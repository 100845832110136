import React from "react";
import { ButtonProps } from "rsuite";

import "./index.scss";

const Button = (props: ButtonProps) => {
  return (
    <div className="button-wrapper">
      <button {...props} />
    </div>
  );
};

export default Button;
