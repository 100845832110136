import config from "../../config";

export default {
  AUTH: {
    LOGIN: `${config.baseApiUrl}auth/login`,
    GOOGLE_LOGIN: `${config.baseApiUrl}auth/google`,
    GOOGLE_REGISTER: `${config.baseApiUrl}auth/google_register`,
  },
  ROLES: {
    GET_ROLES: `${config.baseApiUrl}roles`,
  },
  USER: {
    GET_USER_DETAILS: `${config.baseApiUrl}users/details`,
    GET_USERS: `${config.baseApiUrl}users`,
    GET_USER: (id: string) => `${config.baseApiUrl}users/${id}`,
    SYNC_USERS: `${config.baseApiUrl}users/sync`,
    SYNC_USERS_HOURS: `${config.baseApiUrl}users/sync_hours`,
    EXPORT_USERS_HOURS: `${config.baseApiUrl}users/export_hours`,
    CREATE_USER: `${config.baseApiUrl}users`,
    UPDATE_USER: (id: string) => `${config.baseApiUrl}users/${id}`,
    DELETE_USER: (id: string) => `${config.baseApiUrl}users/${id}`,
    GET_MEETINGS: `${config.baseApiUrl}meetings`,
    CREATE_MEETING: `${config.baseApiUrl}meetings`,
    UPDATE_MEETING: (id: string) => `${config.baseApiUrl}meetings/${id}`,
    DELETE_MEETING: (id: string) => `${config.baseApiUrl}meetings/${id}`,
    GET_ONBOARDINGS: `${config.baseApiUrl}onboardings`,
    GET_PROCEDURES: `${config.baseApiUrl}users/procedures`,
    GET_USER_SURVEYS: `${config.baseApiUrl}users/surveys`,
    CREATE_USER_SURVEY_ANSWERS: (id: string) => `${config.baseApiUrl}users/surveys/${id}/answer`,
    GET_USER_SURVEY: (id: string) => `${config.baseApiUrl}users/surveys/${id}`,
    GET_PROCESSES_WITH_PROCEDURES: `${config.baseApiUrl}processes/procedures`,
    ASSIGN_PROCEDURE: `${config.baseApiUrl}procedures/assign`,
    SELF_ASSIGN_PROCEDURE: `${config.baseApiUrl}procedures/assign_self`,
    ASSIGN_SURVEY: `${config.baseApiUrl}surveys/assign`,
    ASSIGN_ONBOARDING_TO_USER: `${config.baseApiUrl}onboardings/assign`,
    UPDATE_STATUS: (id: string) => `${config.baseApiUrl}users/${id}/status`,
    INVITE_USER: (id: string) => `${config.baseApiUrl}users/${id}/invite`,
    UPDATE_USER_PROCEDURE_STATUS: `${config.baseApiUrl}procedures/status`,
  },
  PROJECT: {
    GET_PROJECTS: `${config.baseApiUrl}projects`,
    CREATE_PROJECT: `${config.baseApiUrl}projects`,
    GET_WORK_TYPES: `${config.baseApiUrl}projects/work_types`,
    UPDATE_PROJECT: (id: string) => `${config.baseApiUrl}projects/${id}`,
    DELETE_PROJECT: (id: string) => `${config.baseApiUrl}projects/${id}`,
  },
  PROCEDURE: {
    GET_PROCEDURES: `${config.baseApiUrl}procedures`,
    GET_PROCEDURE: (id: string) => `${config.baseApiUrl}procedures/${id}`,
    GET_PROCESSES: `${config.baseApiUrl}processes`,
    GET_PROCESSES_PROCEDURES: `${config.baseApiUrl}processes/procedures`,
    GET_TEAMS: `${config.baseApiUrl}teams`,
    CREATE_PROCEDURE: `${config.baseApiUrl}procedures`,
    UPDATE_PROCEDURE: (id: string) => `${config.baseApiUrl}procedures/${id}`,
    DELETE_PROCEDURE: (id: string) => `${config.baseApiUrl}procedures/${id}`,
    UPDATE_LAST_REVIEW_USER_PROCEDURE: `${config.baseApiUrl}procedures/update_last_review`,
    GET_USER_ONBOARDING_STEPS: `${config.baseApiUrl}onboardings/assigned/self`,
    UPDATE_USER_ONBOARDING_STATUS: (id: string) => `${config.baseApiUrl}onboardings/user-onboarding/${id}/status`,
  },
  SURVEY: {
    GET_SURVEYS: `${config.baseApiUrl}surveys`,
    GET_SURVEY: (id: string) => `${config.baseApiUrl}surveys/${id}`,
    CREATE_SURVEY: `${config.baseApiUrl}surveys`,
    UPDATE_SURVEY: (id: string) => `${config.baseApiUrl}surveys/${id}`,
    DELETE_SURVEY: (id: string) => `${config.baseApiUrl}surveys/${id}`,
    GET_SURVEY_STATISTIC: (id: string) => `${config.baseApiUrl}surveys/${id}/statistic`,
    GET_SURVEY_STATISTIC_DETAILS: (id: string) => `${config.baseApiUrl}surveys/${id}/statistic/details`,
    SEND_SURVEY: (id: string) => `${config.baseApiUrl}surveys/${id}/send`,
  },
  GOAL: {
    GET_GOALS: `${config.baseApiUrl}goals`,
    UPDATE_GOAL_PROGRESS: (id: string) => `${config.baseApiUrl}goals/${id}/progress`,
    CREATE_GOAL: `${config.baseApiUrl}goals`,
    UPDATE_GOAL: (id: string) => `${config.baseApiUrl}goals/${id}`,
    DELETE_GOAL: (id: string) => `${config.baseApiUrl}goals/${id}`,
  },
  RECOGNITION: {
    GET_RECOGNITIONS: `${config.baseApiUrl}recognitions`,
    GET_RECOGNITION: (id: string) => `${config.baseApiUrl}recognitions/${id}`,
    CREATE_RECOGNITION: `${config.baseApiUrl}recognitions`,
    UPDATE_RECOGNITION: (id: string) => `${config.baseApiUrl}recognitions/${id}`,
    DELETE_RECOGNITION: (id: string) => `${config.baseApiUrl}recognitions/${id}`,
    GET_CORE_VALUES: `${config.baseApiUrl}recognitions/core-values`,
    GET_USER_CORE_VALUES_SUMMARY: (userId: string) => `${config.baseApiUrl}recognitions/core-values/${userId}`,
  },
  COMMENT: {
    GET_COMMENTS: `${config.baseApiUrl}comments`,
    CREATE_COMMENTS: `${config.baseApiUrl}comments`,
    UPDATE_COMMENT: (id: string) => `${config.baseApiUrl}comments/${id}`,
    DELETE_COMMENT: (id: string) => `${config.baseApiUrl}comments/${id}`,
  },
  CHART: {
    GET_CHART: `${config.baseApiUrl}chart`,
  },
};
