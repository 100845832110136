export enum ProcedureActionTypes {
  GET_PROCEDURES = "@@PROCEDURE/GET_PROCEDURES",
  GET_PROCEDURES_SUCCESS = "@@PROCEDURE/GET_PROCEDURES_SUCCESS",
  GET_PROCEDURES_FAILURE = "@@PROCEDURE/GET_PROCEDURES_FAILURE",

  GET_PROCEDURE = "@@PROCEDURE/GET_PROCEDURE",
  GET_PROCEDURE_SUCCESS = "@@PROCEDURE/GET_PROCEDURE_SUCCESS",
  GET_PROCEDURE_FAILURE = "@@PROCEDURE/GET_PROCEDURE_FAILURE",

  CREATE_PROCEDURE = "@@PROCEDURE/CREATE_PROCEDURE",
  CREATE_PROCEDURE_SUCCESS = "@@PROCEDURE/CREATE_PROCEDURE_SUCCESS",
  CREATE_PROCEDURE_FAILURE = "@@PROCEDURE/CREATE_PROCEDURE_FAILURE",

  UPDATE_PROCEDURE = "@@PROCEDURE/UPDATE_PROCEDURE",
  UPDATE_PROCEDURE_SUCCESS = "@@PROCEDURE/UPDATE_PROCEDURE_SUCCESS",
  UPDATE_PROCEDURE_FAILURE = "@@PROCEDURE/UPDATE_PROCEDURE_FAILURE",

  DELETE_PROCEDURE = "@@PROCEDURE/DELETE_PROCEDURE",
  DELETE_PROCEDURE_SUCCESS = "@@PROCEDURE/DELETE_PROCEDURE_SUCCESS",
  DELETE_PROCEDURE_FAILURE = "@@PROCEDURE/DELETE_PROCEDURE_FAILURE",

  GET_PROCESSES = "@@PROCEDURE/GET_PROCESSES",
  GET_PROCESSES_SUCCESS = "@@PROCEDURE/GET_PROCESSES_SUCCESS",
  GET_PROCESSES_FAILURE = "@@PROCEDURE/GET_PROCESSES_FAILURE",

  GET_PROCESSES_PROCEDURES = "@@PROCEDURE/GET_PROCESSES_PROCEDURES",
  GET_PROCESSES_PROCEDURES_SUCCESS = "@@PROCEDURE/GET_PROCESSES_PROCEDURES_SUCCESS",
  GET_PROCESSES_PROCEDURES_FAILURE = "@@PROCEDURE/GET_PROCESSES_PROCEDURES_FAILURE",

  GET_TEAMS = "@@PROCEDURE/GET_TEAMS",
  GET_TEAMS_SUCCESS = "@@PROCEDURE/GET_TEAMS_SUCCESS",
  GET_TEAMS_FAILURE = "@@PROCEDURE/GET_TEAMS_FAILURE",

  CLEAR_PROCEDURES = "@@PROCEDURE/CLEAR_PROCEDURES",
  CLEAR_PROCESS_PROCEDURES = "@@PROCEDURE/CLEAR_PROCESS_PROCEDURES",
  SET_FILTER = "@@PROCEDURE/SET_FILTER",
  SET_CONTRACTOR_PROCEDURES_FILTER = "@@PROCEDURE/SET_CONTRACTOR_PROCEDURES_FILTER",

  UPDATE_USER_PROCEDURE_STATUS = "@@PROCEDURE/UPDATE_USER_PROCEDURE_STATUS_PROCEDURE",
  UPDATE_USER_PROCEDURE_STATUS_SUCCESS = "@@PROCEDURE/UPDATE_USER_PROCEDURE_STATUS_SUCCESS",
  UPDATE_USER_PROCEDURE_STATUS_FAILURE = "@@PROCEDURE/UPDATE_USER_PROCEDURE_STATUS_FAILURE",

  GET_USER_ONBOARDING_STEPS = "@@PROCEDURE/GET_USER_ONBOARDING_STEPS",
  GET_USER_ONBOARDING_STEPS_SUCCESS = "@@PROCEDURE/GET_USER_ONBOARDING_STEPS_SUCCESS",
  GET_USER_ONBOARDING_STEPS_FAILURE = "@@PROCEDURE/GET_USER_ONBOARDING_STEPS_FAILURE",

  UPDATE_USER_ONBOARDING_STATUS = "@@PROCEDURE/UPDATE_USER_ONBOARDING_STATUS",
  UPDATE_USER_ONBOARDING_STATUS_SUCCESS = "@@PROCEDURE/UPDATE_USER_ONBOARDING_STATUS_SUCCESS",
  UPDATE_USER_ONBOARDING_STATUS_FAILURE = "@@PROCEDURE/UPDATE_USER_ONBOARDING_STATUS_FAILURE",
}
