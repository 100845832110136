import * as Yup from "yup";
import { FORM_ERROR_MESSAGES } from "@shared/constants";
import { User } from "@shared/models";
import {
  AssignOnboardingToUserDto,
  AssignOnboardingToUserFormShape,
} from "@containers/User/interfaces/UserForm.interface";

export const validationSchema = Yup.object().shape({
  onboarding_id: Yup.number().required(FORM_ERROR_MESSAGES.REQUIRED).typeError(FORM_ERROR_MESSAGES.REQUIRED),
});

export const getInitValues = () => ({
  onboarding_id: null,
});

export const prepareSubmitValue = (values: AssignOnboardingToUserFormShape, user: User): AssignOnboardingToUserDto => {
  return {
    ...values,
    user_id: user.id,
  };
};
