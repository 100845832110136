import { request } from "@shared/utils";
import { API, METHODS } from "@shared/constants";
import { Filter } from "@shared/interfaces";
import { ProcessFilter } from "@shared/interfaces/ProcessFilter.interface";
import { UserOnboarding } from "@shared/models";

import { CreateProcedureDto, UpdateProcedureDto, UpdateUserProcedureLastReviewDto } from "../interfaces";

export default {
  getProcedures: (payload: Filter) => request(METHODS.GET, API.PROCEDURE.GET_PROCEDURES)({ params: payload }),
  getProcedure: (id: number) => {
    return request(METHODS.GET, API.PROCEDURE.GET_PROCEDURE(String(id)))();
  },
  getProcesses: () => request(METHODS.GET, API.PROCEDURE.GET_PROCESSES)(),
  getProcessesProcedures: (payload: ProcessFilter) =>
    request(METHODS.GET, API.PROCEDURE.GET_PROCESSES_PROCEDURES)({ params: payload }),

  createProcedure: (payload: CreateProcedureDto) => request(METHODS.POST, API.PROCEDURE.CREATE_PROCEDURE)(payload),
  updateProcedure: (payload: UpdateProcedureDto) => {
    const { id, ...rest } = payload;
    return request(METHODS.PUT, API.PROCEDURE.UPDATE_PROCEDURE(String(id)))(rest);
  },
  deleteProcedure: (id: number) => {
    return request(METHODS.DELETE, API.PROCEDURE.DELETE_PROCEDURE(String(id)))();
  },

  updateUserProcedureLastReview: (payload: UpdateUserProcedureLastReviewDto) =>
    request(METHODS.POST, API.PROCEDURE.UPDATE_LAST_REVIEW_USER_PROCEDURE)(payload),
  getUserOnboardingSteps: () => request(METHODS.GET, API.PROCEDURE.GET_USER_ONBOARDING_STEPS)(),
  updateUserOnboardingStatus: (payload: Partial<UserOnboarding>) => {
    const { id, ...rest } = payload;
    return request(METHODS.PUT, API.PROCEDURE.UPDATE_USER_ONBOARDING_STATUS(String(id)))(rest);
  },
};
