import React from "react";
import classnames from "classnames";

import "./index.scss";

export interface LogoProps {
  className?: string;
  adaptive?: boolean;
}

const Logo: React.FC<LogoProps> = ({ className, adaptive }) => (
  <div
    className={classnames("logo", className, {
      logo_adaptive: adaptive,
    })}
  />
);

export default Logo;
