import { MeetingFilter, UserFilter, UserProcedureFilter, UserSurveyFilter } from "@shared/interfaces";
import { produce } from "immer";
import { ActionType, createReducer } from "typesafe-actions";
import { USER_STATUS } from "@shared/constants";

import { UserStateType } from "../interfaces";
import * as actions from "./actions";

type Action = ActionType<typeof actions>;

const defaultFilter: UserFilter = {
  page: 0,
  limit: 50,
  search: "",
  status: USER_STATUS.ACTIVE,
};

const defaultMeetingFilter: MeetingFilter = {
  page: 0,
  limit: 50,
};

const defaultUserProcedureFilter: UserProcedureFilter = {
  page: 0,
  limit: 50,
  search: "",
};

const defaultUserSurveyFilter: UserSurveyFilter = {
  page: 0,
  limit: 50,
  search: "",
};

export const initialState: UserStateType = {
  users: [],
  usersTotal: 0,
  selectedUser: null,
  selectedUserMeetings: [],
  selectedUserMeetingsTotal: 0,
  filter: { ...defaultFilter },
  onboardings: [],
  processesWithProcedures: [],
  surveyList: [],
  userProcedures: [],
  userProceduresTotal: 0,
  userProceduresFilter: { ...defaultUserProcedureFilter },
  userSurveys: [],
  userSurvey: null,
  userSurveysTotal: 0,
  userSurveysFilter: { ...defaultUserSurveyFilter },
  meetingFilter: { ...defaultMeetingFilter },
  userProceduresIsLoaded: false,
};

const reducer = createReducer<UserStateType, Action>(initialState)
  .handleAction(actions.getUsers.success, (state, action) =>
    produce(state, (nextState) => {
      const { rows, count, is_clear } = action.payload;
      nextState.users = !is_clear ? [...nextState.users, ...rows] : [...rows];
      nextState.usersTotal = count;
    }),
  )

  .handleAction(actions.getUserMeetings.success, (state, action) =>
    produce(state, (nextState) => {
      const { rows, count, is_clear } = action.payload;
      nextState.selectedUserMeetings = !is_clear ? [...nextState.selectedUserMeetings, ...rows] : [...rows];
      nextState.selectedUserMeetingsTotal = count;
    }),
  )
  .handleAction(actions.getUserProcedures.success, (state, action) =>
    produce(state, (nextState) => {
      const { rows, count, is_clear } = action.payload;
      nextState.userProcedures = !is_clear ? [...nextState.userProcedures, ...rows] : [...rows];
      nextState.userProceduresTotal = count;
      nextState.userProceduresIsLoaded = true;
    }),
  )
  .handleAction(actions.getUserSurveys.success, (state, action) =>
    produce(state, (nextState) => {
      const { rows, count, is_clear } = action.payload;
      nextState.userSurveys = !is_clear ? [...nextState.userSurveys, ...rows] : [...rows];
      nextState.userSurveysTotal = count;
    }),
  )
  .handleAction(actions.getUserSurvey.success, (state, action) =>
    produce(state, (nextState) => {
      nextState.userSurvey = action.payload;
    }),
  )
  .handleAction(actions.createUserSurveyAnswers.success, (state, action) =>
    produce(state, (nextState) => {
      nextState.userSurvey = action.payload;
    }),
  )
  .handleAction(actions.createUserMeeting.success, (state, action) =>
    produce(state, (nextState) => {
      nextState.selectedUserMeetings = [action.payload.meeting, ...nextState.selectedUserMeetings];
    }),
  )
  .handleAction(actions.selfAssignProcedure.success, (state, action) =>
    produce(state, (nextState) => {
      nextState.userProcedures = [...nextState.userProcedures, action.payload];
      nextState.userProceduresTotal = nextState.userProceduresTotal + 1;
    }),
  )
  .handleAction(actions.updateUserMeeting.success, (state, action) =>
    produce(state, (nextState) => {
      const { selectedUserMeetings } = nextState;
      const foundSelectedUserMeetingIndex = selectedUserMeetings.findIndex((m) => action.payload.meeting.id === m.id);

      selectedUserMeetings[foundSelectedUserMeetingIndex] = action.payload.meeting;

      nextState.selectedUserMeetings = [...selectedUserMeetings];
    }),
  )
  .handleAction(actions.deleteUserMeeting.success, (state, action) =>
    produce(state, (nextState) => {
      const selectedUserMeetings = nextState.selectedUserMeetings.filter((m) => m.id !== action.payload.meeting_id);
      nextState.selectedUserMeetings = [...selectedUserMeetings];
    }),
  )
  .handleAction(actions.createUser.success, (state, action) =>
    produce(state, (nextState) => {
      nextState.users = [action.payload.user, ...nextState.users];
      nextState.usersTotal = nextState.usersTotal + 1;
    }),
  )
  .handleAction(actions.updateUser.success, (state, action) =>
    produce(state, (nextState) => {
      const { users } = nextState;
      const foundUserIndex = users.findIndex((s) => action.payload.user.id === s.id);

      users[foundUserIndex] = action.payload.user;

      nextState.users = [...users];

      if (state.selectedUser && state.selectedUser.id === action.payload.user.id) {
        nextState.selectedUser = action.payload.user;
      }
    }),
  )
  .handleAction(actions.deleteUser.success, (state, action) =>
    produce(state, (nextState) => {
      const users = nextState.users.filter((u) => u.id !== action.payload.user_id);
      nextState.users = [...users];
      if (state.selectedUser && state.selectedUser.id === action.payload.user_id) {
        nextState.selectedUser = null;
      }
    }),
  )
  .handleAction(actions.getUser.success, (state, action) =>
    produce(state, (nextState) => {
      const user = action.payload;
      nextState.selectedUser = user;
    }),
  )
  .handleAction(actions.assignOnboarding.success, (state, action) =>
    produce(state, (nextState) => {
      const assignedUserOnboarding = action.payload.assignedOnboarding;
      if (nextState.selectedUser) {
        nextState.selectedUser.user_onboarding = assignedUserOnboarding;
      }
    }),
  )
  .handleAction(actions.setFilter, (state, action) =>
    produce(state, (nextState) => {
      nextState.filter = action.payload || { ...defaultFilter };
    }),
  )

  .handleAction(actions.setMeetingsFilter, (state, action) =>
    produce(state, (nextState) => {
      nextState.meetingFilter = action.payload || { ...defaultMeetingFilter };
    }),
  )
  .handleAction(actions.setUserProceduresFilter, (state, action) =>
    produce(state, (nextState) => {
      nextState.userProceduresFilter = action.payload || { ...defaultUserProcedureFilter };
    }),
  )
  .handleAction(actions.setUserSurveysFilter, (state, action) =>
    produce(state, (nextState) => {
      nextState.userSurveysFilter = action.payload || { ...defaultUserSurveyFilter };
    }),
  )
  .handleAction(actions.clearUsers, (state) =>
    produce(state, (nextState) => {
      nextState.users = [];
      nextState.usersTotal = 0;
    }),
  )
  .handleAction(actions.getOnboardings.success, (state, action) =>
    produce(state, (nextState) => {
      nextState.onboardings = action.payload;
    }),
  )
  .handleAction(actions.getProcessesWithProcedures.success, (state, action) =>
    produce(state, (nextState) => {
      nextState.processesWithProcedures = action.payload;
    }),
  )
  .handleAction(actions.getSurveyList.success, (state, action) =>
    produce(state, (nextState) => {
      nextState.surveyList = action.payload.rows;
    }),
  )
  .handleAction(actions.clearUserMeetings, (state) =>
    produce(state, (nextState) => {
      nextState.selectedUserMeetings = [];
      nextState.selectedUserMeetingsTotal = 0;
    }),
  )
  .handleAction(actions.clearUserProcedures, (state) =>
    produce(state, (nextState) => {
      nextState.userProcedures = [];
      nextState.userProceduresTotal = 0;
      nextState.userProceduresIsLoaded = false;
    }),
  )
  .handleAction(actions.clearUserSurveys, (state) =>
    produce(state, (nextState) => {
      nextState.userSurveys = [];
      nextState.userSurveysTotal = 0;
    }),
  )
  .handleAction(actions.clearUserSurvey, (state) =>
    produce(state, (nextState) => {
      nextState.userSurvey = null;
    }),
  )
  .handleAction(actions.updateUserStatus.success, (state, action) =>
    produce(state, (nextState) => {
      if (state.selectedUser && state.selectedUser.id === action.payload.user.id) {
        nextState.selectedUser = { ...state.selectedUser, status: action.payload.user.status };
      }
    }),
  )
  .handleAction(actions.updateUserProcedureStatus.success, (state, action) =>
    produce(state, (nextState) => {
      const foundIndex = state.userProcedures.findIndex((p) => p.procedure.id === action.payload.procedure_id);
      if (foundIndex >= 0) {
        nextState.userProcedures[foundIndex].status = action.payload.status;
      }
    }),
  );

export { reducer as UserReducer };
