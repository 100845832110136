import React, { FC, MouseEvent, RefObject, useCallback, useEffect, useRef, useState } from "react";
import classnames from "classnames";
import { useOutsideClick } from "@shared/hooks";
import { Icon } from "@shared/components";

import "./index.scss";
import { IconType } from "../Icon/Icon";

interface ToggleMenuProps {
  className?: string;
  isCloseIcon?: boolean;
  items: {
    label: string;
    handler: () => void;
    className?: string;
    icon?: IconType;
  }[];
  align?: {
    top?: number;
    left?: number;
  };
  externalOpened?: boolean;
  setExternalOpened?: React.Dispatch<React.SetStateAction<boolean>>;
}

const ToggleMenu: FC<ToggleMenuProps> = ({
  items,
  className,
  isCloseIcon,
  align,
  externalOpened,
  setExternalOpened,
}) => {
  const [opened, setOpened] = useState(externalOpened || false);
  const [position, setPosition] = useState({ top: 0, left: 0 });
  const dropDownRef = useRef<HTMLDivElement | null>(null);

  const { isOutside } = useOutsideClick(
    dropDownRef as RefObject<{ contains: (target: EventTarget | null) => boolean }>,
  );

  useEffect(() => {
    if (isOutside) {
      setOpened(false);
      setExternalOpened && setExternalOpened(false);
    }
  }, [isOutside, setExternalOpened]);

  const onOpen = useCallback(
    (event: MouseEvent) => {
      setPosition({ top: event.clientY + (align?.top || 10), left: event.clientX - (align?.left || 10) });
      setOpened((value) => !value);
      setExternalOpened && setExternalOpened((prevValue) => !prevValue);
    },
    [align?.left, align?.top, setExternalOpened],
  );

  const handleItemClick = useCallback(
    (handler: () => void) => {
      handler();
      setOpened(false);
      setExternalOpened && setExternalOpened(false);
    },
    [setExternalOpened],
  );

  return (
    <div className="toggle-menu-wrapper" ref={dropDownRef}>
      <div className={classnames("toggle-menu-dots-wrapper", className)} onClick={onOpen}>
        <div className={classnames("toggle-menu-dots")}>
          {(externalOpened !== undefined && !externalOpened) || !opened || !isCloseIcon ? (
            <>
              <div className="dot" />
              <div className="dot" />
              <div className="dot" />
            </>
          ) : (
            <div>X</div>
          )}
        </div>
      </div>
      {((externalOpened !== undefined && externalOpened) || opened) && (
        <div className="toggle-menu-items" style={{ top: position.top, left: position.left }}>
          {items.map((item) => (
            <div
              key={item.label}
              className={classnames("toggle-menu-item", item.className)}
              onClick={() => handleItemClick(item.handler)}
            >
              {item.icon && <Icon type={item.icon} />}
              {item.label}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default ToggleMenu;
