import { createAsyncAction, createAction } from "typesafe-actions";
import { Meeting, Onboarding, Process, Survey, User, UserProcedure, UserSurvey } from "@shared/models";
import {
  BaseSuccessResponse,
  Filter,
  MeetingFilter,
  PaginatedResponse,
  SurveysFilter,
  UserFilter,
  UserProcedureFilter,
  UserSurveyFilter,
  WithCallback,
  WithOnlyCallback,
} from "@shared/interfaces";
import {
  CreateUserMeetingDto,
  CreateUserMeetingSuccess,
  UpdateUserDto,
  UpdateUserSuccess,
  AssignOnboardingToUserDto,
  AssignOnboardingToUserSuccess,
  UpdateUserMeetingDto,
  UpdateUserMeetingSuccess,
  DeleteUserMeetingSuccess,
  UpdateUserStatusDto,
  UpdateUserStatusSuccess,
  AssignProcedureDto,
  AssignProcedureSuccess,
  UpdateUserProcedureStatusDto,
  AssignSurveyDto,
  AssignSurveySuccess,
  CreateUserSurveyAnswersDto,
  SelfAssignProcedureDto,
  DeleteUserSuccess,
  CreateUserDto,
  CreateUserSuccess,
  ExportUsersHoursDto,
} from "@containers/User/interfaces/UserForm.interface";

import { UserActionTypes } from "./constants";

export const getUsers = createAsyncAction(
  UserActionTypes.GET_USERS,
  UserActionTypes.GET_USERS_SUCCESS,
  UserActionTypes.GET_USERS_FAILURE,
)<UserFilter, PaginatedResponse<User>, Error>();

export const getUser = createAsyncAction(
  UserActionTypes.GET_USER,
  UserActionTypes.GET_USER_SUCCESS,
  UserActionTypes.GET_USER_FAILURE,
)<string, User | null, Error>();

export const syncUsers = createAsyncAction(
  UserActionTypes.SYNC_USERS_HARVEST,
  UserActionTypes.SYNC_USERS_HARVEST_SUCCESS,
  UserActionTypes.SYNC_USERS_HARVEST_FAILURE,
)<WithOnlyCallback, undefined, Error>();

export const syncUsersHours = createAsyncAction(
  UserActionTypes.SYNC_USERS_HOURS_HARVEST,
  UserActionTypes.SYNC_USERS_HOURS_HARVEST_SUCCESS,
  UserActionTypes.SYNC_USERS_HOURS_HARVEST_FAILURE,
)<undefined, undefined, Error>();

export const createUser = createAsyncAction(
  UserActionTypes.CREATE_USER,
  UserActionTypes.CREATE_USER_SUCCESS,
  UserActionTypes.CREATE_USER_FAILURE,
)<CreateUserDto, CreateUserSuccess, Error>();

export const updateUser = createAsyncAction(
  UserActionTypes.UPDATE_USER,
  UserActionTypes.UPDATE_USER_SUCCESS,
  UserActionTypes.UPDATE_USER_FAILURE,
)<UpdateUserDto, UpdateUserSuccess, Error>();

export const deleteUser = createAsyncAction(
  UserActionTypes.DELETE_USER,
  UserActionTypes.DELETE_USER_SUCCESS,
  UserActionTypes.DELETE_USER_FAILURE,
)<number, DeleteUserSuccess, Error>();

export const getUserMeetings = createAsyncAction(
  UserActionTypes.GET_MEETINGS,
  UserActionTypes.GET_MEETINGS_SUCCESS,
  UserActionTypes.GET_MEETINGS_FAILURE,
)<MeetingFilter, PaginatedResponse<Meeting>, Error>();

export const getUserSurveys = createAsyncAction(
  UserActionTypes.GET_USER_SURVEYS,
  UserActionTypes.GET_USER_SURVEYS_SUCCESS,
  UserActionTypes.GET_USER_SURVEYS_FAILURE,
)<UserSurveyFilter, PaginatedResponse<UserSurvey>, Error>();

export const getUserSurvey = createAsyncAction(
  UserActionTypes.GET_USER_SURVEY,
  UserActionTypes.GET_USER_SURVEY_SUCCESS,
  UserActionTypes.GET_USER_SURVEY_FAILURE,
)<number, UserSurvey | null, Error>();

export const createUserSurveyAnswers = createAsyncAction(
  UserActionTypes.CREATE_USER_SURVEY_ANSWERS,
  UserActionTypes.CREATE_USER_SURVEY_ANSWERS_SUCCESS,
  UserActionTypes.CREATE_USER_SURVEY_ANSWERS_FAILURE,
)<WithCallback<CreateUserSurveyAnswersDto>, UserSurvey, Error>();

export const createUserMeeting = createAsyncAction(
  UserActionTypes.CREATE_MEETING,
  UserActionTypes.CREATE_MEETING_SUCCESS,
  UserActionTypes.CREATE_MEETING_FAILURE,
)<CreateUserMeetingDto, CreateUserMeetingSuccess, Error>();

export const updateUserMeeting = createAsyncAction(
  UserActionTypes.UPDATE_MEETING,
  UserActionTypes.UPDATE_MEETING_SUCCESS,
  UserActionTypes.UPDATE_MEETING_FAILURE,
)<UpdateUserMeetingDto, UpdateUserMeetingSuccess, Error>();

export const deleteUserMeeting = createAsyncAction(
  UserActionTypes.DELETE_MEETING,
  UserActionTypes.DELETE_MEETING_SUCCESS,
  UserActionTypes.DELETE_MEETING_FAILURE,
)<number, DeleteUserMeetingSuccess, Error>();

export const getOnboardings = createAsyncAction(
  UserActionTypes.GET_ONBOARDINGS,
  UserActionTypes.GET_ONBOARDINGS_SUCCESS,
  UserActionTypes.GET_ONBOARDINGS_FAILURE,
)<undefined, Onboarding[], Error>();

export const getProcessesWithProcedures = createAsyncAction(
  UserActionTypes.GET_PROCESSES_WITH_PROCEDURES,
  UserActionTypes.GET_PROCESSES_WITH_PROCEDURES_SUCCESS,
  UserActionTypes.GET_PROCESSES_WITH_PROCEDURES_FAILURE,
)<undefined, Process[], Error>();

export const getSurveyList = createAsyncAction(
  UserActionTypes.GET_SURVEY_LIST,
  UserActionTypes.GET_SURVEY_LIST_SUCCESS,
  UserActionTypes.GET_SURVEY_LIST_FAILURE,
)<SurveysFilter, PaginatedResponse<Survey>, Error>();

export const assignProcedure = createAsyncAction(
  UserActionTypes.ASSIGN_PROCEDURE,
  UserActionTypes.ASSIGN_PROCEDURE_SUCCESS,
  UserActionTypes.ASSIGN_PROCEDURE_FAILURE,
)<AssignProcedureDto, AssignProcedureSuccess, Error>();

export const selfAssignProcedure = createAsyncAction(
  UserActionTypes.SELF_ASSIGN_PROCEDURE,
  UserActionTypes.SELF_ASSIGN_PROCEDURE_SUCCESS,
  UserActionTypes.SELF_ASSIGN_PROCEDURE_FAILURE,
)<SelfAssignProcedureDto, UserProcedure, Error>();

export const assignSurvey = createAsyncAction(
  UserActionTypes.ASSIGN_SURVEY,
  UserActionTypes.ASSIGN_SURVEY_SUCCESS,
  UserActionTypes.ASSIGN_SURVEY_FAILURE,
)<AssignSurveyDto, AssignSurveySuccess, Error>();

export const getUserProcedures = createAsyncAction(
  UserActionTypes.GET_USER_PROCEDURES,
  UserActionTypes.GET_USER_PROCEDURES_SUCCESS,
  UserActionTypes.GET_USER_PROCEDURES_FAILURE,
)<UserProcedureFilter, PaginatedResponse<UserProcedure>, Error>();

export const setFilter = createAction(UserActionTypes.SET_FILTER)<Filter | null>();
export const setMeetingsFilter = createAction(UserActionTypes.SET_MEETINGS_FILTER)<MeetingFilter | null>();
export const clearUsers = createAction(UserActionTypes.CLEAR_USERS)();
export const clearUserMeetings = createAction(UserActionTypes.CLEAR_USER_MEETINGS)();
export const clearUserProcedures = createAction(UserActionTypes.CLEAR_USER_PROCEDURES)();
export const clearUserSurveys = createAction(UserActionTypes.CLEAR_USER_SURVEYS)();
export const clearUserSurvey = createAction(UserActionTypes.CLEAR_USER_SURVEY)();
export const setUserProceduresFilter = createAction(
  UserActionTypes.SET_USER_PROCEDURES_FILTER,
)<UserProcedureFilter | null>();
export const setUserSurveysFilter = createAction(UserActionTypes.SET_USER_SURVEYS_FILTER)<UserSurveyFilter | null>();

export const assignOnboarding = createAsyncAction(
  UserActionTypes.ASSIGN_ONBOARDING,
  UserActionTypes.ASSIGN_ONBOARDING_SUCCESS,
  UserActionTypes.ASSIGN_ONBOARDING_FAILURE,
)<AssignOnboardingToUserDto, AssignOnboardingToUserSuccess, Error>();

export const updateUserStatus = createAsyncAction(
  UserActionTypes.UPDATE_USER_STATUS,
  UserActionTypes.UPDATE_USER_STATUS_SUCCESS,
  UserActionTypes.UPDATE_USER_STATUS_FAILURE,
)<UpdateUserStatusDto, UpdateUserStatusSuccess, Error>();

export const inviteUser = createAsyncAction(
  UserActionTypes.INVITE_USER,
  UserActionTypes.INVITE_USER_SUCCESS,
  UserActionTypes.INVITE_USER_FAILURE,
)<number, BaseSuccessResponse, Error>();

export const updateUserProcedureStatus = createAsyncAction(
  UserActionTypes.UPDATE_USER_PROCEDURE_STATUS,
  UserActionTypes.UPDATE_USER_PROCEDURE_STATUS_SUCCESS,
  UserActionTypes.UPDATE_USER_PROCEDURE_STATUS_FAILURE,
)<WithCallback<UpdateUserProcedureStatusDto>, UpdateUserProcedureStatusDto, Error>();

export const exportUsersHours = createAsyncAction(
  UserActionTypes.EXPORT_USERS_HOURS,
  UserActionTypes.EXPORT_USERS_HOURS_SUCCESS,
  UserActionTypes.EXPORT_USERS_HOURS,
)<ExportUsersHoursDto, undefined, Error>();
