import React from "react";
import classnames from "classnames";

import "./index.scss";

export interface LoaderProps {
  className?: string;
  position?: string;
  text?: string;
}

const Loader: React.FC<LoaderProps> = ({ className, text, position }) => (
  <div className={classnames("loader-wrapper", position)}>
    <div className={classnames("loader", className)}>{text}</div>
  </div>
);

export default Loader;
