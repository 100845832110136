import React from "react";
import type { FC, ReactNode } from "react";
import { Navigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { checkPermissions, getDefaultUrl, tokenHandler } from "@shared/utils";
import { selectors as authSelectors } from "@containers/Auth/store";
import { selectors as sharedSelectors, actions as sharedActions } from "@shared/store";

import { NameOfChildRoutes, NamesOfParentRoutes } from "../constants";

interface AuthGuardProps {
  children?: ReactNode;
  permissions?: string[];
  isInit?: boolean;
}

const AuthGuard: FC<AuthGuardProps> = (props) => {
  const dispatch = useDispatch();

  const { children, permissions, isInit } = props;
  const isAuthenticated = useSelector(authSelectors.authentificated());
  const authenticatedUser = useSelector(sharedSelectors.getUserDetails());

  const hasPermission = Array.isArray(permissions) ? checkPermissions(permissions) : isAuthenticated;

  if (!isAuthenticated) {
    const searchParams = window.location.search;
    !window.location.pathname.includes("auth") &&
      localStorage.setItem("redirectUrl", `${window.location.pathname}${searchParams ? searchParams : ""}`);
  }

  if (!hasPermission) {
    return <Navigate to={`${NamesOfParentRoutes.AUTH}${NameOfChildRoutes.AUTH.LOGIN}`} />;
  }

  if (isInit && authenticatedUser) {
    return (
      <Navigate
        to={getDefaultUrl(authenticatedUser, () => {
          tokenHandler.removeAll();
          dispatch(sharedActions.getUserDetails.success(null));
        })}
      />
    );
  }

  return <>{children}</>;
};

export default AuthGuard;
