import { request } from "@shared/utils";
import { API, METHODS } from "@shared/constants";
import { GoalsFilter } from "@shared/interfaces";
import {
  CreateCommentDto,
  CreateGoalDto,
  UpdateCommentDto,
  UpdateGoalDto,
  UpdateGoalProgressDto,
} from "@containers/Goal/interfaces";
import { Comment } from "@shared/models";

export default {
  getGoals: (payload: GoalsFilter) => request(METHODS.GET, API.GOAL.GET_GOALS)({ params: payload }),
  updateGoalProgress: (payload: UpdateGoalProgressDto) => {
    const { id, ...rest } = payload;
    return request(METHODS.PUT, API.GOAL.UPDATE_GOAL_PROGRESS(String(id)))(rest);
  },
  createGoal: (payload: CreateGoalDto) => request(METHODS.POST, API.GOAL.CREATE_GOAL)(payload),
  updateGoal: (payload: UpdateGoalDto) => {
    const { id, ...rest } = payload;
    return request(METHODS.PUT, API.GOAL.UPDATE_GOAL(String(id)))(rest);
  },
  deleteGoal: (id: number) => {
    return request(METHODS.DELETE, API.GOAL.DELETE_GOAL(String(id)))();
  },
  createComment: (payload: CreateCommentDto) => request(METHODS.POST, API.COMMENT.CREATE_COMMENTS)(payload),
  getComments: (payload: Partial<Comment>) => {
    return request(METHODS.GET, API.COMMENT.GET_COMMENTS)({ params: payload });
  },
  updateComment: (payload: UpdateCommentDto) => {
    const { id, ...rest } = payload;
    return request(METHODS.PUT, API.COMMENT.UPDATE_COMMENT(String(id)))(rest);
  },
  deleteComment: (id: number) => {
    return request(METHODS.DELETE, API.COMMENT.DELETE_COMMENT(String(id)))();
  },
};
