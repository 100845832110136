import { useDispatch } from "react-redux";
import React, { FC, useCallback } from "react";
import { actions as sharedActions } from "@shared/store";
import { actions as RecognitionActions } from "@containers/Recognition/store";
import { CreateRecognitionDto } from "@containers/Recognition/interfaces";
import { RecognitionForm } from "@containers/Recognition/components";
import { OVERLAY_TYPES } from "@shared/constants";
import { OverlaySize } from "@shared/interfaces";

interface RecognitionCreateContainerProps {
  onClose: () => void;
}

const RecognitionCreateContainer: FC<RecognitionCreateContainerProps> = (props) => {
  const { onClose } = props;

  const dispatch = useDispatch();

  const changeFormValues = useCallback(() => {
    dispatch(sharedActions.updateOverlay({ leaveConfirm: true }));
  }, [dispatch]);

  const openRecognitionCreationNoteOverlay = useCallback(() => {
    dispatch(
      sharedActions.showOverlay({
        type: OVERLAY_TYPES.SHOW_RECOGNITION_CREATION_NOTE,
        size: OverlaySize.EXTRA_REDUCED,
        closeOnOverlayClick: false,
        props: {},
      }),
    );
  }, [dispatch]);

  const handleSubmitForm = useCallback(
    (payload: CreateRecognitionDto) => {
      dispatch(sharedActions.hideOverlay());
      dispatch(RecognitionActions.createRecognition.request({ ...payload, cb: openRecognitionCreationNoteOverlay }));
    },
    [dispatch, openRecognitionCreationNoteOverlay],
  );

  return (
    <div className="recognition-create-container">
      <RecognitionForm onChangeForm={changeFormValues} onSubmitForm={handleSubmitForm} onCloseForm={onClose} />
    </div>
  );
};

export default RecognitionCreateContainer;
