import { useEffect, useMemo, useState } from "react";

export default function useLoadingBottom(isLoading: boolean) {
  const [isLoadingNextPage, setIsLoadingNextPage] = useState<boolean>(false);
  const isBottomLoading = useMemo<boolean>(() => isLoadingNextPage && isLoading, [isLoadingNextPage, isLoading]);
  useEffect(() => {
    !isBottomLoading && setIsLoadingNextPage(false);
  }, [isBottomLoading]);

  return { isBottomLoading, setIsLoadingNextPage };
}
