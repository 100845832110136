import React from "react";
import { ToggleComponent } from "@shared/components";

interface StatusToggleProps {
  checked: boolean;
  onChange: (checked: boolean) => void;
}

const StatusToggle: React.FunctionComponent<StatusToggleProps> = (props) => {
  const { checked, onChange } = props;
  return <ToggleComponent checked={checked} onChange={onChange} />;
};

export default StatusToggle;
