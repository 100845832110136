import React from "react";
import { useCheckPermission } from "@shared/hooks";

export interface AccessControlProps {
  permission?: string;
  children?: React.ReactNode;
}

const AccessControl = (props: AccessControlProps) => {
  const { permission, children } = props;

  const permitted = useCheckPermission(permission);

  return permitted ? <>{children}</> : null;
};

export default AccessControl;
