import { all, fork } from "redux-saga/effects";
import { saga as authSaga } from "@containers/Auth/store";
import projectSaga from "@containers/Project/store/saga";
import userSaga from "@containers/User/store/saga";
import sharedSaga from "@shared/store/saga";
import procedureSaga from "@containers/Procedure/store/saga";
import surveySagas from "@containers/Survey/store/saga";
import goalsSagas from "@containers/Goal/store/saga";
import recognitionsSagas from "@containers/Recognition/store/saga";
import chartSagas from "@containers/AccountabilityChart/store/saga";

const allSagas = [
  authSaga,
  projectSaga,
  procedureSaga,
  surveySagas,
  goalsSagas,
  sharedSaga,
  userSaga,
  recognitionsSagas,
  chartSagas,
];

export default function* appSagas() {
  yield all(allSagas.map(fork));
}
