import React, { useEffect } from "react";
import { useFormikContext } from "formik";

const ScrollToFirstFormError: React.FC = () => {
  const { isValid, errors, isSubmitting } = useFormikContext();

  useEffect(() => {
    setTimeout(() => {
      const errorKeys = Object.keys(errors);
      if (!isSubmitting || isValid || !errorKeys.length) return;
      const element = document.querySelector(`input[name=${errorKeys[0]}]`);
      if (!element) return;
      element.scrollIntoView({ behavior: "smooth", block: "center" });
    }, 100);
  }, [isSubmitting, isValid, errors]);

  return null;
};

export default ScrollToFirstFormError;
