export enum ProjectActionTypes {
  GET_PROJECTS = "@@PROJECT/GET_PROJECTS",
  GET_PROJECTS_SUCCESS = "@@PROJECT/GET_PROJECTS_SUCCESS",
  GET_PROJECTS_FAILURE = "@@PROJECT/GET_PROJECTS_FAILURE",

  CREATE_PROJECT = "@@PROJECT/CREATE_PROJECT",
  CREATE_PROJECT_SUCCESS = "@@PROJECT/CREATE_PROJECT_SUCCESS",
  CREATE_PROJECT_FAILURE = "@@PROJECT/CREATE_PROJECT_FAILURE",

  UPDATE_PROJECT = "@@PROJECT/UPDATE_PROJECT",
  UPDATE_PROJECT_SUCCESS = "@@PROJECT/UPDATE_PROJECT_SUCCESS",
  UPDATE_PROJECT_FAILURE = "@@PROJECT/UPDATE_PROJECT_FAILURE",

  DELETE_PROJECT = "@@PROJECT/DELETE_PROJECT",
  DELETE_PROJECT_SUCCESS = "@@PROJECT/DELETE_PROJECT_SUCCESS",
  DELETE_PROJECT_FAILURE = "@@PROJECT/DELETE_PROJECT_FAILURE",

  CLEAR_PROJECTS = "@@PROJECT/CLEAR_PROJECTS",
  SET_FILTER = "@@PROJECT/SET_FILTER",

  GET_WORK_TYPES = "@@PROJECT/GET_WORK_TYPES",
  GET_WORK_TYPES_SUCCESS = "@@PROJECT/GET_WORK_TYPES_SUCCESS",
  GET_WORK_TYPES_FAILURE = "@@PROJECT/GET_WORK_TYPES_FAILURE",
}
