import React from "react";
import { SelectPicker, SelectPickerProps } from "rsuite";

import { Label } from "../Label";

import "./index.scss";

const SelectPickerInput = (props: SelectPickerProps<any> & { labelTitle?: string; labelClassName?: string }) => {
  const { labelTitle, labelClassName, ...pickerProps } = props;

  return (
    <div className="select-picker-input">
      {labelTitle ? <Label title={labelTitle} className={labelClassName} /> : null}
      <SelectPicker {...pickerProps} placement="bottom" />
    </div>
  );
};

export default SelectPickerInput;
