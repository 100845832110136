import React, { FC, useCallback } from "react";
import { Recognition, User } from "@shared/models";
import { Icon } from "@shared/components";
import { IconType } from "@shared/components/Icon/Icon";
import { getFullName, getGbFormattedDate } from "@shared/utils";
import { actions as sharedActions } from "@shared/store";
import { useDispatch } from "react-redux";

import "./index.scss";

interface RecognitionOverlayDetailsContainerProps {
  recognition: Recognition;
  showSentTo?: boolean;
}

const RecognitionOverlayDetailsContainer: FC<RecognitionOverlayDetailsContainerProps> = (props) => {
  const { recognition, showSentTo } = props;
  const dispatch = useDispatch();
  const closeRecognitionDetailsOverlay = useCallback(() => {
    dispatch(sharedActions.hideOverlay());
  }, [dispatch]);

  return (
    <div className="recognition-details-container-wrapper">
      <div className="close-icon">
        <Icon type="clear" onClick={closeRecognitionDetailsOverlay} />
      </div>
      <div className="recognition-details-icon">
        <div className="core-value-icon" style={{ backgroundImage: `url("${recognition.core_value?.icon_url}")` }} />
        <Icon type={`core-value-score core-value-score-${recognition.score}` as IconType} />
      </div>
      <div className="recognition-details-title">{recognition.core_value?.title}</div>
      <div className="recognition-details-description">{recognition.description}</div>
      <div className="recognition-details-created-info">
        {showSentTo && <div className="sent-to">to {getFullName(recognition.user as User)}</div>}
        <div>{getGbFormattedDate(recognition.created_at)}</div>
      </div>
    </div>
  );
};

export default RecognitionOverlayDetailsContainer;
