import React, { useCallback, useEffect, useMemo, useState } from "react";
import { FieldArray, FieldArrayRenderProps, Formik } from "formik";
import { AsyncSelectPickerInput, Button, ErrorMessageField, Icon, Input, SelectPickerInput } from "@shared/components";
import { Project, WorkType } from "@shared/models";
import { CreateProjectDto } from "@containers/Project/interfaces";
import { Option } from "@shared/interfaces";

import {
  getInitValues,
  validationSchema,
  prepareSubmitValue,
  PROJECT_TYPES_OPTIONS,
  handlers,
  PROJECT_STATUS_OPTIONS,
} from "./formHelpers";
import { WorkTypeErrorMessage } from "./components";

import "./index.scss";

interface ProjectFormProps {
  project: Project | null;
  workTypes: WorkType[];
  onDelete: () => void;
  onChangeForm: () => void;
  onCloseForm: () => void;
  onSubmitForm: (payload: CreateProjectDto) => void;
}

const ProjectForm: React.FunctionComponent<ProjectFormProps> = (props) => {
  const [workTypeOptions, setWorkTypeOptions] = useState<Option<number>[]>([]);
  const { project, onChangeForm, onCloseForm, onDelete, onSubmitForm, workTypes } = props;

  const formValues = useMemo(() => getInitValues(project), [project]);

  const addNewWorkType = useCallback((arrayHelpers: FieldArrayRenderProps) => {
    arrayHelpers.push({
      work_type_id: "",
      hours: 0,
    });
  }, []);

  useEffect(() => {
    const wT = workTypes.map((i) => {
      return {
        label: i.name,
        value: i.id,
      };
    });
    setWorkTypeOptions(wT);
  }, [workTypes]);

  return (
    <Formik
      validationSchema={validationSchema}
      enableReinitialize={true}
      validateOnBlur={false}
      onSubmit={(values, { setSubmitting }) => {
        onSubmitForm(prepareSubmitValue(values));
        setSubmitting(false);
      }}
      initialValues={formValues}
      validate={() => {
        onChangeForm();
      }}
    >
      {({ values, handleChange, handleBlur, handleSubmit, setFieldValue, touched, errors }) => (
        <div className="project-form">
          <form onSubmit={handleSubmit}>
            <div className="project-form-content">
              <div>
                <Input
                  type="text"
                  labelTitle="Name*"
                  name="name"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.name}
                  placeholder="Name"
                />
                <ErrorMessageField name="name" />
              </div>
              <div>
                <Input
                  type="date"
                  labelTitle="Start Date*"
                  name="start_date"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.start_date}
                  placeholder="Start Date"
                />
                <ErrorMessageField name="start_date" />
              </div>
              <div>
                <Input
                  type="text"
                  labelTitle="Code*"
                  name="code"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.code}
                  placeholder="Code"
                />
                <ErrorMessageField name="code" />
              </div>
              <div>
                <SelectPickerInput
                  searchable={false}
                  name="type"
                  data={PROJECT_TYPES_OPTIONS}
                  onChange={(value) => setFieldValue("type", value)}
                  onClean={handleChange}
                  labelTitle="Type*"
                  value={values.type}
                  placeholder="Type"
                />
                <ErrorMessageField name="type" />
              </div>
              <div className="average-cost-wrapper">
                <Input
                  type="number"
                  labelTitle="Average cost"
                  name="average_cost"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.average_cost}
                  placeholder="Average cost"
                  additionalSymbol="$"
                />
                <ErrorMessageField name="average_cost" />
              </div>
              <div>
                <AsyncSelectPickerInput
                  labelTitle="Manager*"
                  name="manager"
                  className="manager-input"
                  onChange={(v) => setFieldValue("manager", v)}
                  value={values.manager}
                  placeholder="Select Manager"
                  getData={handlers.user.getData}
                  selectData={handlers.user.selectData}
                  prepareOptionFunction={handlers.user.prepareOptionFunction}
                  loadingAction={handlers.user.loadingAction}
                />
                <ErrorMessageField name="manager" />
              </div>
              <div>
                <div>
                  <SelectPickerInput
                    searchable={false}
                    name="status"
                    cleanable={false}
                    data={PROJECT_STATUS_OPTIONS}
                    onChange={(value) => setFieldValue("status", value)}
                    labelTitle="Status"
                    value={values.status}
                  />
                </div>
                <ErrorMessageField name="status" />
              </div>
              <div className="work-types-line">
                <div className="work-types-header">Work Types</div>
                <FieldArray
                  name="work_types"
                  render={(arrayHelpers) => (
                    <>
                      <div className="work-types-content">
                        <div className="work-types-content-items">
                          <div>
                            {values.work_types.map((f, i) => (
                              <div className="work-type-row" key={`${i}`}>
                                <div className="work-type">
                                  <SelectPickerInput
                                    searchable={false}
                                    name={`work_types.${i}.work_type`}
                                    data={workTypeOptions}
                                    onChange={(value) => setFieldValue(`work_types.${i}.work_type_id`, value)}
                                    onClean={handleChange}
                                    labelTitle="Work Type*"
                                    value={values.work_types[i].work_type_id}
                                    placeholder="Work Type"
                                  />
                                  <ErrorMessageField name={`work_types.${i}.work_type_id`} />
                                </div>
                                <div className="hours">
                                  <Input
                                    type="number"
                                    labelClassName="work-type-label"
                                    labelTitle="Hours*"
                                    name={`work_types.${i}.hours`}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.work_types[i].hours}
                                    placeholder="Work Type"
                                    min={0}
                                  />
                                  <ErrorMessageField name={`work_types.${i}.hours`} />
                                </div>
                                <div className="actions">
                                  <Icon onClick={() => arrayHelpers.remove(i)} type="clear" />
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                        <div className="work-types-content-add-item">
                          <span onClick={() => addNewWorkType(arrayHelpers)}>+ Add New</span>
                        </div>
                        <div className="add-work-types-error-row">
                          <WorkTypeErrorMessage name="work_types" touched={touched} errors={errors} />
                        </div>
                      </div>
                    </>
                  )}
                />
              </div>
            </div>

            <div className="project-form-footer">
              <div className="project-form-footer-left">
                {project ? (
                  <Button type="button" className="clear padding" onClick={onDelete}>
                    Delete
                  </Button>
                ) : null}
              </div>

              <div className="project-form-footer-right">
                <Button type="button" className="close-btn margin-right" onClick={onCloseForm}>
                  Cancel
                </Button>
                <Button type="submit" className="accent padding">
                  {project ? "Save" : "Add Project"}
                </Button>
              </div>
            </div>
          </form>
        </div>
      )}
    </Formik>
  );
};

export default ProjectForm;
