import React, { useCallback } from "react";
import { Icon } from "@shared/components";

import "./index.scss";

interface DateToggleProps {
  dates: string[];
  selectedDate: string;
  onChangeDate: (date: string) => void;
}

const DateToggle: React.FunctionComponent<DateToggleProps> = (props) => {
  const { dates, selectedDate, onChangeDate } = props;
  const changeCurrentDate = useCallback(
    (action: "prev" | "next") => {
      const length = dates.length;
      const index = dates.findIndex((date) => date === selectedDate);
      const newDate =
        action === "prev" ? dates[index === 0 ? length - 1 : index - 1] : dates[index === length - 1 ? 0 : index + 1];

      onChangeDate(newDate);
    },
    [dates, onChangeDate, selectedDate],
  );

  return (
    <div className="date-toggle">
      <Icon
        type="chevron-left"
        onClick={() => {
          changeCurrentDate("prev");
        }}
      />
      <div className="date-content">Send Date: {selectedDate}</div>
      <Icon
        className="right"
        type="chevron-left"
        onClick={() => {
          changeCurrentDate("next");
        }}
      />
    </div>
  );
};

export default DateToggle;
