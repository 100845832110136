import React from "react";
import classnames from "classnames";
import { USER_STATUS } from "@shared/constants";

import "./index.scss";

export interface StatusLabelProps {
  className?: string;
  status: USER_STATUS;
}

const StatusLabel: React.FC<StatusLabelProps> = ({ className, status }) => (
  <div className={classnames("status-label", className, status)}>{status}</div>
);

export default StatusLabel;
