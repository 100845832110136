import React from "react";
import classnames from "classnames";
import { ORDER_TYPE } from "@shared/constants";

import { TableProperty, TableSort } from "../../interfaces";
import { SortableHeader } from "../SortableHeader";

import "./index.scss";

export interface HeadProps<T> extends TableSort {
  properties: TableProperty<T>[];
  total?: number;
  exportTable?: () => void;
}

function Head<T>(props: HeadProps<T>) {
  const { properties, sortBy, onChangeSorting, sortOrder, exportTable } = props;

  return (
    <div className="table-header">
      <>
        <div className="export">{exportTable ? <div onClick={exportTable}>EXPORT</div> : null}</div>
        {properties.map((prop) =>
          !prop.appearClickBlock ? (
            <div key={prop.title} className={classnames("table-header-cell", prop.headerClassName)}>
              {prop.sort ? (
                <SortableHeader
                  column={prop}
                  sortOrder={
                    sortBy && sortBy.find((sf) => prop.sort?.includes(sf)) && sortOrder ? sortOrder : ORDER_TYPE.NONE
                  }
                  onChangeSorting={onChangeSorting}
                />
              ) : (
                <div>{prop.title}</div>
              )}
            </div>
          ) : null,
        )}
      </>
    </div>
  );
}

export default Head;
