export enum SharedActionTypes {
  START_LOADING = "@@SHARED/START_LOADING",
  STOP_LOADING = "@@SHARED/STOP_LOADING",

  ADD_LOADING_TYPE = "@@SHARED/ADD_LOADING_TYPE",
  REMOVE_LOADING_TYPE = "@@SHARED/REMOVE_LOADING_TYPE",

  ADD_LOADING_SECTION = "@@SHARED/ADD_LOADING_SECTION",
  REMOVE_LOADING_SECTION = "@@SHARED/REMOVE_LOADING_SECTION",

  SHOW_NOTIFICATION = "@@SHARED/SHOW_NOTIFICATION",

  NAVIGATE = "@@SHARED/NAVIGATE",

  SHOW_MODAL = "@@SHARED/SHOW_MODAL",
  HIDE_MODAL = "@@SHARED/HIDE_MODAL",

  SHOW_OVERLAY = "@@SHARED/SHOW_OVERLAY",
  HIDE_OVERLAY = "@@SHARED/HIDE_OVERLAY",
  UPDATE_OVERLAY = "@@SHARED/UPDATE_OVERLAY",

  GET_USER_DETAIL = "@@SHARED/GET_USER_DETAIL",
  GET_USER_DETAIL_SUCCESS = "@@SHARED/GET_USER_DETAIL_SUCCESS",
  GET_USER_DETAIL_FAILURE = "@@SHARED/GET_USER_DETAIL_FAILURE",

  GET_USERS = "@@SHARED/GET_USERS",
  GET_USERS_SUCCESS = "@@SHARED/GET_USERS_SUCCESS",
  GET_USERS_FAILURE = "@@SHARED/GET_USERS_FAILURE",

  GET_TEAMS = "@@SHARED/GET_TEAMS",
  GET_TEAMS_SUCCESS = "@@SHARED/GET_TEAMS_SUCCESS",
  GET_TEAMS_FAILURE = "@@SHARED/GET_TEAMS_FAILURE",

  GET_PROJECTS = "@@SHARED/GET_PROJECTS",
  GET_PROJECTS_SUCCESS = "@@SHARED/GET_PROJECTS_SUCCESS",
  GET_PROJECTS_FAILURE = "@@SHARED/GET_PROJECTS_FAILURE",

  GET_ROLES = "@@SHARED/GET_ROLES",
  GET_ROLES_SUCCESS = "@@SHARED/GET_ROLES_SUCCESS",
  GET_ROLES_FAILURE = "@@SHARED/GET_ROLES_FAILURE",
}
