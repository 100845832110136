import React from "react";
import classnames from "classnames";

import { Label } from "../Label";

import "./index.scss";

export interface TextareaProps extends React.HTMLProps<HTMLTextAreaElement> {
  labelTitle?: string;
  labelClassName?: string;
  inputRef?: React.RefObject<HTMLTextAreaElement>;
  isRequired?: boolean;
}

const Textarea: React.FC<TextareaProps> = (props) => {
  const { labelTitle, labelClassName, inputRef, isRequired, ...inputProps } = props;
  return (
    <div className="textarea">
      {labelTitle ? <Label title={labelTitle} className={labelClassName} isRequired={isRequired} /> : null}
      <textarea ref={inputRef} {...inputProps} className={classnames(props.className)} rows={4} />
    </div>
  );
};

export default Textarea;
