import { produce } from "immer";
import { ActionType, createReducer } from "typesafe-actions";

import { GoalStateType } from "../interfaces";
import * as actions from "./actions";

type Action = ActionType<typeof actions>;

export const initialState: GoalStateType = {
  goals: [],
  goalsTotal: 0,
  filter: null,
  goalsIsLoaded: false,
  comments: [],
};

const reducer = createReducer<GoalStateType, Action>(initialState)
  .handleAction(actions.getGoals.success, (state, action) =>
    produce(state, (nextState) => {
      const { rows, count } = action.payload;
      nextState.goals = rows;
      nextState.goalsTotal = count;
      nextState.goalsIsLoaded = true;
    }),
  )
  .handleAction(actions.clearGoals, (state) =>
    produce(state, (nextState) => {
      nextState.goals = [];
      nextState.goalsTotal = 0;
      nextState.goalsIsLoaded = false;
      nextState.filter = null;
      nextState.comments = [];
    }),
  )
  .handleAction(actions.setFilter, (state, action) =>
    produce(state, (nextState) => {
      nextState.filter = action.payload;
    }),
  )
  .handleAction(actions.updateGoalProgress.success, (state, action) =>
    produce(state, (nextState) => {
      const filterStatus = nextState.filter?.status ?? "";
      const goals =
        filterStatus && filterStatus !== action.payload.goal.status
          ? nextState.goals.filter((goal) => goal.id !== action.payload.goal.id)
          : nextState.goals.map((goal) => (goal.id !== action.payload.goal.id ? goal : action.payload.goal));

      nextState.goals = [...goals];
      nextState.goalsTotal = goals.length;
    }),
  )
  .handleAction(actions.createGoal.success, (state, action) =>
    produce(state, (nextState) => {
      nextState.goals = [action.payload.goal, ...nextState.goals];
      nextState.goalsTotal++;
    }),
  )
  .handleAction(actions.updateGoal.success, (state, action) =>
    produce(state, (nextState) => {
      const filterStatus = nextState.filter?.status ?? "";
      const goals =
        filterStatus && filterStatus !== action.payload.goal.status
          ? nextState.goals.filter((goal) => goal.id !== action.payload.goal.id)
          : nextState.goals.map((goal) => (goal.id !== action.payload.goal.id ? goal : action.payload.goal));

      nextState.goals = [...goals];
      nextState.goalsTotal = goals.length;
    }),
  )
  .handleAction(actions.deleteGoal.success, (state, action) =>
    produce(state, (nextState) => {
      const goals = nextState.goals.filter((goal) => goal.id !== action.payload.goal_id);
      nextState.goals = [...goals];
      nextState.goalsTotal--;
    }),
  )
  .handleAction(actions.createComment.success, (state, action) =>
    produce(state, (nextState) => {
      if (action.payload.comment.goal_id) {
        const goals = nextState.goals;
        const foundGoalIndex = goals.findIndex((s) => action.payload.comment.goal_id === s.id);
        goals[foundGoalIndex].comments = [action.payload.comment, ...goals[foundGoalIndex].comments];
        nextState.goals = [...goals];
      }
      nextState.comments = [action.payload.comment, ...nextState.comments];
    }),
  )
  .handleAction(actions.getComments.success, (state, action) =>
    produce(state, (nextState) => {
      nextState.comments = action.payload;
    }),
  )
  .handleAction(actions.updateComment.success, (state, action) =>
    produce(state, (nextState) => {
      if (action.payload.comment.goal_id) {
        const goalIndex = nextState.goals.findIndex((goal) => goal.id === action.payload.comment.goal_id);
        const commentIndex = nextState.goals[goalIndex].comments.findIndex(
          (comment) => comment.id === action.payload.comment.id,
        );
        nextState.goals[goalIndex].comments[commentIndex] = action.payload.comment;
      }
      nextState.comments = nextState.comments.map((comment) =>
        comment.id !== action.payload.comment.id ? comment : action.payload.comment,
      );
    }),
  )
  .handleAction(actions.deleteComment.success, (state, action) =>
    produce(state, (nextState) => {
      if (action.payload.goal_id) {
        const goalIndex = nextState.goals.findIndex((goal) => goal.id === action.payload.goal_id);
        const comments = nextState.goals[goalIndex].comments.filter((comment) => comment.id !== action.payload.id);
        if (action.payload.next_comment) {
          const elementExist = comments.find((comment) => comment.id === action.payload.next_comment?.id);
          !elementExist && comments.unshift(action.payload.next_comment);
        }
        nextState.goals[goalIndex].comments = comments;
      }

      nextState.comments = nextState.comments.filter((comment) => comment.id !== action.payload.id);
    }),
  );

export { reducer as GoalReducer };
