import { AppState } from "@shared/interfaces";
import { createSelector } from "reselect";

const selectGoal = (state: AppState) => state.goal;

export const getGoals = () => createSelector(selectGoal, (state) => state.goals);
export const getGoalsTotal = () => createSelector(selectGoal, (state) => state.goalsTotal);
export const getFilter = () => createSelector(selectGoal, (state) => state.filter);
export const getGoalsIsLoaded = () => createSelector(selectGoal, (state) => state.goalsIsLoaded);

export const getComments = () => createSelector(selectGoal, (state) => state.comments);
