import { Recognition, CoreValue, UserCoreValuesSummary } from "@shared/models";
import { call, put, takeLatest } from "redux-saga/effects";
import { PaginatedResponse, ActionWithPayload, WithCallback } from "@shared/interfaces";
import { startLoading, stopLoading } from "@shared/store/actions";
import { RecognitionFilter } from "@shared/interfaces/RecognitionFilter.interface";
import {
  CreateRecognitionDto,
  CreateRecognitionSuccess,
  UpdateRecognitionDto,
} from "@containers/Recognition/interfaces";

import {
  getRecognition,
  getCoreValues,
  getUserCoreValuesSummary,
  createRecognition,
  getReceivedRecognitions,
  getSentRecognitions,
  updateRecognition,
} from "./actions";
import api from "../api";

function* getReceivedRecognitionsSaga({ payload }: ActionWithPayload<RecognitionFilter>) {
  try {
    yield put(startLoading());
    const response: PaginatedResponse<Recognition> = yield call(api.getRecognitions, payload);
    yield put(
      getReceivedRecognitions.success({
        ...response,
        is_clear: !payload.page,
      }),
    );
  } catch (error) {
    yield put(getReceivedRecognitions.failure(error as Error));
  } finally {
    yield put(stopLoading());
  }
}

function* getSentRecognitionsSaga({ payload }: ActionWithPayload<RecognitionFilter>) {
  try {
    yield put(startLoading());
    const response: PaginatedResponse<Recognition> = yield call(api.getRecognitions, payload);
    yield put(
      getSentRecognitions.success({
        ...response,
        is_clear: !payload.page,
      }),
    );
  } catch (error) {
    yield put(getSentRecognitions.failure(error as Error));
  } finally {
    yield put(stopLoading());
  }
}

function* getRecognitionSaga({ payload }: ActionWithPayload<number>) {
  try {
    yield put(startLoading());
    const response: Recognition = yield call(api.getRecognition, payload);
    yield put(
      getRecognition.success({
        ...response,
      }),
    );
  } catch (error) {
    yield put(getRecognition.failure(error as Error));
  } finally {
    yield put(stopLoading());
  }
}

function* getCoreValuesSaga() {
  try {
    yield put(startLoading());
    const response: CoreValue[] = yield call(api.getCoreValues);
    yield put(getCoreValues.success(response));
  } catch (error) {
    yield put(getCoreValues.failure(error as Error));
  } finally {
    yield put(stopLoading());
  }
}

function* getUserCoreValuesSummarySaga({ payload }: ActionWithPayload<number>) {
  try {
    yield put(startLoading());
    const response: UserCoreValuesSummary[] = yield call(api.getUserCoreValuesSummary, payload);
    yield put(getUserCoreValuesSummary.success(response));
  } catch (error) {
    yield put(getUserCoreValuesSummary.failure(error as Error));
  } finally {
    yield put(stopLoading());
  }
}

function* createRecognitionSaga({ payload }: ActionWithPayload<WithCallback<CreateRecognitionDto>>) {
  try {
    const { cb, ...rest } = payload;
    yield put(startLoading());

    const response: CreateRecognitionSuccess = yield call(api.createRecognition, rest);

    yield put(createRecognition.success(response));
    if (cb) {
      cb();
    }
  } catch (error) {
    yield put(createRecognition.failure(error as Error));
  } finally {
    yield put(stopLoading());
  }
}

function* updateRecognitionSaga({ payload }: ActionWithPayload<UpdateRecognitionDto>) {
  try {
    yield put(startLoading());

    const response: CreateRecognitionSuccess = yield call(api.updateRecognition, payload);

    yield put(updateRecognition.success(response));
  } catch (error) {
    yield put(updateRecognition.failure(error as Error));
  } finally {
    yield put(stopLoading());
  }
}

function* recognitionSagas() {
  yield takeLatest(getReceivedRecognitions.request, getReceivedRecognitionsSaga);
  yield takeLatest(getSentRecognitions.request, getSentRecognitionsSaga);
  yield takeLatest(getRecognition.request, getRecognitionSaga);
  yield takeLatest(getCoreValues.request, getCoreValuesSaga);
  yield takeLatest(getUserCoreValuesSummary.request, getUserCoreValuesSummarySaga);
  yield takeLatest(createRecognition.request, createRecognitionSaga);
  yield takeLatest(updateRecognition.request, updateRecognitionSaga);
}

export default recognitionSagas;
