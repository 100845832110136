import React, { FC } from "react";
import { Recognition, User } from "@shared/models";
import { Button, Icon } from "@shared/components";
import { getFullName, getGbFormattedDate } from "@shared/utils";
import { IconType } from "@shared/components/Icon/Icon";

import "./index.scss";

interface RecognitionListItemProps {
  recognition: Recognition;
  showSentTo?: boolean;
  showSentFrom: boolean;
  onListItemActionBtnClick: (recognition: Recognition) => void;
}

const RecognitionListItem: FC<RecognitionListItemProps> = (props) => {
  const { recognition, showSentTo, showSentFrom, onListItemActionBtnClick } = props;

  return (
    <div className="recognition-list-item-wrapper">
      <div className="recognition-list-item-icon" onClick={() => onListItemActionBtnClick(recognition)}>
        <div className="core-value-icon" style={{ backgroundImage: `url("${recognition.core_value?.icon_url}")` }} />
        <Icon type={`core-value-score core-value-score-${recognition.score}` as IconType} />
      </div>
      <div className="recognition-list-item-info">
        <div className="item-title">{recognition.core_value?.title}</div>
        <div className="item-description">{recognition.description}</div>
      </div>
      <div className="recognition-created-info">
        {showSentTo ? (
          <div className="sent-to">To {getFullName(recognition.user as User)}</div>
        ) : showSentFrom ? (
          <div className="sent-from">From {recognition.created_by}</div>
        ) : null}
        <div>{getGbFormattedDate(recognition.created_at)}</div>
      </div>

      <Button className="action-button" onClick={() => onListItemActionBtnClick(recognition)}>
        <Icon type="arrow-forward" />
      </Button>
    </div>
  );
};

export default RecognitionListItem;
