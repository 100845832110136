import React from "react";
import { RequiredMarker } from "@shared/components";

import "./index.scss";

interface LabelProps {
  title: string;
  className?: string;
  isRequired?: boolean;
}

const Label = (props: LabelProps) => {
  const { title, className, isRequired } = props;

  return (
    <label className={className}>
      {isRequired && <RequiredMarker />}
      {title}
    </label>
  );
};

export default Label;
