import React, { useMemo } from "react";
import { Formik } from "formik";
import { Button, ErrorMessageField, Input, Textarea } from "@shared/components";
import { Goal } from "@shared/models";
import { CreateGoalDto } from "@containers/Goal/interfaces";

import { getInitValues, validationSchema, prepareSubmitValue } from "./formHelpers";

import "./index.scss";

interface GoalFormProps {
  goal: Goal | null;
  onChangeForm: () => void;
  onCloseForm: () => void;
  onSubmitForm: (payload: CreateGoalDto) => void;
}

const GoalForm: React.FunctionComponent<GoalFormProps> = (props) => {
  const { goal, onChangeForm, onCloseForm, onSubmitForm } = props;

  const formValues = useMemo(() => getInitValues(goal), [goal]);

  return (
    <Formik
      validationSchema={validationSchema}
      enableReinitialize={true}
      validateOnBlur={false}
      onSubmit={(values, { setSubmitting }) => {
        onSubmitForm(prepareSubmitValue(values));
        setSubmitting(false);
      }}
      initialValues={formValues}
      validate={() => {
        onChangeForm();
      }}
    >
      {({ values, handleChange, handleBlur, handleSubmit }) => (
        <div className="goal-form">
          <form onSubmit={handleSubmit}>
            <div className="goal-form-content">
              <div className="input-wrapper">
                <Input
                  type="text"
                  className="text-input"
                  labelTitle="Goal Objective"
                  name="name"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.name}
                  placeholder="Name"
                />
                <ErrorMessageField name="name" />
              </div>
              <div className="input-wrapper">
                <Input
                  type="date"
                  className="date-input"
                  labelTitle="Due Date"
                  name="due_date"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.due_date}
                  placeholder="Start Date"
                />
                <ErrorMessageField name="due_date" />
              </div>
              <div className="input-wrapper">
                <Textarea
                  type="text"
                  className="textarea-input"
                  labelTitle="Short Description"
                  name="description"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.description}
                  placeholder="Description"
                />
                <ErrorMessageField name="description" />
              </div>
              <div className="input-wrapper goal-progress-input">
                <Input
                  type="number"
                  className="text-input number"
                  labelTitle="Progress"
                  name="progress"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.progress}
                  placeholder="progress"
                  min={0}
                  max={100}
                  additionalSymbol="%"
                />
                <ErrorMessageField name="progress" />
              </div>
            </div>
            <div className="goal-form-footer">
              <Button type="button" className="close-btn margin-right" onClick={onCloseForm}>
                Cancel
              </Button>
              <Button type="submit" className="accent padding">
                {goal ? "Save" : "Save Goal"}
              </Button>
            </div>
          </form>
        </div>
      )}
    </Formik>
  );
};

export default GoalForm;
