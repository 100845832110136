import React, { FormEvent } from "react";
import "./index.scss";
import { ErrorMessageField, Input } from "@shared/components";

interface UserFormTextInputProps {
  labelTitle: string;
  name: string;
  onChange: (e: FormEvent<HTMLInputElement>) => void;
  onBlur: (e: FormEvent<HTMLInputElement>) => void;
  value: string;
  placeholder: string;
}

const UserFormTextInput: React.FunctionComponent<UserFormTextInputProps> = (props) => {
  const { labelTitle, name, onChange, onBlur, value, placeholder } = props;
  return (
    <div className="user-form-input-container">
      <Input
        className="user-form-input"
        type="text"
        labelTitle={labelTitle}
        name={name}
        onChange={onChange}
        onBlur={onBlur}
        value={value}
        placeholder={placeholder}
      />
      <ErrorMessageField name={name} />
    </div>
  );
};

export default UserFormTextInput;
