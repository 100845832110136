import React, { useCallback, useState } from "react";
import { useDispatch } from "react-redux";
import { actions as sharedActions } from "@shared/store";
import { actions } from "@containers/Goal/store";
import { Goal } from "@shared/models";
import { GOALS_STATUS } from "@shared/constants";
import { Button, Textarea } from "@shared/components";

import "./index.scss";

const MAX_COMMENT_LENGTH = 250;

interface GoalCloseContainerProps {
  onClose: () => void;
  goal: Goal;
}

const GoalCloseContainer = ({ goal, onClose }: GoalCloseContainerProps) => {
  const dispatch = useDispatch();
  const [commentInput, setCommentInput] = useState("");

  const handleCommentChange = useCallback((e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const value = e.target.value.slice(0, MAX_COMMENT_LENGTH);
    setCommentInput(value);
  }, []);

  const handleSubmitForm = useCallback(() => {
    dispatch(sharedActions.hideOverlay());
    const comment = commentInput.trim();
    if (comment) {
      dispatch(actions.createComment.request({ description: comment, goal_id: goal.id }));
    }
    dispatch(actions.updateGoal.request({ status: GOALS_STATUS.CLOSED, id: Number(goal.id) }));
  }, [dispatch, goal, commentInput]);

  return (
    <div className="goal-close-content-wrapper">
      <div className="goal-close-heading">Are you sure you want to close this incomplete goal?</div>
      <div className="goal-close-content">
        You will still be able to access this goal and reopen it by applying the Сlosed filter.
      </div>
      <div className="comment-wrapper">
        <div className="comment-notice">Optional Comment</div>
        <Textarea
          className="comment-input"
          type="text"
          name="comment"
          onChange={handleCommentChange}
          value={commentInput}
          placeholder="Why are you closing out this goal?"
        />
        {commentInput && (
          <div className="counter">
            {commentInput.length}/{MAX_COMMENT_LENGTH}
          </div>
        )}
      </div>
      <div className="goal-close-actions">
        <Button appearance="subtle" className="subtle auto-width" onClick={onClose}>
          Cancel
        </Button>
        <Button type="submit" className="accent padding margin-left" onClick={handleSubmitForm}>
          Yes, close goal
        </Button>
      </div>
    </div>
  );
};

export default GoalCloseContainer;
