import React, { RefObject, useCallback, useRef, useState } from "react";
import classnames from "classnames";
import { Button } from "@shared/components";
import { ReactComponent as FilterIcon } from "@assets/images/icons/filter/filter.svg";
import { GOALS_STATUS } from "@shared/constants";
import { GoalsFilter } from "@shared/interfaces";
import { useOutsideClick } from "@shared/hooks";
import { Goal } from "@shared/models";

import "./index.scss";

const contractorDropdownOptions = [
  {
    name: GOALS_STATUS.IN_PROGRESS,
    value: GOALS_STATUS.IN_PROGRESS,
  },
  {
    name: GOALS_STATUS.COMPLETED,
    value: GOALS_STATUS.COMPLETED,
  },
  {
    name: GOALS_STATUS.CLOSED,
    value: GOALS_STATUS.CLOSED,
  },
  {
    name: "All",
    value: "",
  },
];

export interface GoalHeaderProps {
  total: number;
  filter: GoalsFilter | null;
  changeGoalsFilter: (updatedFilter: Partial<GoalsFilter>) => void;
  addNewGoal: (goal?: Goal) => void;
}

const GoalHeader: React.FC<GoalHeaderProps> = (props) => {
  const { total, filter, changeGoalsFilter, addNewGoal } = props;

  const [isFilterDropdownOpened, setFilterDropdownOpened] = useState<boolean>(false);
  const filterDropdownRef = useRef<HTMLDivElement | null>(null);
  const { isOutside } = useOutsideClick(
    filterDropdownRef as RefObject<{ contains: (target: EventTarget | null) => boolean }>,
  );

  const onClickOutside = useCallback(() => {
    setFilterDropdownOpened(false);
    return " closed";
  }, []);

  return (
    <div className="goal-list-header">
      <div className="goal-list-header-left">
        <div>Goals</div>
        <div className="goal-total">{total}</div>
      </div>
      <div className="goal-list-header-right">
        <Button
          className="filter-btn secondary margin-right"
          onClick={() => setFilterDropdownOpened(!isFilterDropdownOpened)}
        >
          <FilterIcon />
        </Button>
        {isFilterDropdownOpened ? (
          <div
            className={classnames("filter-dropdown", isOutside ? onClickOutside() : "opened")}
            ref={filterDropdownRef}
          >
            {contractorDropdownOptions.map((option) => (
              <label className="dropdown-label" key={option.value}>
                <input
                  name={option.name}
                  type="radio"
                  className="filter-radio"
                  value={option.value}
                  checked={option.value === filter?.status}
                  onChange={(e) => changeGoalsFilter({ status: e.target.value as GOALS_STATUS })}
                />
                <div className="dropdown-label-text">{option.name}</div>
              </label>
            ))}
          </div>
        ) : null}
        <Button className="accent padding add-button" onClick={() => addNewGoal()}>
          New Goal
        </Button>
      </div>
    </div>
  );
};

export default GoalHeader;
