import React, { useEffect, useState } from "react";
import { Icon } from "@shared/components";
import { Process } from "@shared/models";
import { SelectedProcessType } from "@containers/Procedure/interfaces";

import "./index.scss";

interface ProcessListProps {
  processes: Process[];
  selectProcess: (process: SelectedProcessType) => void;
  proceduresTotal: number;
}

const ProcessList: React.FunctionComponent<ProcessListProps> = (props) => {
  const { processes, selectProcess, proceduresTotal } = props;
  const [filteredProcesses, setFilteredProcesses] = useState<Process[]>([]);

  useEffect(() => {
    const filteredProcesses = processes.filter((process) => !process.parent_id);
    setFilteredProcesses(filteredProcesses);
  }, [processes]);

  return (
    <div className="processes-content">
      {filteredProcesses.map((process) => (
        <div
          className="process-wrapper"
          key={process.id}
          onClick={() => selectProcess({ id: process.id, name: process.name })}
        >
          <div className="process">
            <div className="image-wrapper">{process.image && <img src={process.image} alt={process.name} />}</div>
            <div className="title-wrapper">
              <div className="title">{process.name}</div>
              <div className="counter">{process.procedures?.length ?? 0} procedures</div>
            </div>
            <div className="button-wrapper">
              <button className="button">
                <Icon type="arrow-long" />
              </button>
            </div>
          </div>
        </div>
      ))}
      <div className="process-wrapper" key={"all"} onClick={() => selectProcess({ id: null, name: "All" })}>
        <div className="process">
          <div className="image-wrapper">
            {/*<img src="https://923home-files.s3.amazonaws.com/Processes/hr.svg" alt="hr" />*/}
          </div>
          <div className="title-wrapper">
            <div className="title">All</div>
            <div className="counter">{proceduresTotal} procedures</div>
          </div>
          <div className="button-wrapper">
            <button className="button">
              <Icon type="arrow-long" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProcessList;
