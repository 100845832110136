import React from "react";

import "./index.scss";

export interface PublishingStatusColumn {
  status: string;
}

function PublishingStatusColumn(props: PublishingStatusColumn) {
  const { status } = props;

  return (
    <div className={`procedure-status ${status}`}>
      <div>{status}</div>
    </div>
  );
}

export default PublishingStatusColumn;
