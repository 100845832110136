import { INPUT_TYPES } from "@shared/constants";
import React, { useCallback, useMemo, useState } from "react";
import classnames from "classnames";
import { Icon } from "@shared/components/Icon";

import { Label } from "../Label";

import "./index.scss";

export interface InputProps extends React.HTMLProps<HTMLInputElement> {
  labelTitle?: string;
  labelClassName?: string;
  additionalSymbol?: string;
  inputRef?: React.RefObject<HTMLInputElement>;
}

const Input: React.FC<InputProps> = (props) => {
  const { labelTitle, labelClassName, type, inputRef, additionalSymbol, ...inputProps } = props;
  const [inputType, setInputType] = useState(type);

  const isPasswordType = useMemo(() => {
    return type === INPUT_TYPES.PASSWORD;
  }, [type]);

  const onShowPassword = useCallback(() => {
    setInputType((prev) => (prev === INPUT_TYPES.PASSWORD ? INPUT_TYPES.TEXT : INPUT_TYPES.PASSWORD));
  }, []);

  return (
    <div className="input">
      {labelTitle ? <Label title={labelTitle} className={labelClassName} /> : null}
      {isPasswordType ? (
        <div className="view-password">
          <Icon type={inputType === INPUT_TYPES.PASSWORD ? "eye" : "eye-close"} onClick={onShowPassword} />
        </div>
      ) : null}
      {additionalSymbol && (
        <div className="additional-symbol-wrapper">
          <span className="additional-symbol">{additionalSymbol}</span>
        </div>
      )}
      <input
        ref={inputRef}
        {...inputProps}
        type={inputType}
        className={classnames(props.className, { password: isPasswordType })}
      />
    </div>
  );
};

export default Input;
