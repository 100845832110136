import React, { useEffect } from "react";
import { Icon, Loader } from "@shared/components";
import { useDispatch, useSelector } from "react-redux";
import { actions, selectors } from "@containers/Goal/store";
import { useLoader } from "@shared/hooks";
import CommentListContainer from "@containers/Goal/containers/CommentsContainer/CommentListContainer/CommentListContainer";
import CommentCreateContainer from "@containers/Goal/containers/CommentsContainer/CommentCreateContainer/CommentCreateContainer";

import "./index.scss";

interface CommentsContainerProps {
  onClose?: () => void;
  goal_id?: number;
  contractor_id?: number;
}

const CommentsContainer = ({ goal_id, contractor_id, onClose }: CommentsContainerProps) => {
  const dispatch = useDispatch();
  const comments = useSelector(selectors.getComments());

  const { isLoading } = useLoader({
    name: "GoalCommentsList",
    actionTypes: [actions.getComments, actions.createComment, actions.updateComment, actions.deleteComment],
  });

  useEffect(() => {
    dispatch(actions.getComments.request({ goal_id, contractor_id }));
  }, [dispatch, goal_id, contractor_id]);

  return (
    <div className="comments-container">
      {onClose && (
        <div className="comments-header">
          <div className="title">Comments</div>
          <div className="close-wrapper" onClick={onClose}>
            <Icon type="close" />
          </div>
        </div>
      )}
      <CommentCreateContainer goal_id={goal_id} contractor_id={contractor_id} />
      {isLoading ? <Loader position="center" /> : <CommentListContainer comments={comments} />}
    </div>
  );
};

export default CommentsContainer;
