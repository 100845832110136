import React, { MouseEvent, useCallback, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { MenuItem } from "@shared/interfaces";
import { AccessControl } from "@shared/components/AccessControl";
import { isUserContractor } from "@shared/utils";
import { selectors as sharedSelectors } from "@shared/store";
import { NameOfChildRoutes } from "@shared/constants";
import classnames from "classnames";

import "./index.scss";

interface SidebarItemProps {
  item: MenuItem;
  currentPathname: string;
}

const SidebarItem = (props: SidebarItemProps) => {
  const { item, currentPathname } = props;
  const { path, permission } = item;
  const [isTooltipVisible, setTooltipVisible] = useState(false);

  const authenticatedUser = useSelector(sharedSelectors.getUserDetails());
  const isCurrentUserContractor = isUserContractor(authenticatedUser ? authenticatedUser : undefined);

  const directPath = useMemo(() => {
    if (isCurrentUserContractor && path === NameOfChildRoutes.PROCEDURES.ROOT) {
      return `${NameOfChildRoutes.PROCEDURES.ROOT}${NameOfChildRoutes.PROCEDURES.PROCESSES}`;
    }

    return path;
  }, [path, isCurrentUserContractor]);

  const isActive = useMemo(() => {
    const isExcludePath = item.exclude_paths?.find((ep: string) => currentPathname.includes(ep));
    return currentPathname.includes(item.root_path) && !isExcludePath;
  }, [currentPathname, item.root_path, item.exclude_paths]);

  const handleNavLinkClick = useCallback(
    (event: MouseEvent) => {
      if (item.onClick) {
        item.onClick();
        event.preventDefault();
        (event.currentTarget as HTMLElement).blur();
      }
    },
    [item],
  );

  return (
    <AccessControl permission={permission}>
      <div className={classnames("sidebar-item", item.onClick ? "schema" : "")}>
        <NavLink
          to={directPath}
          onClick={handleNavLinkClick}
          end={false}
          className={isActive ? "active" : undefined}
          onMouseEnter={() => setTooltipVisible(true)}
          onMouseLeave={() => setTooltipVisible(false)}
        >
          <div className="item-icon">
            <item.icon />
          </div>
          {isTooltipVisible && (
            <div className="tooltip">
              <span>{item.name}</span>
            </div>
          )}
        </NavLink>
      </div>
    </AccessControl>
  );
};

export default SidebarItem;
