import React, { useCallback } from "react";
import classnames from "classnames";
import { useDebouncedFunction } from "@shared/hooks";

import { TableProperty, TableSort } from "./interfaces";
import { Head, Row } from "./components";

import "./index.scss";

export interface TableProps<T> extends TableSort {
  items: T[];
  exportTable?: () => void;
  rowComponent?: typeof Row;
  properties: TableProperty<T>[];
  onFinishScroll?: () => void;
  onRowClick?: (item: T) => void;
  busy?: boolean;
  isBottomLoading?: boolean;
  className?: string;
  rowClassName?: (item: T, idx?: number) => (string | undefined) | string;
  disableRow?: (item: T, idx?: number) => boolean;
}

function Table<T>(props: TableProps<T>) {
  const {
    exportTable,
    sortOrder,
    sortBy,
    onChangeSorting,
    items,
    properties,
    onFinishScroll,
    onRowClick,
    busy,
    isBottomLoading,
    rowComponent: RowComponent = Row,
    className,
    disableRow,
    rowClassName,
  } = props;

  const debouncedFinishScroll = useDebouncedFunction(onFinishScroll);

  const onScroll = useCallback(
    (event: React.SyntheticEvent<Element>) => {
      const maxScroll = event.currentTarget.scrollHeight - event.currentTarget.clientHeight;
      if (event.currentTarget.scrollTop >= maxScroll - 1 && debouncedFinishScroll) {
        debouncedFinishScroll();
      }
    },
    [debouncedFinishScroll],
  );

  const getRowClassName = useCallback(
    (item: T, idx: number) => {
      if (!rowClassName) return;

      if (typeof rowClassName === "string") {
        return rowClassName as string;
      }

      return rowClassName(item, idx);
    },
    [rowClassName],
  );

  return (
    <div
      className={classnames(
        "table-wrapper",
        {
          "table-loading": busy,
          "table-loading-bottom": isBottomLoading,
          "table-empty": !items || !items.length,
        },
        className,
      )}
    >
      <Head<T>
        properties={properties}
        sortBy={sortBy}
        sortOrder={sortOrder}
        onChangeSorting={onChangeSorting}
        exportTable={exportTable}
      />
      <div className={classnames("table-content", { "table-clickable": onRowClick })} onScroll={onScroll}>
        {items.map((item, index) => (
          <RowComponent<T>
            className={getRowClassName(item, index)}
            itemIndex={index}
            key={index}
            disabled={disableRow?.(item)}
            item={item}
            properties={properties}
            onClick={() => onRowClick?.(item)}
          />
        ))}
      </div>
    </div>
  );
}

export default Table;
