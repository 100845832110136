import { produce } from "immer";
import { ActionType, createReducer } from "typesafe-actions";

import { ChartStateType } from "../interfaces";
import * as actions from "./actions";

type Action = ActionType<typeof actions>;

export const initialState: ChartStateType = {
  chart: null,
};

const reducer = createReducer<ChartStateType, Action>(initialState).handleAction(
  actions.getChartData.success,
  (state, action) =>
    produce(state, (nextState) => {
      nextState.chart = action.payload;
    }),
);

export { reducer as ChartReducer };
