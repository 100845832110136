export enum PERMISSION {
  CREATE_PROJECT = "Create project",
  EDIT_PROJECT = "Edit project",
  VIEW_PROJECTS = "View projects",
  DELETE_PROJECT = "Delete project",

  CREATE_PROCEDURE = "Create procedure",
  EDIT_PROCEDURE = "Edit procedure",
  VIEW_PROCEDURES = "View procedures",
  DELETE_PROCEDURE = "Delete procedure",
  ASSIGN_PROCEDURE = "Assign procedure",

  CREATE_SURVEY = "Create survey",
  EDIT_SURVEY = "Edit survey",
  VIEW_SURVEYS = "View surveys",
  DELETE_SURVEY = "Delete survey",
  ASSIGN_SURVEY = "Assign survey",

  CREATE_MEETING = "Create meeting",
  EDIT_MEETING = "Edit meeting",
  VIEW_MEETINGS = "View meetings",
  DELETE_MEETING = "Delete meeting",

  CREATE_USER = "Create user",
  EDIT_USER = "Edit user",
  VIEW_USERS = "View users",
  DELETE_USER = "Delete user",

  CREATE_GOAL = "Create goal",
  EDIT_GOAL = "Edit goal",
  VIEW_GOALS = "View goals",
  DELETE_GOAL = "Delete goal",
  ASSIGN_GOAL = "Assign goal",

  CREATE_RECOGNITION = "Create recognition",
  EDIT_RECOGNITION = "Edit recognition",
  VIEW_RECOGNITIONS = "View recognitions",
  DELETE_RECOGNITION = "Delete recognition",

  CREATE_COMMENT = "Create comment",
  EDIT_COMMENT = "Edit comment",
  VIEW_COMMENTS = "View comments",
  DELETE_COMMENT = "Delete comment",

  CONTRACTOR_MODE = "Contractor Mode",
  TEAM_LEAD_MODE = "Team Lead Mode",
  SYNC_TEAM_WITH_HARVEST = "Sync Team With Harvest",
}
