import { AppState } from "@shared/interfaces";
import { createSelector } from "reselect";

const selectProcedure = (state: AppState) => state.procedure;

export const getProcedures = () => createSelector(selectProcedure, (state) => state.procedures);
export const getProcedure = () => createSelector(selectProcedure, (state) => state.procedure);
export const getProcesses = () => createSelector(selectProcedure, (state) => state.processes);

export const getProceduresTotal = () => createSelector(selectProcedure, (state) => state.proceduresTotal);
export const getFilter = () => createSelector(selectProcedure, (state) => state.filter);
export const getContractorProceduresFilter = () =>
  createSelector(selectProcedure, (state) => state.contractorProceduresFilter);
export const getUserOnboardingSteps = () => createSelector(selectProcedure, (state) => state.userOnboardingSteps);
export const getProcessesProceduresIsLoaded = () =>
  createSelector(selectProcedure, (state) => state.processesProceduresIsLoaded);
