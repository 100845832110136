import { produce } from "immer";
import { ActionType, createReducer } from "typesafe-actions";
import { RecognitionFilter } from "@shared/interfaces/RecognitionFilter.interface";

import { RecognitionStateType } from "../interfaces";
import * as actions from "./actions";

type Action = ActionType<typeof actions>;

const defaultFilter: RecognitionFilter = {
  page: 0,
  limit: 50,
  search: "",
};

export const initialState: RecognitionStateType = {
  recognition: null,
  receivedRecognitions: [],
  receivedRecognitionsTotal: 0,
  receivedRecognitionsIsLoaded: false,
  sentRecognitions: [],
  sentRecognitionsTotal: 0,
  sentRecognitionsIsLoaded: false,
  coreValues: [],
  userCoreValuesSummary: [],
  receivedFilter: { ...defaultFilter },
  sentFilter: { ...defaultFilter },
};

const reducer = createReducer<RecognitionStateType, Action>(initialState)
  .handleAction(actions.getReceivedRecognitions.success, (state, action) =>
    produce(state, (nextState) => {
      const { rows, count, is_clear } = action.payload;
      nextState.receivedRecognitions = !is_clear ? [...nextState.receivedRecognitions, ...rows] : [...rows];
      nextState.receivedRecognitionsTotal = count;
      nextState.receivedRecognitionsIsLoaded = true;
    }),
  )
  .handleAction(actions.getSentRecognitions.success, (state, action) =>
    produce(state, (nextState) => {
      const { rows, count, is_clear } = action.payload;
      nextState.sentRecognitions = !is_clear ? [...nextState.sentRecognitions, ...rows] : [...rows];
      nextState.sentRecognitionsTotal = count;
      nextState.sentRecognitionsIsLoaded = true;
    }),
  )
  .handleAction(actions.getRecognition.success, (state, action) =>
    produce(state, (nextState) => {
      nextState.recognition = action.payload;
    }),
  )
  .handleAction(actions.createRecognition.success, (state, action) =>
    produce(state, (nextState) => {
      nextState.sentRecognitions = [action.payload.recognition, ...nextState.sentRecognitions];
      nextState.sentRecognitionsTotal += 1;
    }),
  )
  .handleAction(actions.updateRecognition.success, (state, action) =>
    produce(state, (nextState) => {
      nextState.receivedRecognitions = nextState.receivedRecognitions.map((recognition) =>
        recognition.id === action.payload.recognition.id ? action.payload.recognition : recognition,
      );
    }),
  )
  .handleAction(actions.getCoreValues.success, (state, action) =>
    produce(state, (nextState) => {
      nextState.coreValues = action.payload;
    }),
  )

  .handleAction(actions.getUserCoreValuesSummary.success, (state, action) =>
    produce(state, (nextState) => {
      nextState.userCoreValuesSummary = action.payload;
    }),
  )

  .handleAction(actions.setReceivedFilter, (state, action) =>
    produce(state, (nextState) => {
      nextState.receivedFilter = action.payload || { ...defaultFilter };
    }),
  )
  .handleAction(actions.setSentFilter, (state, action) =>
    produce(state, (nextState) => {
      nextState.sentFilter = action.payload || { ...defaultFilter };
    }),
  )

  .handleAction(actions.clearCoreValues, (state) =>
    produce(state, (nextState) => {
      nextState.coreValues = [];
    }),
  )

  .handleAction(actions.clearRecognitions, (state) =>
    produce(state, (nextState) => {
      nextState.receivedRecognitions = [];
      nextState.receivedRecognitionsTotal = 0;
      nextState.receivedRecognitionsIsLoaded = false;
      nextState.sentRecognitions = [];
      nextState.sentRecognitionsTotal = 0;
      nextState.sentRecognitionsIsLoaded = false;
    }),
  )

  .handleAction(actions.clearUserCoreValuesSummary, (state) =>
    produce(state, (nextState) => {
      nextState.userCoreValuesSummary = [];
    }),
  );

export { reducer as RecognitionReducer };
