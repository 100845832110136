import { call, put, takeLatest } from "redux-saga/effects";
import { startLoading, stopLoading, getUserDetails } from "@shared/store/actions";
import { ActionWithPayload, WithCallback, WithNavigate } from "@shared/interfaces";
import { NamesOfParentRoutes } from "@shared/constants";
import history from "@shared/history";
import { tokenHandler } from "@shared/utils";
import { User } from "@shared/models";

import { logout, login, googleLogin, googleRegister } from "./actions";
import { AuthActionTypes } from "./constants";
import { GoogleLoginShape, LoginShape, LoginSuccess } from "../interfaces";
import api from "../api";

function* logoutSaga() {
  yield localStorage.clear();
  const searchParams = window.location.search;
  localStorage.setItem("redirectUrl", `${window.location.pathname}${searchParams ? searchParams : ""}`);
  yield put(logout.success());
  yield put(getUserDetails.success(null));
  yield history.push(NamesOfParentRoutes.AUTH);
}

function* loginSaga({ payload }: ActionWithPayload<WithNavigate<LoginShape>>) {
  try {
    yield put(startLoading());
    const response: LoginSuccess = yield call(api.login, payload);
    const { token } = response;

    if (token) {
      yield tokenHandler.set(token);
      yield put(getUserDetails.request());
    }

    yield put(login.success(response));
    yield put(stopLoading());

    yield history.push("/");
  } catch (error) {
    yield put(login.failure(error as Error));
    yield put(stopLoading());
  }
}

function* googleLoginSaga({ payload }: ActionWithPayload<WithCallback<GoogleLoginShape>>) {
  try {
    const { cb, ...rest } = payload;
    yield put(startLoading());
    const response: User = yield call(api.googleLogin, rest);

    yield put(getUserDetails.success(response));

    yield put(stopLoading());
    if (cb) {
      cb();
    }
  } catch (error) {
    yield put(googleLogin.failure(error as Error));
    yield put(stopLoading());
  }
}

function* googleRegisterSaga({ payload }: ActionWithPayload<WithNavigate<GoogleLoginShape>>) {
  try {
    yield put(startLoading());
    const response: LoginSuccess = yield call(api.googleRegister, payload);
    const { token } = response;

    if (token) {
      yield tokenHandler.set(token);
      yield put(getUserDetails.request());
    }

    yield put(login.success(response));
    yield put(stopLoading());

    yield history.push("/");
  } catch (error) {
    yield put(googleRegister.failure(error as Error));
    yield put(stopLoading());
  }
}

function* authSagas() {
  yield takeLatest(AuthActionTypes.LOGOUT, logoutSaga);
  yield takeLatest(AuthActionTypes.LOGIN, loginSaga);
  yield takeLatest(AuthActionTypes.GOOGLE_LOGIN, googleLoginSaga);
  yield takeLatest(AuthActionTypes.GOOGLE_REGISTER, googleRegisterSaga);
}

export default authSagas;
