import * as Yup from "yup";
import { FORM_ERROR_MESSAGES } from "@shared/constants";
import { Process } from "@shared/models";
import { AssignProcedureDto, AssignProcedureFormShape } from "@containers/User/interfaces/UserForm.interface";

export const validationSchema = Yup.object().shape({
  procedures: Yup.array().of(Yup.number()).min(1, FORM_ERROR_MESSAGES.REQUIRED),
});

export const getInitValues = (processes: Process[]): AssignProcedureFormShape => ({
  process: processes[0]?.id || null,
  procedures: [],
});

export const prepareSubmitValue = (values: AssignProcedureFormShape): Omit<AssignProcedureDto, "user_id"> => {
  return {
    procedure_ids: values.procedures,
  };
};
