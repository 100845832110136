import React, { FC, useCallback } from "react";
import { generatePath } from "react-router";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Button } from "@shared/components";
import { UserSurvey } from "@shared/models";
import { USER_SURVEY_STATUS } from "@shared/constants";

import "./index.scss";

interface UserSurveyActionProps {
  userSurvey: UserSurvey;
}

const UserSurveyActions: FC<UserSurveyActionProps> = ({ userSurvey }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  const openSurveyDetailsPage = useCallback(() => {
    searchParams.set("activeTab", "3");
    setSearchParams(searchParams);
    navigate(`${generatePath("/surveys/:id", { id: userSurvey.id.toString() })}?viewResult=true`);
  }, [navigate, searchParams, setSearchParams, userSurvey]);

  return (
    <div className="user-survey-actions">
      {userSurvey.status === USER_SURVEY_STATUS.COMPLETED && !userSurvey.survey.is_anonymous && (
        <Button type="button" className="info" onClick={openSurveyDetailsPage}>
          View survey
        </Button>
      )}
    </div>
  );
};

export default UserSurveyActions;
