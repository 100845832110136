import React, { FC, useMemo } from "react";
import { CreateRecognitionDto } from "@containers/Recognition/interfaces";
import { Formik } from "formik";
import { AsyncSelectPickerInput, Button, ErrorMessageField, SelectPickerInput, Textarea } from "@shared/components";
import {
  getInitValues,
  handlers,
  prepareSubmitValue,
  RECOGNITION_SCORE_OPTIONS,
  validationSchema,
} from "@containers/Recognition/components/RecognitionCreateContainer/RecognitionForm/formHelpers";
import { selectors as sharedSelectors } from "@shared/store/";
import { useSelector } from "react-redux";

import "./index.scss";

interface RecognitionFormProps {
  onChangeForm: () => void;
  onCloseForm: () => void;
  onSubmitForm: (payload: CreateRecognitionDto) => void;
}

const RecognitionForm: FC<RecognitionFormProps> = (props) => {
  const { onChangeForm, onCloseForm, onSubmitForm } = props;
  const currentUser = useSelector(sharedSelectors.getUserDetails());

  const formValues = useMemo(() => getInitValues(), []);

  return (
    <Formik
      validationSchema={validationSchema}
      enableReinitialize={true}
      validateOnBlur={false}
      onSubmit={(values, { setSubmitting }) => {
        onSubmitForm(prepareSubmitValue(values));
        setSubmitting(false);
      }}
      initialValues={formValues}
      validate={() => {
        onChangeForm();
      }}
    >
      {({ values, handleChange, handleBlur, handleSubmit, setFieldValue }) => (
        <div className="recognition-form">
          <form onSubmit={handleSubmit}>
            <div className="recognition-form-content">
              <div className="recognition-select-input-group">
                <div className="recognition-core-value-input-wrapper">
                  <div className="recognition-core-value-input">
                    <AsyncSelectPickerInput
                      labelTitle="Value Title"
                      name="core_value"
                      className="core-value-input"
                      onChange={(v) => setFieldValue("core_value", v)}
                      value={values.core_value}
                      placeholder="Select Value Title"
                      getData={handlers.coreValues.getData}
                      selectData={handlers.coreValues.selectData}
                      prepareOptionFunction={handlers.coreValues.prepareOptionFunction}
                      loadingAction={handlers.coreValues.loadingAction}
                      searchable={false}
                    />
                  </div>
                  <ErrorMessageField name="core_value" />
                </div>
                <div className="recognition-score-input-wrapper">
                  <div className="recognition-score-input">
                    <SelectPickerInput
                      className="score-input"
                      labelTitle="Recognition Level"
                      name="score"
                      onChange={(v) => setFieldValue("score", v)}
                      value={values.score}
                      data={RECOGNITION_SCORE_OPTIONS}
                      searchable={false}
                      cleanable={false}
                    />
                    <ErrorMessageField name="score" />
                  </div>
                </div>
              </div>

              <div className="recognition-teammate-select-input-wrapper">
                <div className="recognition-teammate-select-input">
                  <AsyncSelectPickerInput
                    labelTitle="Select Teammate"
                    name="user"
                    className="teammate-select-input"
                    onChange={(v) => setFieldValue("user", v)}
                    value={values.user}
                    placeholder="Select Teammate"
                    getData={handlers.user.getData}
                    selectData={handlers.user.selectData}
                    prepareOptionFunction={handlers.user.prepareOptionFunction}
                    loadingAction={handlers.user.loadingAction}
                    onSort={handlers.user.onSort}
                    disabledOptionsValues={currentUser ? [String(currentUser.id)] : []}
                  />
                </div>
                <ErrorMessageField name="user" />
              </div>
              <div className="description-textarea-wrapper">
                <Textarea
                  className="recognition-description-input"
                  type="text"
                  labelTitle="Description"
                  name="description"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.description}
                  placeholder="Please provide description"
                />
                <ErrorMessageField name="description" />
              </div>
            </div>

            <div className="recognition-form-footer">
              <label className="recognition__anonymous-label">
                <input
                  name="is_anonymous"
                  type="checkbox"
                  className="recognition__anonymous-checkbox"
                  checked={values.is_anonymous}
                  onChange={handleChange}
                />
                <div className="recognition__anonymous-text">Keep anonymous</div>
              </label>
              <Button type="button" className="close-btn margin-right" onClick={onCloseForm}>
                Cancel
              </Button>
              <Button type="submit" className="accent padding">
                Add Recognition
              </Button>
            </div>
          </form>
        </div>
      )}
    </Formik>
  );
};

export default RecognitionForm;
