import { SURVEY_STATUS } from "@shared/constants";

import { User } from "./User";

export enum SURVEY_FIELD_TYPE {
  SINGLE = "Single",
  MULTIPLE = "Multiple",
  TEXT = "Text",
}
export enum SURVEY_TYPE {
  SURVEY = "Survey",
  QUIZ = "Quiz",
}

export interface Survey {
  readonly id: number;

  name: string;
  description?: string;
  status: SURVEY_STATUS;
  reccurence_days: number | null;
  last_sent: Date | null;
  is_anonymous: boolean;
  title?: string;
  subtitle?: string;
  type: SURVEY_TYPE | null;

  is_deleted: boolean;
  created_at: Date;
  updated_at: Date;
  created_by: string;
  updated_by: string;
  survey_fields?: SurveyFields[];
}
export interface SurveyFields {
  readonly id: number;
  readonly survey_id: number;
  type: SURVEY_FIELD_TYPE;
  order: number;
  required: boolean;
  title: string;
  description?: string;
  survey_field_options: {
    readonly id: number;
    readonly survey_field_id: number;
    value: string;
    percent_statistic?: number;
    is_correct: boolean | null;
  }[];
  answers_statistic?: string[];
}

export interface StatisticUserCompletedSurvey {
  created_at: string;
  complete_date?: string;
  user: User;
  user_survey_id: number;
}

export interface SurveyStatistic {
  users_got_survey_count: number;
  users_completed_survey_count: number;
  users_not_completed_survey_count: number;
  users_completed_survey: StatisticUserCompletedSurvey[];
  users_not_completed_survey: StatisticUserCompletedSurvey[];
  date: string;
}

export interface SurveyStatisticDetails {
  survey_fields: SurveyFields[];
  selects: {
    count: number;
    survey_field_option_id: number;
  }[];
  texts: {
    survey_field_id: number;
    text: string;
  }[];
}
