import { AppState } from "@shared/interfaces";
import { createSelector } from "reselect";

const selectRecognition = (state: AppState) => state.recognition;

export const getReceivedRecognitions = () => createSelector(selectRecognition, (state) => state.receivedRecognitions);
export const getReceivedRecognitionsTotal = () =>
  createSelector(selectRecognition, (state) => state.receivedRecognitionsTotal);
export const getReceivedRecognitionsIsLoaded = () =>
  createSelector(selectRecognition, (state) => state.receivedRecognitionsIsLoaded);
export const getReceivedRecognitionsFilter = () => createSelector(selectRecognition, (state) => state.receivedFilter);

export const getSentRecognitions = () => createSelector(selectRecognition, (state) => state.sentRecognitions);
export const getSentRecognitionsTotal = () => createSelector(selectRecognition, (state) => state.sentRecognitionsTotal);
export const getSentRecognitionsIsLoaded = () =>
  createSelector(selectRecognition, (state) => state.sentRecognitionsIsLoaded);
export const getSentRecognitionsFilter = () => createSelector(selectRecognition, (state) => state.sentFilter);

export const getRecognition = () => createSelector(selectRecognition, (state) => state.recognition);
export const getCoreValues = () => createSelector(selectRecognition, (state) => state.coreValues);
export const getUserCoreValuesSummary = () => createSelector(selectRecognition, (state) => state.userCoreValuesSummary);
