import { Process } from "@shared/models/Process";
import { Team } from "@shared/models/Team";
import { UserProcedure } from "@shared/models/UserProcedure";
import { Project } from "@shared/models/Project";

export enum ProcedureRecurrenceType {
  NONE = "None",
  MONTHLY = "Monthly",
  EVERY_THREE_MONTHS = "Every 3 months",
  EVERY_SIX_MONTHS = "Every 6 months",
  YEARLY = "Yearly",
}

export interface Procedure {
  readonly id: number;

  name: string;
  status: string;
  external_link: string | "";

  recurrence: ProcedureRecurrenceType;

  is_deleted: boolean;
  created_at: Date;
  updated_at: Date;
  created_by: string;
  updated_by: string;

  process: Process[];
  teams: Team[];
  accountable_team_id: number;
  project?: Project;
  accountable_team?: Team;
  user_procedures?: UserProcedure[];
}
