export enum AuthActionTypes {
  LOGIN = "@@AUTH/LOGIN",
  LOGIN_SUCCESS = "@@AUTH/LOGIN_SUCCESS",
  LOGIN_FAILURE = "@@AUTH/LOGIN_FAILURE",

  LOGOUT = "@@AUTH/LOGOUT",
  LOGOUT_SUCCESS = "@@AUTH/LOGOUT_SUCCESS",
  LOGOUT_FAILURE = "@@AUTH/LOGOUT_FAILURE",

  GOOGLE_LOGIN = "@@AUTH/GOOGLE_LOGIN",
  GOOGLE_LOGIN_SUCCESS = "@@AUTH/GOOGLE_LOGIN_SUCCESS",
  GOOGLE_LOGIN_FAILURE = "@@AUTH/GOOGLE_LOGIN_FAILURE",

  GOOGLE_REGISTER = "@@AUTH/GOOGLE_REGISTER",
  GOOGLE_REGISTER_SUCCESS = "@@AUTH/GOOGLE_REGISTER_SUCCESS",
  GOOGLE_REGISTER_FAILURE = "@@AUTH/GOOGLE_REGISTER_FAILURE",
}
