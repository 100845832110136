import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { actions as sharedActions } from "@shared/store";
import { AssignOnboardingToUserDto } from "@containers/User/interfaces/UserForm.interface";
import { User } from "@shared/models";
import { AssignOnboardingForm } from "@containers/User/components";
import { actions, selectors } from "@containers/User/store";

interface AssignOnboardingContainerProps {
  onClose: () => void;
  user: User;
}

const AssignOnboardingContainer = (props: AssignOnboardingContainerProps) => {
  const { onClose, user } = props;
  const dispatch = useDispatch();
  const onboardings = useSelector(selectors.getOnboardings());

  useEffect(() => {
    if (!onboardings.length) {
      dispatch(actions.getOnboardings.request());
    }
  }, [dispatch, onboardings.length]);

  const changeFormValues = useCallback(() => {
    dispatch(sharedActions.updateOverlay({ leaveConfirm: true }));
  }, [dispatch]);

  const handleSubmitForm = useCallback(
    (payload: AssignOnboardingToUserDto) => {
      dispatch(sharedActions.hideOverlay());
      dispatch(actions.assignOnboarding.request({ user_id: payload.user_id, onboarding_id: payload.onboarding_id }));
    },
    [dispatch],
  );

  return (
    <div className="assign-onboarding-container">
      <AssignOnboardingForm
        user={user}
        onboardings={onboardings}
        onChangeForm={changeFormValues}
        onSubmitForm={handleSubmitForm}
        onCloseForm={onClose}
      />
    </div>
  );
};

export default AssignOnboardingContainer;
