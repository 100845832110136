import React from "react";
import classnames from "classnames";
import { USER_ONBOARDING_STEP_STATUS } from "@shared/constants";

import "./index.scss";

export interface UserOnboardingStatusProps {
  className?: string;
  status: USER_ONBOARDING_STEP_STATUS;
  percent: number;
}

const UserOnboardingStatus: React.FC<UserOnboardingStatusProps> = ({ className, status, percent }) => {
  return (
    <div className={classnames("status-label", className, status.replace(" ", "-"))}>
      {status} <span> {status === USER_ONBOARDING_STEP_STATUS.IN_PROGRESS ? `\u00A0 - ${percent}%` : ""}</span>
    </div>
  );
};

export default UserOnboardingStatus;
