import React, { useRef, useState } from "react";
import { Icon, Textarea } from "@shared/components";
import { useAutosizeTextArea } from "@shared/hooks";

import "./index.scss";

export interface CommentCreateProps {
  submitComment: (description: string) => void;
  initialValue?: string;
}

const CommentCreate: React.FC<CommentCreateProps> = ({ submitComment, initialValue = "" }) => {
  const MAX_COUNT_COMMENT_INPUT = 500;

  const [commentInput, setCommentInput] = useState(initialValue);
  const textAreaRef = useRef<HTMLTextAreaElement>(null);
  useAutosizeTextArea(textAreaRef.current, commentInput);

  return (
    <div className="comments-textarea-wrapper">
      <Textarea
        className="comment-input"
        inputRef={textAreaRef}
        type="text"
        name="comment"
        onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
          e.target?.value?.length <= MAX_COUNT_COMMENT_INPUT && setCommentInput(e.target.value);
        }}
        value={commentInput}
        placeholder="Add a note"
      />
      {commentInput && (
        <>
          <Icon
            type="arrow-long"
            onClick={() => {
              submitComment(commentInput);
              setCommentInput("");
            }}
          />
          <div className="counter">
            {commentInput.length}/{MAX_COUNT_COMMENT_INPUT}
          </div>
        </>
      )}
    </div>
  );
};

export default CommentCreate;
