export enum RecognitionActionTypes {
  GET_RECEIVED_RECOGNITIONS = "@@RECOGNITION/GET_RECEIVED_RECOGNITIONS",
  GET_RECEIVED_RECOGNITIONS_SUCCESS = "@@RECOGNITION/GET_RECEIVED_RECOGNITIONS_SUCCESS",
  GET_RECEIVED_RECOGNITIONS_FAILURE = "@@RECOGNITION/GET_RECEIVED_RECOGNITIONS_FAILURE",

  GET_SENT_RECOGNITIONS = "@@RECOGNITION/GET_SENT_RECOGNITIONS",
  GET_SENT_RECOGNITIONS_SUCCESS = "@@RECOGNITION/GET_SENT_RECOGNITIONS_SUCCESS",
  GET_SENT_RECOGNITIONS_FAILURE = "@@RECOGNITION/GET_SENT_RECOGNITIONS_FAILURE",

  GET_RECOGNITION = "@@RECOGNITION/GET_RECOGNITION",
  GET_RECOGNITION_SUCCESS = "@@RECOGNITION/GET_RECOGNITION_SUCCESS",
  GET_RECOGNITION_FAILURE = "@@RECOGNITION/GET_RECOGNITION_FAILURE",

  CREATE_RECOGNITION = "@@RECOGNITION/CREATE_RECOGNITION",
  CREATE_RECOGNITION_SUCCESS = "@@RECOGNITION/CREATE_RECOGNITION_SUCCESS",
  CREATE_RECOGNITION_FAILURE = "@@RECOGNITION/CREATE_RECOGNITION_FAILURE",

  UPDATE_RECOGNITION = "@@RECOGNITION/UPDATE_RECOGNITION",
  UPDATE_RECOGNITION_SUCCESS = "@@RECOGNITION/UPDATE_RECOGNITION_SUCCESS",
  UPDATE_RECOGNITION_FAILURE = "@@RECOGNITION/UPDATE_RECOGNITION_FAILURE",

  DELETE_RECOGNITION = "@@RECOGNITION/DELETE_RECOGNITION",
  DELETE_RECOGNITION_SUCCESS = "@@RECOGNITION/DELETE_RECOGNITION_SUCCESS",
  DELETE_RECOGNITION_FAILURE = "@@RECOGNITION/DELETE_RECOGNITION_FAILURE",

  GET_CORE_VALUES = "@@RECOGNITION/GET_CORE_VALUES",
  GET_CORE_VALUES_SUCCESS = "@@RECOGNITION/GET_CORE_VALUES_SUCCESS",
  GET_CORE_VALUES_FAILURE = "@@RECOGNITION/GET_CORE_VALUES_FAILURE",

  GET_USER_CORE_VALUES_SUMMARY = "@@RECOGNITION/GET_USER_CORE_VALUES_SUMMARY",
  GET_USER_CORE_VALUES_SUMMARY_SUCCESS = "@@RECOGNITION/GET_USER_CORE_VALUES_SUMMARY_SUCCESS",
  GET_USER_CORE_VALUES_SUMMARY_FAILURE = "@@RECOGNITION/GET_USER_CORE_VALUES_SUMMARY_FAILURE",

  CLEAR_RECOGNITIONS = "@@RECOGNITION/CLEAR_RECOGNITIONS",
  CLEAR_CORE_VALUES = "@@RECOGNITION/CLEAR_CORE_VALUES",
  CLEAR_USER_CORE_VALUES_SUMMARY = "@@RECOGNITION/CLEAR_USER_CORE_VALUES_SUMMARY",

  SET_RECEIVED_FILTER = "@@RECOGNITION/SET_RECEIVED_FILTER",
  SET_SENT_FILTER = "@@RECOGNITION/SET_SENT_FILTER",
}
