import React, { useCallback } from "react";
import classnames from "classnames";

import "./index.scss";
import { TableProperty } from "../../interfaces";

export interface RowProps<T> {
  item: T;
  itemIndex: number;
  properties: TableProperty<T>[];
  onClick?: () => void;
  disabled?: boolean;
  className?: string;
}

function getItemCellContent<T>(item: T, prop: TableProperty<T>, index: number) {
  return prop.get ? prop.get(item, index) : item[prop.key];
}

function Row<T>(props: RowProps<T>) {
  const { properties, item, onClick, disabled, className, itemIndex } = props;

  const localCellClick = useCallback(
    (property: TableProperty<T>, event: React.MouseEvent) => {
      if (!property.cellClick) {
        return;
      }

      event.stopPropagation();
      event.preventDefault();

      property.cellClick(item);
    },
    [item],
  );

  return (
    <div
      className={classnames("table-row", { "table-row_disabled": disabled }, className)}
      onClick={disabled ? undefined : onClick}
    >
      {properties.map((prop, index) => (
        <React.Fragment key={index}>
          {prop.appearClickBlock ? (
            <div
              key={String(`${index}_${String(prop.key)}`)}
              className="table-row-cell-clickblock appear-click-block"
              onClick={(e) => localCellClick(prop, e)}
            >
              {prop.appearClickBlock(item)}
            </div>
          ) : (
            <div
              key={prop.title}
              className={classnames("table-row-cell", prop.cellClassName)}
              onClick={prop.cellWholeClick ? (e) => localCellClick(prop, e) : undefined}
            >
              <>
                {getItemCellContent<T>(item, prop, itemIndex)}
                {prop.cellClickBlock && (
                  <div className="table-row-cell-clickblock" onClick={(e) => localCellClick(prop, e)}>
                    {prop.cellClickBlock}
                  </div>
                )}
              </>
            </div>
          )}
        </React.Fragment>
      ))}
    </div>
  );
}

export default Row;
