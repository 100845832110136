import { AppState } from "@shared/interfaces";
import { createSelector } from "reselect";

const selectUser = (state: AppState) => state.user;

export const getUsers = () => createSelector(selectUser, (state) => state.users);
export const getSelectedUser = () => createSelector(selectUser, (state) => state.selectedUser);
export const getSelectedUserMeetings = () => createSelector(selectUser, (state) => state.selectedUserMeetings);
export const getSelectedUserMeetingsTotal = () =>
  createSelector(selectUser, (state) => state.selectedUserMeetingsTotal);
export const getSelectedUserSurveys = () => createSelector(selectUser, (state) => state.userSurveys);
export const getSelectedUserSurveysTotal = () => createSelector(selectUser, (state) => state.userSurveysTotal);
export const getUsersTotal = () => createSelector(selectUser, (state) => state.usersTotal);
export const getFilter = () => createSelector(selectUser, (state) => state.filter);
export const getMeetingsFilter = () => createSelector(selectUser, (state) => state.meetingFilter);
export const getSurveysFilter = () => createSelector(selectUser, (state) => state.userSurveysFilter);

export const getOnboardings = () => createSelector(selectUser, (state) => state.onboardings);
export const getCurrentContractorUserProcedures = () => createSelector(selectUser, (state) => state.userProcedures);
export const getCurrentContractorUserProceduresTotal = () =>
  createSelector(selectUser, (state) => state.userProceduresTotal);
export const getCurrentContractorUserProceduresFilter = () =>
  createSelector(selectUser, (state) => state.userProceduresFilter);
export const getProcessesWithProcedures = () => createSelector(selectUser, (state) => state.processesWithProcedures);
export const getSurveyList = () => createSelector(selectUser, (state) => state.surveyList);
export const getUserSurvey = () => createSelector(selectUser, (state) => state.userSurvey);
export const getUserProceduresIsLoaded = () => createSelector(selectUser, (state) => state.userProceduresIsLoaded);
