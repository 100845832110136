import React, { FC, useState } from "react";
import { Procedure, Process } from "@shared/models";
import { Icon } from "@shared/components";
import { PublishingStatusColumn } from "@shared/components/Table/components/PublishingStatusColumn";
import { calculateDatesDiff, getGbFormattedDate } from "@shared/utils";
import { CellWithIcon } from "@shared/components/Table/components";

import "./index.scss";

interface ProcessesListProps {
  processes: Process[];
  onRowClick: (procedure: Procedure) => void;
  isLoading: boolean;
}

const ProcessesList: FC<ProcessesListProps> = (props) => {
  const { processes, onRowClick, isLoading } = props;

  const [openedProcesses, setOpenedProcesses] = useState<number[]>([]);
  const switchOpenedProcess = (id: number) => {
    setOpenedProcesses((prevState) =>
      prevState.includes(id) ? [...prevState.filter((el) => el !== id)] : [...prevState, id],
    );
  };

  return (
    <div className="processes-list-wrapper">
      <div className={`table-wrapper ${isLoading ? "table-loading" : ""}`}>
        <div className="table-header">
          <div className="table-header-cell">Procedure Name</div>
          <div className="table-header-cell">Status</div>
          <div className="table-header-cell">Process name</div>
          <div className="table-header-cell">Accountable Team</div>
          <div className="table-header-cell">Support Teams</div>
          <div className="table-header-cell">Last Reviewed</div>
          <div className="table-header-cell">Spent Time</div>
        </div>
        <div className="table-content table-clickable">
          {processes.map((process) => (
            <div key={process.id}>
              <div className="parent-wrapper">
                <Icon
                  type="chevron-left"
                  className={openedProcesses.includes(process.id) ? "opened" : ""}
                  onClick={() => switchOpenedProcess(process.id)}
                />
                <div className="image-wrapper">{process.image && <img src={process.image} alt={process.name} />}</div>
                <div className="title-wrapper">
                  <div className="title">{process.name}</div>
                  <div className="counter">{process.procedures?.length} procedures</div>
                </div>
              </div>
              <div className={`table-row-wrapper ${openedProcesses.includes(process.id) ? "opened" : ""}`}>
                {process.procedures?.map((procedure) => (
                  <div
                    className="table-row"
                    key={`${process.id}-${procedure.id}`}
                    onClick={() => onRowClick(procedure)}
                  >
                    <div className="table-row-cell">
                      <CellWithIcon iconType="procedure-list" text={procedure.name} />
                    </div>
                    <div className="table-row-cell">
                      {procedure.user_procedures ? (
                        <PublishingStatusColumn status={procedure?.user_procedures[0].status} />
                      ) : (
                        "-"
                      )}
                    </div>
                    <div className="table-row-cell">{process.name}</div>
                    <div className="table-row-cell">{procedure.accountable_team?.name || "-"}</div>
                    <div className="table-row-cell">{procedure.teams?.map((r) => r.name).join(", ") || "-"}</div>
                    <div className="table-row-cell">
                      {procedure.user_procedures ? (
                        <span>
                          {getGbFormattedDate(
                            procedure.user_procedures[0].end_review
                              ? procedure.user_procedures[0].end_review
                              : procedure.user_procedures[0].last_review,
                          )}
                        </span>
                      ) : (
                        "-"
                      )}
                    </div>
                    <div className="table-row-cell">
                      {procedure.user_procedures ? (
                        <span>
                          {calculateDatesDiff(
                            procedure.user_procedures[0].start_review,
                            procedure.user_procedures[0].end_review,
                          )}
                        </span>
                      ) : (
                        "-"
                      )}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ProcessesList;
