import React, { useEffect, useMemo, useState } from "react";
import { Formik } from "formik";
import { Button, ErrorMessageField, SelectPickerInput } from "@shared/components";
import { Onboarding, User } from "@shared/models";
import { Option } from "@shared/interfaces";
import { AssignOnboardingToUserDto } from "@containers/User/interfaces/UserForm.interface";

import { getInitValues, validationSchema, prepareSubmitValue } from "./formHelpers";
import "./index.scss";

interface AssignOnboardingFormProps {
  user: User;
  onboardings: Onboarding[];
  onChangeForm: () => void;
  onCloseForm: () => void;
  onSubmitForm: (payload: AssignOnboardingToUserDto) => void;
}

const AssignOnboardingForm: React.FunctionComponent<AssignOnboardingFormProps> = (props) => {
  const { user, onboardings, onChangeForm, onCloseForm, onSubmitForm } = props;
  const [onboardingOptions, setOnboardingOptions] = useState<Option<number>[]>([]);
  const formValues = useMemo(() => getInitValues(), []);

  useEffect(() => {
    const onboardingOpt = onboardings.map((i) => {
      return {
        label: i.name,
        value: i.id,
      };
    });
    setOnboardingOptions(onboardingOpt);
  }, [onboardings]);

  return (
    <Formik
      validationSchema={validationSchema}
      enableReinitialize={true}
      validateOnBlur={false}
      onSubmit={(values, { setSubmitting }) => {
        onSubmitForm(prepareSubmitValue(values, user));
        setSubmitting(false);
      }}
      initialValues={formValues}
      validate={() => {
        onChangeForm();
      }}
    >
      {({ values, handleSubmit, setFieldValue, handleChange }) => (
        <div className="user-onboarding-form">
          <form onSubmit={handleSubmit}>
            <div className="form-content">
              <div className="input-wrapper">
                <div className="input">
                  <SelectPickerInput
                    searchable={false}
                    name="process"
                    cleanable={false}
                    data={onboardingOptions}
                    onChange={(value) => setFieldValue("onboarding_id", value)}
                    onClean={handleChange}
                    labelTitle="Onboarding Flow"
                    value={values.onboarding_id}
                    placeholder="Onboarding"
                  />
                </div>
                <ErrorMessageField name="onboarding_id" />
              </div>
            </div>
          </form>

          <div className="form-footer">
            <Button type="button" className="close-btn margin-right" onClick={() => onCloseForm()}>
              Cancel
            </Button>
            <Button type="submit" className="accent padding" onClick={() => handleSubmit()}>
              Assign Onboarding
            </Button>
          </div>
        </div>
      )}
    </Formik>
  );
};

export default AssignOnboardingForm;
