import React, { FC } from "react";
import classnames from "classnames";

import "./index.scss";

interface CoreValueCardProps {
  valueTitle: string;
  totalScore: number;
  coreValueId: number;
  onClickHandler: (coreValueId: number) => void;
  isSelected?: boolean;
  iconUrl: string;
}

const CoreValueCard: FC<CoreValueCardProps> = (props) => {
  const { valueTitle, totalScore, coreValueId, onClickHandler, isSelected, iconUrl } = props;
  return (
    <div
      className={classnames("core-value-card", isSelected ? "selected" : "")}
      onClick={() => onClickHandler(coreValueId)}
    >
      <div className="core-value-card-info">
        <div className="value-icon-section">
          <div className="icon" style={{ backgroundImage: `url("${iconUrl}")` }} />
        </div>
        <div className="value-title-section">{valueTitle}</div>
        <div className="value-score-section">
          {totalScore} points | {100 - totalScore > 0 ? 100 - totalScore : 0} to get reward
        </div>
      </div>
      <div className="core-value-progress-bar">
        <div className="core-value-progress-bar-inner" style={{ height: `${totalScore}%` }} />
      </div>
    </div>
  );
};

export default CoreValueCard;
