import { Project, Role, Team, User } from "@shared/models";
import { createAction, createAsyncAction } from "typesafe-actions";

import {
  Notification,
  ModalComponent,
  OverlayComponent,
  LoadingType,
  Filter,
  PaginatedResponse,
  UserFilter,
} from "../interfaces";
import { SharedActionTypes } from "./constants";

export const startLoading = createAction(SharedActionTypes.START_LOADING)();
export const stopLoading = createAction(SharedActionTypes.STOP_LOADING)();

export const addLoadingType = createAction(SharedActionTypes.ADD_LOADING_TYPE)<LoadingType>();
export const removeLoadingType = createAction(SharedActionTypes.REMOVE_LOADING_TYPE)<string>();

export const addLoadingSection = createAction(SharedActionTypes.ADD_LOADING_SECTION)<{
  loadingSection: string;
  requestName: string;
}>();
export const removeLoadingSection = createAction(SharedActionTypes.REMOVE_LOADING_SECTION)<{
  loadingSection: string;
  requestName: string;
}>();

export const showNotification = createAction(SharedActionTypes.SHOW_NOTIFICATION)<Notification>();

export const showModal = createAction(SharedActionTypes.SHOW_MODAL)<ModalComponent>();
export const hideModal = createAction(SharedActionTypes.HIDE_MODAL)();

export const showOverlay = createAction(SharedActionTypes.SHOW_OVERLAY)<OverlayComponent>();
export const updateOverlay = createAction(SharedActionTypes.UPDATE_OVERLAY)<Partial<OverlayComponent>>();
export const hideOverlay = createAction(SharedActionTypes.HIDE_OVERLAY)();

export const navigate = createAction(SharedActionTypes.NAVIGATE)<string>();

export const getUserDetails = createAsyncAction(
  SharedActionTypes.GET_USER_DETAIL,
  SharedActionTypes.GET_USER_DETAIL_SUCCESS,
  SharedActionTypes.GET_USER_DETAIL_FAILURE,
)<undefined, User | null, Error>();

export const getUsers = createAsyncAction(
  SharedActionTypes.GET_USERS,
  SharedActionTypes.GET_USERS_SUCCESS,
  SharedActionTypes.GET_USERS_FAILURE,
)<UserFilter, PaginatedResponse<User>, Error>();

export const getTeams = createAsyncAction(
  SharedActionTypes.GET_TEAMS,
  SharedActionTypes.GET_TEAMS_SUCCESS,
  SharedActionTypes.GET_TEAMS_FAILURE,
)<void, Team[], Error>();

export const getProjects = createAsyncAction(
  SharedActionTypes.GET_PROJECTS,
  SharedActionTypes.GET_PROJECTS_SUCCESS,
  SharedActionTypes.GET_PROJECTS_FAILURE,
)<Filter, PaginatedResponse<Project>, Error>();

export const getRoles = createAsyncAction(
  SharedActionTypes.GET_ROLES,
  SharedActionTypes.GET_ROLES_SUCCESS,
  SharedActionTypes.GET_ROLES_FAILURE,
)<void, Role[], Error>();
