import "./index.scss";

import { Button, ErrorMessageField, Input, SelectPickerInput, Textarea } from "@shared/components";
import { Meeting, User } from "@shared/models";
import { Formik } from "formik";
import React, { useMemo } from "react";
import { CreateUserMeetingDto } from "@containers/User/interfaces/UserForm.interface";

import { getInitValues, prepareSubmitValue, validationSchema, MEETING_STATUS_OPTIONS } from "./formHelpers";

const MAX_TEXTAREA_LENGTH = 200;

interface CreateEditMeetingFormProps {
  meeting: Meeting | null;
  currentUser: User | null;
  onChangeForm: () => void;
  onCloseForm: () => void;
  onDelete: () => void;
  onSubmitForm: (payload: Omit<CreateUserMeetingDto, "contractor_id">) => void;
}

const CreateEditMeetingForm: React.FunctionComponent<CreateEditMeetingFormProps> = (props) => {
  const { meeting, onChangeForm, onCloseForm, onDelete, onSubmitForm, currentUser } = props;
  const formValues = useMemo(() => getInitValues(meeting), [meeting]);
  const ownMeeting = useMemo(() => {
    return !meeting || currentUser?.id === meeting.manager?.id;
  }, [meeting, currentUser]);

  const isDisabledFields = meeting?.id ? true : false;

  return (
    <Formik
      validationSchema={validationSchema}
      enableReinitialize={true}
      validateOnBlur={false}
      onSubmit={(values, { setSubmitting }) => {
        onSubmitForm(prepareSubmitValue(values));
        setSubmitting(false);
      }}
      initialValues={formValues}
      validate={() => {
        onChangeForm();
      }}
    >
      {({ values, handleSubmit, handleChange, handleBlur, setFieldValue }) => (
        <div className="user-meeting-form">
          <form onSubmit={handleSubmit}>
            <div className="user-meeting-form-content">
              <div className="meeting-title-wrapper">
                <Input
                  className="meeting-title-input"
                  type="text"
                  labelTitle="Meeting"
                  name="title"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.title}
                  placeholder="Meeting"
                />
                <ErrorMessageField name="title" />
              </div>
              <div className="meeting-date-wrapper">
                <Input
                  className="meeting-date-input"
                  type="date"
                  labelTitle="Date"
                  name="date"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.date}
                  placeholder="Date"
                  disabled={isDisabledFields}
                />
                <ErrorMessageField name="date" />
              </div>
              {ownMeeting && (
                <div className="meeting-next-date-wrapper">
                  <label className="add-to-calendar-label">
                    <input
                      disabled={Boolean(Number(meeting?.add_to_google_calendar))}
                      name="google-calendar-checkbox"
                      type="checkbox"
                      className="google-calendar-checkbox"
                      value={"add"}
                      checked={Boolean(Number(values.add_to_google_calendar))}
                      onChange={(e) => {
                        if (e.target.checked) {
                          setFieldValue("add_to_google_calendar", "1");
                        } else {
                          setFieldValue("add_to_google_calendar", "0");
                        }
                      }}
                    />
                    <div className="google-calendar-label-text">Add to Calendar</div>
                  </label>
                </div>
              )}
              <div className="meeting-comment-wrapper">
                <div className="comment-textarea-length">
                  {values.comment.length}/{MAX_TEXTAREA_LENGTH}
                </div>
                <Textarea
                  className="meeting-comment-input"
                  maxLength={200}
                  labelTitle="Comment"
                  name="comment"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.comment}
                  placeholder="Comment"
                />
                <ErrorMessageField name="comment" />
              </div>
              <div className="procedure-process-input-wrapper">
                <div className="procedure-process-input">
                  <SelectPickerInput
                    searchable={false}
                    name="status"
                    cleanable={false}
                    data={MEETING_STATUS_OPTIONS}
                    onChange={(value) => setFieldValue("status", value)}
                    onClean={handleChange}
                    labelTitle="Status"
                    value={values.status}
                    placeholder="Status"
                  />
                </div>
                <ErrorMessageField name="process" />
              </div>
              {/* <div className="meeting-manager-select-input-wrapper">
                <div className="manager-select-input">
                  <AsyncSelectPickerInput
                    searchable={false}
                    labelTitle="Created By"
                    name="manager"
                    className="meeting-manager-input"
                    onChange={(v) => setFieldValue("manager", v)}
                    value={values.manager}
                    placeholder="Created By"
                    getData={handlers.manager.getData}
                    selectData={handlers.manager.selectData}
                    prepareOptionFunction={handlers.manager.prepareOptionFunction}
                    loadingAction={handlers.manager.loadingAction}
                  />
                </div>
                <ErrorMessageField name="manager" />
              </div> */}
            </div>
          </form>

          <div className="form-footer">
            <div className="form-footer-left">
              {meeting ? (
                <Button type="button" className="clear padding" onClick={onDelete}>
                  Delete
                </Button>
              ) : null}
            </div>
            <div className="form-footer-right">
              <Button type="button" className="close-btn margin-right" onClick={() => onCloseForm()}>
                Cancel
              </Button>
              <Button type="submit" className="accent padding" onClick={() => handleSubmit()}>
                {meeting ? "Save" : "Create Meeting"}
              </Button>
            </div>
          </div>
        </div>
      )}
    </Formik>
  );
};

export default CreateEditMeetingForm;
