import React, { useCallback } from "react";
import { useDispatch } from "react-redux";
import { actions as sharedActions } from "@shared/store";
import { actions } from "@containers/Goal/store";
import { Goal, User } from "@shared/models";
import { CreateGoalDto } from "@containers/Goal/interfaces";
import { GoalForm } from "@containers/Goal/components/GoalCreateEditContainer";

import "./index.scss";

interface GoalCreateEditContainerProps {
  onClose: () => void;
  goal: Goal | null;
  user: User;
}

const GoalCreateEditContainer = ({ onClose, goal, user }: GoalCreateEditContainerProps) => {
  const dispatch = useDispatch();

  const changeFormValues = useCallback(() => {
    dispatch(sharedActions.updateOverlay({ leaveConfirm: true }));
  }, [dispatch]);

  const handleSubmitForm = useCallback(
    (payload: CreateGoalDto) => {
      dispatch(sharedActions.hideOverlay());

      if (!goal) {
        return dispatch(actions.createGoal.request({ ...payload, user_id: user.id }));
      }

      dispatch(actions.updateGoal.request({ ...payload, id: Number(goal.id) }));
    },
    [dispatch, goal, user],
  );

  return (
    <div className="goal-create-edit-container">
      <div className="title-wrapper">
        <div className="title">{goal ? "Edit Goal" : "New Goal"}</div>
        <hr />
      </div>
      <GoalForm goal={goal} onChangeForm={changeFormValues} onSubmitForm={handleSubmitForm} onCloseForm={onClose} />
    </div>
  );
};

export default GoalCreateEditContainer;
