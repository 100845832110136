import { LOCATION_KEY } from "@shared/constants";
import { User } from "@shared/models";
import { Location, NavigateFunction } from "react-router";

export const copyObject = <T,>(obj: T) => JSON.parse(JSON.stringify(obj));

export const debounce = <F extends (...args: Parameters<F>) => ReturnType<F>>(func: F, waitFor = 500) => {
  let timeout: ReturnType<typeof setTimeout> | null = null;
  const debounced = (...args: Parameters<F>) => {
    if (timeout) {
      clearTimeout(timeout);
    }
    timeout = setTimeout(() => func(...args), waitFor);
  };

  return debounced as (...args: Parameters<F>) => ReturnType<F>;
};

export const getUniqueElements = <T,>(array: T[], key: keyof T): T[] => {
  return Array.from(new Map(array.map((item) => [item[key], item])).values());
};

export const getFullName = (user: User | null) => {
  return user ? [user.first_name, user.last_name].filter((el) => Boolean(el)).join(" ") : "";
};

// DD-Mon-YYYY
export const getGbFormattedDate = (date: Date | string | undefined | null) => {
  return date
    ? new Date(date).toLocaleDateString("en-GB", {
        day: "numeric",
        month: "long",
        year: "numeric",
      })
    : "-";
};

export const getGbFormattedDateTime = (date: Date | string | undefined | null) => {
  return date
    ? new Date(date).toLocaleDateString("en-GB", {
        day: "numeric",
        month: "long",
        year: "numeric",
        hour: "numeric",
        minute: "numeric",
        hour12: true,
      })
    : "-";
};

export const getRecurrenceDaysString = (days: number | undefined | null) => {
  return days ? (days === 180 ? "Every 6 months" : `Every ${days} days`) : "-";
};

export const calculateDatesDiff = (
  date1: Date | string | undefined | null,
  date2: Date | string | undefined | null,
) => {
  if (!date1 || !date2) {
    return "-";
  }
  const timeDiff = Math.abs(new Date(date1).getTime() - new Date(date2).getTime());
  const diffSeconds = Math.ceil(timeDiff / 1000);

  return diffSeconds >= 60 ? `${Math.round(timeDiff / (1000 * 60))} minutes` : `${diffSeconds} seconds`;
};

export function getCutString(str: string, maxLength = 103, dotted = true) {
  return str.length > maxLength
    ? dotted
      ? str.substring(0, maxLength - 3) + "..."
      : str.substring(0, maxLength)
    : str;
}

export function openInNewTab(url: string) {
  window.open(url);
}

export function downLoadFile(data: any, type: string, name: string) {
  const blob = new Blob([data], { type: type });
  const url = window.URL.createObjectURL(blob);

  const anchor = document.createElement("a");
  anchor.download = `${name}.csv`;
  anchor.href = url;
  anchor.click();
}

export function goBack(location: Location, navigate: NavigateFunction, defaultUrl: string) {
  if (location.key !== LOCATION_KEY.DEFAULT) {
    navigate(-1);
    return;
  }

  navigate(defaultUrl);
}
