import { createAsyncAction } from "typesafe-actions";
import { WithCallback, WithNavigate } from "@shared/interfaces";
import { User } from "@shared/models";

import { AuthActionTypes } from "./constants";
import { GoogleLoginShape, LoginShape, LoginSuccess } from "../interfaces";

export const login = createAsyncAction(
  AuthActionTypes.LOGIN,
  AuthActionTypes.LOGIN_SUCCESS,
  AuthActionTypes.LOGIN_FAILURE,
)<WithNavigate<LoginShape>, LoginSuccess, Error>();

export const logout = createAsyncAction(
  AuthActionTypes.LOGOUT,
  AuthActionTypes.LOGOUT_SUCCESS,
  AuthActionTypes.LOGOUT_FAILURE,
)<undefined, undefined, Error>();

export const googleLogin = createAsyncAction(
  AuthActionTypes.GOOGLE_LOGIN,
  AuthActionTypes.GOOGLE_LOGIN_SUCCESS,
  AuthActionTypes.GOOGLE_LOGIN_FAILURE,
)<WithCallback<GoogleLoginShape>, User, Error>();

export const googleRegister = createAsyncAction(
  AuthActionTypes.GOOGLE_REGISTER,
  AuthActionTypes.GOOGLE_REGISTER_SUCCESS,
  AuthActionTypes.GOOGLE_REGISTER_FAILURE,
)<WithCallback<GoogleLoginShape>, User, Error>();
