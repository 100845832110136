import { createAsyncAction, createAction } from "typesafe-actions";
import { Filter, PaginatedResponse } from "@shared/interfaces";
import { Project, WorkType } from "@shared/models";

import { ProjectActionTypes } from "./constants";
import { CreateProjectDto, CreateProjectSuccess, DeleteProjectSuccess, UpdateProjectDto } from "../interfaces";

export const getProjects = createAsyncAction(
  ProjectActionTypes.GET_PROJECTS,
  ProjectActionTypes.GET_PROJECTS_SUCCESS,
  ProjectActionTypes.GET_PROJECTS_FAILURE,
)<Filter, PaginatedResponse<Project>, Error>();

export const getWorkTypes = createAsyncAction(
  ProjectActionTypes.GET_WORK_TYPES,
  ProjectActionTypes.GET_WORK_TYPES_SUCCESS,
  ProjectActionTypes.GET_WORK_TYPES_FAILURE,
)<void, WorkType[], Error>();

export const createProject = createAsyncAction(
  ProjectActionTypes.CREATE_PROJECT,
  ProjectActionTypes.CREATE_PROJECT_SUCCESS,
  ProjectActionTypes.CREATE_PROJECT_FAILURE,
)<CreateProjectDto, CreateProjectSuccess, Error>();

export const updateProject = createAsyncAction(
  ProjectActionTypes.UPDATE_PROJECT,
  ProjectActionTypes.UPDATE_PROJECT_SUCCESS,
  ProjectActionTypes.UPDATE_PROJECT_FAILURE,
)<UpdateProjectDto, CreateProjectSuccess, Error>();

export const deleteProject = createAsyncAction(
  ProjectActionTypes.DELETE_PROJECT,
  ProjectActionTypes.DELETE_PROJECT_SUCCESS,
  ProjectActionTypes.DELETE_PROJECT_FAILURE,
)<number, DeleteProjectSuccess, Error>();

export const setFilter = createAction(ProjectActionTypes.SET_FILTER)<Filter | null>();
export const clearProjects = createAction(ProjectActionTypes.CLEAR_PROJECTS)();
