export const NamesOfParentRoutes = {
  AUTH: "/auth",
  PROCEDURES: "/procedures",
};

export const NameOfChildRoutes = {
  AUTH: {
    ROOT: "/auth",
    LOGIN: "/login",
  },
  PROJECTS: {
    ROOT: "/projects",
    LIST: "/",
  },
  USERS: {
    ROOT: "/users",
    LIST: "/",
    VIEW: "/:id",
  },
  PROCEDURES: {
    ROOT: "/procedures",
    PROCESSES: "/processes",
    PROCESS: "/processes/:process_id",
    LIST: "/",
    DETAILS: "/:id",
    ONBOARDING: "/onboarding",
    MANAGE: "/manage",
  },
  SURVEYS: {
    ROOT: "/surveys",
    LIST: "/",
    DETAILS: "/:id",
  },
  GOALS: {
    ROOT: "/goals",
    LIST: "/",
  },
  RECOGNITIONS: {
    ROOT: "/recognitions",
    LIST: "/",
  },
  ACCOUNTABILITY_CHART: {
    ROOT: "/accountability-chart",
    VIEW: "/",
  },
};
