import { RefObject, useEffect, useState } from "react";

const getDimensions = (myRef: RefObject<HTMLDivElement>) => ({
  width: myRef?.current?.offsetWidth || 0,
  height: myRef?.current?.offsetHeight || 0,
});

const useContainerDimensions = (myRef: RefObject<HTMLDivElement>) => {
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

  useEffect(() => {
    const handleResize = () => {
      setDimensions(getDimensions(myRef));
    };

    if (myRef.current) {
      setDimensions(getDimensions(myRef));
    }

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [myRef]);

  return dimensions;
};

export default useContainerDimensions;
