import { Filter } from "@shared/interfaces";
import { produce } from "immer";
import { ActionType, createReducer } from "typesafe-actions";
import { CONTRACTOR_FILTER_DROPDOWN_OPTIONS } from "@shared/constants";

import { ProcedureStateType } from "../interfaces";
import * as actions from "./actions";

type Action = ActionType<typeof actions>;

const defaultFilter: Filter = {
  page: 0,
  limit: 50,
  search: "",
};

export const initialState: ProcedureStateType = {
  procedures: [],
  procedure: null,
  processes: [],
  proceduresTotal: 0,
  filter: { ...defaultFilter },
  contractorProceduresFilter: CONTRACTOR_FILTER_DROPDOWN_OPTIONS.ALL,
  userOnboardingSteps: [],
  processesProceduresIsLoaded: false,
};

const reducer = createReducer<ProcedureStateType, Action>(initialState)
  .handleAction(actions.getProcedures.success, (state, action) =>
    produce(state, (nextState) => {
      const { rows, count, is_clear } = action.payload;
      nextState.procedures = !is_clear ? [...nextState.procedures, ...rows] : [...rows];
      nextState.proceduresTotal = count;
    }),
  )
  .handleAction(actions.getProcedure.success, (state, action) =>
    produce(state, (nextState) => {
      nextState.procedure = action.payload;
    }),
  )
  .handleAction(actions.createProcedure.success, (state, action) =>
    produce(state, (nextState) => {
      nextState.procedures = [action.payload.procedure, ...nextState.procedures];
      nextState.proceduresTotal = nextState.proceduresTotal + 1;
    }),
  )
  .handleAction(actions.updateProcedure.success, (state, action) =>
    produce(state, (nextState) => {
      const procedures = nextState.procedures;
      const foundProcedureIndex = procedures.findIndex((s) => action.payload.procedure.id === s.id);

      procedures[foundProcedureIndex] = action.payload.procedure;

      nextState.procedures = [...procedures];

      if (state.procedure && state.procedure.id === action.payload.procedure.id) {
        nextState.procedure = action.payload.procedure;
      }
    }),
  )
  .handleAction(actions.deleteProcedure.success, (state, action) =>
    produce(state, (nextState) => {
      const procedures = nextState.procedures.filter((p) => p.id !== action.payload.procedure_id);
      nextState.procedures = [...procedures];
      nextState.proceduresTotal = nextState.proceduresTotal - 1;
      if (state.procedure && state.procedure.id === action.payload.procedure_id) {
        nextState.procedure = null;
      }
    }),
  )
  .handleAction(actions.getProcesses.success, (state, action) =>
    produce(state, (nextState) => {
      nextState.processes = action.payload;
    }),
  )
  .handleAction(actions.getProcessesProcedures.success, (state, action) =>
    produce(state, (nextState) => {
      nextState.processes = action.payload;
      nextState.processesProceduresIsLoaded = true;
    }),
  )
  .handleAction(actions.clearProcessesProcedures, (state) =>
    produce(state, (nextState) => {
      nextState.processes = [];
      nextState.processesProceduresIsLoaded = false;
    }),
  )
  .handleAction(actions.setFilter, (state, action) =>
    produce(state, (nextState) => {
      nextState.filter = action.payload || { ...defaultFilter };
    }),
  )
  .handleAction(actions.clearProcedures, (state) =>
    produce(state, (nextState) => {
      nextState.procedures = [];
      nextState.proceduresTotal = 0;
    }),
  )
  .handleAction(actions.setContractorProceduresFilter, (state, action) =>
    produce(state, (nextState) => {
      nextState.contractorProceduresFilter = action.payload;
    }),
  )
  .handleAction(actions.getUserOnboardingSteps.success, (state, action) =>
    produce(state, (nextState) => {
      nextState.userOnboardingSteps = action.payload;
    }),
  );

export { reducer as ProcedureReducer };
