import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { actions as sharedActions, selectors as sharedSelectors } from "@shared/store";
import { User } from "@shared/models";
import UserEditForm from "@containers/User/components/UserEditContainer/EditUserForm/UserEditForm";
import { CreateUserDto } from "@containers/User/interfaces/UserForm.interface";
import { createUser, updateUser } from "@containers/User/store/actions";

import "./index.scss";

interface UserCreateEditContainerProps {
  onClose: () => void;
  user: User | null;
}

const UserEditContainer = (props: UserCreateEditContainerProps) => {
  const { onClose, user } = props;

  const dispatch = useDispatch();
  const teams = useSelector(sharedSelectors.getTeams());

  useEffect(() => {
    dispatch(sharedActions.getTeams.request());
  }, [dispatch]);

  const changeFormValues = useCallback(() => {
    dispatch(sharedActions.updateOverlay({ leaveConfirm: true }));
  }, [dispatch]);

  const handleSubmitForm = useCallback(
    (payload: CreateUserDto) => {
      dispatch(sharedActions.hideOverlay());
      if (!user) {
        return dispatch(createUser.request(payload));
      }
      dispatch(updateUser.request({ ...payload, id: Number(user.id) }));
    },
    [dispatch, user],
  );

  return (
    <div className="user-edit-container">
      <div className="title-wrapper">
        <div className="title">{user ? "Edit Teammate" : "Add Teammate"}</div>
        <hr />
      </div>
      <UserEditForm
        user={user}
        onChangeForm={changeFormValues}
        onSubmitForm={handleSubmitForm}
        onCloseForm={onClose}
        teams={teams}
      />
    </div>
  );
};

export default UserEditContainer;
