import React, { useMemo, useState, useEffect } from "react";
import "./index.scss";
import { TabBarNav } from "@shared/components/TabBar/TabBarNav";
import classNames from "classnames";
import { Button } from "@shared/components";
import { useSearchParams } from "react-router-dom";

export interface TabBar {
  className?: string;
  children: {
    props: {
      label: string;
      isAccess?: boolean;
      actionButtonTitle?: string;
      actionButtonOnClick?: () => void;
    };
  }[];
  onChangeActiveTabHandler?: (label: string, number: number) => void;
  isLoading?: boolean;
  activeTab?: number;
}

const TabBar: React.FC<TabBar> = (props) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { children, className, onChangeActiveTabHandler, isLoading } = props;
  const [activeTab, setActiveTab] = useState(0);

  useEffect(() => {
    setActiveTab(props.activeTab ? props.activeTab : 0);
  }, [props.activeTab]);

  const activeChildren = useMemo(
    () => children?.filter(({ props }) => (props.isAccess === undefined ? true : props.isAccess)),
    [children],
  );

  const labelsList = useMemo(() => activeChildren?.map(({ props }) => props.label), [activeChildren]);

  const onChangeActiveTab = (label: string) => {
    const activeTabNum = labelsList.indexOf(label);
    setActiveTab(activeTabNum);
    searchParams.set("activeTab", String(activeTabNum));
    setSearchParams(searchParams);
    if (onChangeActiveTabHandler) {
      onChangeActiveTabHandler(label, labelsList.indexOf(label));
    }
  };

  const renderTabs = () =>
    labelsList.map((label: string, key: number) => (
      <TabBarNav
        label={label}
        key={label}
        className={key === activeTab ? "active-tab" : ""}
        onChangeActiveTab={onChangeActiveTab}
      />
    ));

  const renderContent = () => {
    return activeChildren[activeTab] as React.ReactNode;
  };

  const renderActionButton = () => {
    const { props } = activeChildren[activeTab];
    return props.actionButtonTitle && props.actionButtonOnClick ? (
      <Button type="button" className="margin-right clear" onClick={() => props.actionButtonOnClick?.()}>
        {props.actionButtonTitle}
      </Button>
    ) : null;
  };

  const classes = classNames("tab-bar", className);

  return (
    <div className={classes}>
      <div className="tab-bar-nav">
        <div className="tab-bar-nav-left">{renderTabs()}</div>
        <div className="tab-bar-nav-right">{renderActionButton()}</div>
      </div>
      <div className="tab-container">{!isLoading ? renderContent() : null}</div>
    </div>
  );
};

export default TabBar;
