import { request } from "@shared/utils";
import { API, METHODS } from "@shared/constants";
import { SurveysFilter } from "@shared/interfaces/SurveysFilter.interface";

import { CreateSurveyDto, SurveyStatisticDetailFilter, UpdateSurveyDto } from "../interfaces";

export default {
  getSurveys: (payload: SurveysFilter) => request(METHODS.GET, API.SURVEY.GET_SURVEYS)({ params: payload }),
  getSurvey: (id: number) => {
    return request(METHODS.GET, API.SURVEY.GET_SURVEY(String(id)))();
  },
  createSurvey: (payload: CreateSurveyDto) => request(METHODS.POST, API.SURVEY.CREATE_SURVEY)(payload),
  updateSurvey: (payload: UpdateSurveyDto) => {
    const { id, ...rest } = payload;
    return request(METHODS.PUT, API.SURVEY.UPDATE_SURVEY(String(id)))(rest);
  },
  deleteSurvey: (id: number) => {
    return request(METHODS.DELETE, API.SURVEY.DELETE_SURVEY(String(id)))();
  },
  getSurveyStatistic: (id: number) => {
    return request(METHODS.GET, API.SURVEY.GET_SURVEY_STATISTIC(String(id)))();
  },
  getSurveyStatisticDetails: (payload: SurveyStatisticDetailFilter) => {
    const { id, ...rest } = payload;

    return request(METHODS.GET, API.SURVEY.GET_SURVEY_STATISTIC_DETAILS(String(id)))({ params: rest });
  },
  sendSurvey: (id: number) => {
    return request(METHODS.POST, API.SURVEY.SEND_SURVEY(String(id)))();
  },
};
