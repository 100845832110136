import { Option } from "@shared/interfaces";
import { ProcedureRecurrenceType } from "@shared/models";

export const procedureRecurrenceOptions: Option<string>[] = [
  { value: ProcedureRecurrenceType.NONE, label: ProcedureRecurrenceType.NONE },
  { value: ProcedureRecurrenceType.MONTHLY, label: ProcedureRecurrenceType.MONTHLY },
  { value: ProcedureRecurrenceType.EVERY_THREE_MONTHS, label: ProcedureRecurrenceType.EVERY_THREE_MONTHS },
  { value: ProcedureRecurrenceType.EVERY_SIX_MONTHS, label: ProcedureRecurrenceType.EVERY_SIX_MONTHS },
  { value: ProcedureRecurrenceType.YEARLY, label: ProcedureRecurrenceType.YEARLY },
];
