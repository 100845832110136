import { createAsyncAction, createAction } from "typesafe-actions";
import { BaseSuccessResponse, Filter, PaginatedResponse, UserProcedureFilter, WithCallback } from "@shared/interfaces";
import { Procedure, Process, UserOnboarding, UserOnboardingSteps } from "@shared/models";
import {
  CreateProcedureDto,
  CreateProcedureSuccess,
  DeleteProcedureSuccess,
  UpdateProcedureDto,
} from "@containers/Procedure/interfaces";
import { ProcessFilter } from "@shared/interfaces/ProcessFilter.interface";
import { CONTRACTOR_FILTER_DROPDOWN_OPTIONS } from "@shared/constants";

import { ProcedureActionTypes } from "./constants";

export const getProcedures = createAsyncAction(
  ProcedureActionTypes.GET_PROCEDURES,
  ProcedureActionTypes.GET_PROCEDURES_SUCCESS,
  ProcedureActionTypes.GET_PROCEDURES_FAILURE,
)<UserProcedureFilter, PaginatedResponse<Procedure>, Error>();

export const getProcedure = createAsyncAction(
  ProcedureActionTypes.GET_PROCEDURE,
  ProcedureActionTypes.GET_PROCEDURE_SUCCESS,
  ProcedureActionTypes.GET_PROCEDURE_FAILURE,
)<number, Procedure | null, Error>();

export const createProcedure = createAsyncAction(
  ProcedureActionTypes.CREATE_PROCEDURE,
  ProcedureActionTypes.CREATE_PROCEDURE_SUCCESS,
  ProcedureActionTypes.CREATE_PROCEDURE_FAILURE,
)<CreateProcedureDto, CreateProcedureSuccess, Error>();

export const updateProcedure = createAsyncAction(
  ProcedureActionTypes.UPDATE_PROCEDURE,
  ProcedureActionTypes.UPDATE_PROCEDURE_SUCCESS,
  ProcedureActionTypes.UPDATE_PROCEDURE_FAILURE,
)<UpdateProcedureDto, CreateProcedureSuccess, Error>();

export const deleteProcedure = createAsyncAction(
  ProcedureActionTypes.DELETE_PROCEDURE,
  ProcedureActionTypes.DELETE_PROCEDURE_SUCCESS,
  ProcedureActionTypes.DELETE_PROCEDURE_FAILURE,
)<number, DeleteProcedureSuccess, Error>();

export const getProcesses = createAsyncAction(
  ProcedureActionTypes.GET_PROCESSES,
  ProcedureActionTypes.GET_PROCESSES_SUCCESS,
  ProcedureActionTypes.GET_PROCESSES_FAILURE,
)<void, Process[], Error>();

export const getProcessesProcedures = createAsyncAction(
  ProcedureActionTypes.GET_PROCESSES_PROCEDURES,
  ProcedureActionTypes.GET_PROCESSES_PROCEDURES_SUCCESS,
  ProcedureActionTypes.GET_PROCESSES_PROCEDURES_FAILURE,
)<ProcessFilter | void, Process[], Error>();

export const getUserOnboardingSteps = createAsyncAction(
  ProcedureActionTypes.GET_USER_ONBOARDING_STEPS,
  ProcedureActionTypes.GET_USER_ONBOARDING_STEPS_SUCCESS,
  ProcedureActionTypes.GET_USER_ONBOARDING_STEPS_FAILURE,
)<void, UserOnboardingSteps[], Error>();

export const updateUserOnboardingStatus = createAsyncAction(
  ProcedureActionTypes.UPDATE_USER_ONBOARDING_STATUS,
  ProcedureActionTypes.UPDATE_USER_ONBOARDING_STATUS_SUCCESS,
  ProcedureActionTypes.UPDATE_USER_ONBOARDING_STATUS_FAILURE,
)<WithCallback<Partial<UserOnboarding>>, BaseSuccessResponse, Error>();

export const setFilter = createAction(ProcedureActionTypes.SET_FILTER)<Filter | null>();
export const clearProcedures = createAction(ProcedureActionTypes.CLEAR_PROCEDURES)();
export const clearProcessesProcedures = createAction(ProcedureActionTypes.CLEAR_PROCESS_PROCEDURES)();
export const setContractorProceduresFilter = createAction(
  ProcedureActionTypes.SET_CONTRACTOR_PROCEDURES_FILTER,
)<CONTRACTOR_FILTER_DROPDOWN_OPTIONS>();
