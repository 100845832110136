import * as Yup from "yup";
import { FORM_ERROR_MESSAGES } from "@shared/constants";
import { AssignSurveyDto, AssignSurveyFormShape } from "@containers/User/interfaces/UserForm.interface";

export const validationSchema = Yup.object().shape({
  surveys: Yup.array().of(Yup.number()).min(1, FORM_ERROR_MESSAGES.REQUIRED),
});

export const getInitValues = (): AssignSurveyFormShape => ({
  surveys: [],
});

export const prepareSubmitValue = (values: AssignSurveyFormShape): Omit<AssignSurveyDto, "user_id"> => {
  return {
    survey_ids: values.surveys,
  };
};
