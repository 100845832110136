import React, { RefObject, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { getGbFormattedDateTime } from "@shared/utils";
import { Comment } from "@shared/models";
import { CommentCreate } from "@containers/Goal/components";
import { useOutsideClick } from "@shared/hooks";
import { UpdateCommentDto } from "@containers/Goal/interfaces";
import { DropDownMenu } from "@shared/components";
import { IconType } from "@shared/components/Icon/Icon";
import { selectors as sharedSelectors } from "@shared/store";

import "./index.scss";

export interface CommentListProps {
  comments: Comment[];
  editComment: (payload: UpdateCommentDto) => void;
  deleteComment: (comment: Comment) => void;
}

const CommentList: React.FC<CommentListProps> = ({ comments, editComment, deleteComment }) => {
  const [selectedId, setSelectedId] = useState<null | number>(null);

  const authenticatedUser = useSelector(sharedSelectors.getUserDetails());

  const editFieldRef = useRef<HTMLDivElement | null>(null);
  const { isOutside } = useOutsideClick(
    editFieldRef as RefObject<{ contains: (target: EventTarget | null) => boolean }>,
  );

  useEffect(() => {
    isOutside && selectedId && setSelectedId(null);
  }, [isOutside, selectedId]);

  return (
    <div className="comments-wrapper">
      {comments.map((comment) => (
        <div className="comment" key={comment.id}>
          <div className="info-wrapper">
            {/*<div className="avatar">*/}
            {/*  <img*/}
            {/*    src="https://s3-alpha-sig.figma.com/img/738e/6e77/a92971e6075b85d18be0de93205d90cb?Expires=1676246400&Signature=I0RxnsJlXpRQZ8FftaL-Mdh64QCFMYDfGABTJ8HAdvqrinROfBqBunTQeNeQUVz7NGvON4Kfue3ZJogFQS39mya2HptXcGyV-EJEI-0fs0T9in3A30FCsBf90~ycz13eAUtUlYccFCzWp9S8EvHjnfTA53d72ixsL5J0V6yS6c0x9kpxoA96m2PCpgpDawnRLcRk2fcUrGlbev6ouZCZOoyDXvUSUwIz960Y0rhtBXzKWA0cwzfCX2B5AVEVOeLgzNIQGcrrzdwHlzhPjxlQV789g6cyNxP3w8IKhweRN0Ffbp63uiQ~gjk9nwBKmrJE0PqFyp49DicORFKRvtc-7Q__&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4"*/}
            {/*    alt="avatar"*/}
            {/*  />*/}
            {/*</div>*/}
            <div className="info">
              <div className="name">
                {comment.user ? `${comment.user.first_name} ${comment.user.last_name}` : "System Notification"}
              </div>
              <div className="date">{getGbFormattedDateTime(comment.created_at)}</div>
            </div>
            {authenticatedUser && authenticatedUser.id === comment.user?.id && (
              <div className="menu-wrapper">
                <DropDownMenu
                  items={[
                    {
                      label: "Edit",
                      handler: () => setSelectedId(comment.id),
                      icon: "edit-blue" as IconType,
                      className: "dropdown-item-blue",
                    },
                    {
                      label: "Delete",
                      handler: () => deleteComment(comment),
                      icon: "delete-red" as IconType,
                      className: "dropdown-item-red",
                    },
                  ]}
                  align={{ left: 170 }}
                />
              </div>
            )}
          </div>
          {selectedId && selectedId === comment.id ? (
            <div ref={editFieldRef} className="edit-comment-wrapper">
              <CommentCreate
                submitComment={(description) => {
                  editComment({ description, id: comment.id });
                  setSelectedId(null);
                }}
                initialValue={comment.description}
              />
            </div>
          ) : (
            <div className="description">{comment.description}</div>
          )}
        </div>
      ))}
    </div>
  );
};

export default CommentList;
