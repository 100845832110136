import React, { FC, useCallback, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { NoMatchesFound, TabBar } from "@shared/components";
import { TabBarItem } from "@shared/components/TabBar/TabBarItem";
import { Recognition } from "@shared/models";
import { RecognitionListItem } from "@containers/Recognition/components/RecognitionListContainer/RecognitionListItem";
import { useDispatch, useSelector } from "react-redux";
import { actions as RecognitionActions, selectors as RecognitionSelectors } from "@containers/Recognition/store";
import { useDebouncedFunction } from "@shared/hooks";
import { RecognitionFilter } from "@shared/interfaces/RecognitionFilter.interface";

import RecognitionsEmptyScreen from "./RecognitionsEmptyScreen/RecognitionsEmptyScreen";

import "./index.scss";

interface RecognitionListProps {
  receivedRecognitions: Recognition[];
  receivedRecognitionsTotal: number;
  sentRecognitions: Recognition[];
  sentRecognitionsTotal: number;
  onListItemActionBtnClick: (recognition: Recognition, showSentTo?: boolean) => void;
  activeTab?: number;
  setActiveTab: (activeTab: number) => void;
  sentRecognitionsFilter: RecognitionFilter;
  receivedRecognitionsFilter: RecognitionFilter;
}

const RecognitionList: FC<RecognitionListProps> = (props) => {
  const {
    receivedRecognitions,
    receivedRecognitionsTotal,
    sentRecognitions,
    sentRecognitionsTotal,
    onListItemActionBtnClick,
    activeTab,
    setActiveTab,
    sentRecognitionsFilter,
    receivedRecognitionsFilter,
  } = props;

  const dispatch = useDispatch();

  const [searchParams, setSearchParams] = useSearchParams();

  const recognitionsFilter = useSelector(RecognitionSelectors.getReceivedRecognitionsFilter());

  useEffect(() => {
    const tab = searchParams.get("activeTab");
    if (tab !== null) {
      const tabNum = Number(tab);
      if (tab && !Number.isNaN(tabNum) && tabNum >= 0 && tabNum <= 1) {
        setActiveTab(tabNum);
      }
    }
  }, [searchParams, setActiveTab, setSearchParams]);

  useEffect(() => {
    if (recognitionsFilter?.search && !recognitionsFilter?.core_value_id) {
      if (activeTab === 0 && !receivedRecognitions.length && sentRecognitions.length) {
        setActiveTab(1);
        searchParams.set("activeTab", "1");
        setSearchParams(searchParams);
      }
      if (activeTab === 1 && !sentRecognitions.length && receivedRecognitions.length) {
        setActiveTab(0);
        searchParams.set("activeTab", "0");
        setSearchParams(searchParams);
      }
    }
  }, [
    activeTab,
    receivedRecognitions.length,
    recognitionsFilter?.core_value_id,
    recognitionsFilter?.search,
    searchParams,
    sentRecognitions,
    setActiveTab,
    setSearchParams,
  ]);

  const loadNextPageReceived = useCallback(() => {
    if (receivedRecognitionsFilter.limit * (receivedRecognitionsFilter.page + 1) < receivedRecognitionsTotal) {
      dispatch(
        RecognitionActions.setReceivedFilter({
          ...receivedRecognitionsFilter,
          page: receivedRecognitionsFilter.page + 1,
        }),
      );
    }
  }, [dispatch, receivedRecognitionsFilter, receivedRecognitionsTotal]);

  const loadNextPageSent = useCallback(() => {
    if (sentRecognitionsFilter.limit * (sentRecognitionsFilter.page + 1) < sentRecognitionsTotal) {
      dispatch(
        RecognitionActions.setSentFilter({
          ...sentRecognitionsFilter,
          page: sentRecognitionsFilter.page + 1,
        }),
      );
    }
  }, [dispatch, sentRecognitionsFilter, sentRecognitionsTotal]);

  const debouncedFinishScrollReceived = useDebouncedFunction(loadNextPageReceived);
  const debouncedFinishScrollSent = useDebouncedFunction(loadNextPageSent);

  const onScrollReceived = useCallback(
    (event: React.SyntheticEvent<Element>) => {
      const maxScroll = event.currentTarget.scrollHeight - event.currentTarget.clientHeight;
      if (event.currentTarget.scrollTop >= maxScroll - 1 && debouncedFinishScrollReceived) {
        debouncedFinishScrollReceived();
      }
    },
    [debouncedFinishScrollReceived],
  );

  const onScrollSent = useCallback(
    (event: React.SyntheticEvent<Element>) => {
      const maxScroll = event.currentTarget.scrollHeight - event.currentTarget.clientHeight;
      if (event.currentTarget.scrollTop >= maxScroll - 1 && debouncedFinishScrollSent) {
        debouncedFinishScrollSent();
      }
    },
    [debouncedFinishScrollSent],
  );

  return (
    <div>
      <TabBar activeTab={activeTab}>
        <TabBarItem label={`Received (${receivedRecognitionsTotal})`}>
          {receivedRecognitions.length ? (
            <div className="scrollable-wrapper" onScroll={onScrollReceived}>
              {receivedRecognitions.map((recognition) => (
                <RecognitionListItem
                  key={recognition.id}
                  recognition={recognition}
                  onListItemActionBtnClick={onListItemActionBtnClick}
                  showSentFrom={!recognition.is_anonymous}
                />
              ))}
            </div>
          ) : recognitionsFilter?.search || recognitionsFilter?.core_value_id ? (
            <div className="no-matches-found-wrapper">
              <NoMatchesFound />
            </div>
          ) : (
            <RecognitionsEmptyScreen />
          )}
        </TabBarItem>
        <TabBarItem label={`Sent (${sentRecognitionsTotal})`}>
          {sentRecognitions.length ? (
            <div className="scrollable-wrapper" onScroll={onScrollSent}>
              {sentRecognitions.map((recognition) => (
                <RecognitionListItem
                  key={recognition.id}
                  recognition={recognition}
                  showSentTo={true}
                  onListItemActionBtnClick={() => onListItemActionBtnClick(recognition, true)}
                  showSentFrom={false}
                />
              ))}
            </div>
          ) : recognitionsFilter?.search ? (
            <div className="no-matches-found-wrapper">
              <NoMatchesFound />
            </div>
          ) : (
            <RecognitionsEmptyScreen />
          )}
        </TabBarItem>
      </TabBar>
    </div>
  );
};

export default RecognitionList;
