import { ProjectFilter } from "@shared/interfaces";
import { produce } from "immer";
import { ActionType, createReducer } from "typesafe-actions";

import { ProjectStateType } from "../interfaces";
import * as actions from "./actions";

type Action = ActionType<typeof actions>;

const defaultFilter: ProjectFilter = {
  page: 0,
  limit: 50,
  search: "",
};

export const initialState: ProjectStateType = {
  projects: [],
  projectsTotal: 0,
  filter: { ...defaultFilter },
  workTypes: [],
};

const reducer = createReducer<ProjectStateType, Action>(initialState)
  .handleAction(actions.getProjects.success, (state, action) =>
    produce(state, (nextState) => {
      const { rows, count, is_clear } = action.payload;
      nextState.projects = !is_clear ? [...nextState.projects, ...rows] : [...rows];
      nextState.projectsTotal = count;
    }),
  )
  .handleAction(actions.createProject.success, (state, action) =>
    produce(state, (nextState) => {
      nextState.projects = [action.payload.project, ...nextState.projects];
      nextState.projectsTotal = nextState.projectsTotal + 1;
    }),
  )
  .handleAction(actions.updateProject.success, (state, action) =>
    produce(state, (nextState) => {
      const projects = nextState.projects;
      const foundProjectIndex = projects.findIndex((s) => action.payload.project.id === s.id);

      projects[foundProjectIndex] = action.payload.project;

      nextState.projects = [...projects];
    }),
  )
  .handleAction(actions.deleteProject.success, (state, action) =>
    produce(state, (nextState) => {
      const projects = nextState.projects.filter((p) => p.id !== action.payload.project_id);
      nextState.projects = [...projects];
      nextState.projectsTotal = nextState.projectsTotal - 1;
    }),
  )
  .handleAction(actions.getWorkTypes.success, (state, action) =>
    produce(state, (nextState) => {
      nextState.workTypes = action.payload;
    }),
  )

  .handleAction(actions.setFilter, (state, action) =>
    produce(state, (nextState) => {
      nextState.filter = action.payload || { ...defaultFilter };
    }),
  )
  .handleAction(actions.clearProjects, (state) =>
    produce(state, (nextState) => {
      nextState.projects = [];
      nextState.projectsTotal = 0;
    }),
  );

export { reducer as ProjectReducer };
