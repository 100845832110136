import React from "react";
import type { FC, ReactNode } from "react";
import { Navigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { selectors as authSelectors } from "@containers/Auth/store";
import { selectors as sharedSelectors, actions as sharedActions } from "@shared/store";
import { getDefaultUrl, tokenHandler } from "@shared/utils";

interface GuestGuardProps {
  children: ReactNode;
}

const GuestGuard: FC<GuestGuardProps> = ({ children }) => {
  const dispatch = useDispatch();
  const isAuthenticated = useSelector(authSelectors.authentificated());
  const authenticatedUser = useSelector(sharedSelectors.getUserDetails());

  if (isAuthenticated && authenticatedUser) {
    return (
      <Navigate
        to={getDefaultUrl(authenticatedUser, () => {
          tokenHandler.removeAll();
          dispatch(sharedActions.getUserDetails.success(null));
        })}
      />
    );
  }

  return <>{children}</>;
};

export default GuestGuard;
