import React from "react";
import classnames from "classnames";

export type IconType =
  | "sort"
  | "export"
  | "sort-asc"
  | "sort-dsc"
  | "clear"
  | "find"
  | "white-clear"
  | "eye"
  | "eye-close"
  | "arrow-left"
  | "warn"
  | "logout"
  | "apply"
  | "delete"
  | "delete-red"
  | "loupe"
  | "btn-close"
  | "btn-open"
  | "google"
  | "back"
  | "user"
  | "chevron-left"
  | "arrow-long"
  | "survey-list"
  | "goals"
  | "goals-empty"
  | "procedures-empty"
  | "edit"
  | "edit-blue"
  | "progress-success"
  | "danger"
  | "close"
  | "not-found"
  | "procedure-list"
  | "team-filter"
  | "project-filter"
  | "core-value-without-score"
  | "core-value-score-20"
  | "core-value-score-15"
  | "core-value-score-10"
  | "core-value-score-5"
  | "arrow-forward"
  | "recognitions-empty"
  | "filter"
  | "arrow-down"
  | "finish-onboarding";

export interface IconProps {
  type: IconType;
  className?: string;
  onClick?: (e: React.MouseEvent) => void;
}

export default function Icon(props: IconProps) {
  const { type, className, onClick } = props;
  return <div onClick={onClick} className={classnames("icon", className, type)} />;
}
