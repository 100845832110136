import React from "react";
import { Icon } from "@shared/components";
import { IconType } from "@shared/components/Icon/Icon";

import "./index.scss";

interface NoMatchesFoundWithIconProps {
  title?: string;
  subtitle?: string;
  iconType?: IconType;
}

const NoMatchesFoundWithIcon = (props: NoMatchesFoundWithIconProps) => {
  const { title = "No matches found", subtitle, iconType } = props;

  return (
    <div className="empty-wrapper">
      {iconType && <Icon type={iconType} />}
      <div className="title">{title}</div>
      {subtitle && <div className="subtitle">{subtitle}</div>}
    </div>
  );
};

export default NoMatchesFoundWithIcon;
