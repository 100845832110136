import React, { ReactNode } from "react";
import "./index.scss";

interface DetailGroupProps {
  name: string;
  label?: { name: string; id: number }[] | string | number;
  component?: ReactNode;
  componentClassName?: string;
}

const DetailGroup: React.FunctionComponent<DetailGroupProps> = (props) => {
  const { name, label, component, componentClassName } = props;

  return (
    <div className="detail-group">
      <div className="detail-item-label">{name}</div>
      {Array.isArray(label) ? (
        label.map((l) => (
          <div className="detail-item-name" key={l.id || l.name}>
            {l.name}
          </div>
        ))
      ) : (
        <div className={componentClassName}>
          <div className="detail-item-name">{label}</div>
          {component}
        </div>
      )}
    </div>
  );
};

export default DetailGroup;
