import React from "react";

import "./index.scss";

const BlockScreen: React.FC = () => (
  <div className="block-screen">
    <div className="block-screen-logo-wrapper">
      <div className="block-screen-logo" />
    </div>
    <div className="block-screen-content">
      <div className="block-screen-title">Please use your computer</div>
      <div className="block-screen-description">
        Our core company portal is not available on mobile just yet. For now, please use your laptop or desktop computer
        with a larger monitor.
      </div>
    </div>
  </div>
);

export default BlockScreen;
