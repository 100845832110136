import React from "react";
import type { FC, ReactNode } from "react";
import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { isUserContractor } from "@shared/utils";
import { selectors as sharedSelectors } from "@shared/store";

import { NameOfChildRoutes, USER_ONBOARDING_STATUS } from "../constants";

interface OnboardingGuardProps {
  children?: ReactNode;
}

const OnboardingGuard: FC<OnboardingGuardProps> = (props) => {
  const { children } = props;
  const authenticatedUser = useSelector(sharedSelectors.getUserDetails());
  const isCurrentUserContractor = isUserContractor(authenticatedUser ? authenticatedUser : undefined);
  if (
    authenticatedUser &&
    isCurrentUserContractor &&
    authenticatedUser?.user_onboarding &&
    authenticatedUser?.user_onboarding.status !== USER_ONBOARDING_STATUS.COMPLETED
  ) {
    return <Navigate to={`${NameOfChildRoutes.PROCEDURES.ROOT}${NameOfChildRoutes.PROCEDURES.ONBOARDING}`} />;
  }

  return <>{authenticatedUser ? children : null}</>;
};

export default OnboardingGuard;
