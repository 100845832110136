import React from "react";

import { Button } from "../Common";

import "./index.scss";

interface ModalConfirmProps {
  heading: string;
  content: string;
  onClose: () => void;
  onSuccess: () => void;
  cancelBtnText?: string;
  successBtnText?: string;
}

const ModalConfirm = (props: ModalConfirmProps) => {
  return (
    <div className="modal-content-wrapper">
      <div className="modal-heading">{props.heading}</div>
      <div className="modal-content">{props.content}</div>
      <div className="modal-actions">
        <Button appearance="subtle" className="subtle auto-width" onClick={props.onClose}>
          {props.cancelBtnText}
        </Button>
        <Button type="submit" className="accent padding margin-left" onClick={props.onSuccess}>
          {props.successBtnText}
        </Button>
      </div>
    </div>
  );
};
export default ModalConfirm;
