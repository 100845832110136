import { User } from "../models";

export default {
  get: () => {
    try {
      return localStorage.getItem("token");
    } catch (err) {
      return false;
    }
  },
  set: (token: string) => {
    if (token) localStorage.setItem("token", token);
  },
  setUser: (user: User) => {
    if (user) localStorage.setItem("user", JSON.stringify(user));
  },
  getUser: () => {
    const user = localStorage.getItem("user");

    return user ? JSON.parse(user) : null;
  },
  remove: () => {
    localStorage.removeItem("token");
  },
  removeAll: () => {
    localStorage.clear();
  },
};
