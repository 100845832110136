import React from "react";
import { Icon } from "@shared/components";
import { IconType } from "@shared/components/Icon/Icon";

import "./index.scss";

interface CellWithIconProps {
  iconType: IconType;
  text: string;
}

const CellWithIcon: React.FC<CellWithIconProps> = (props) => {
  const { iconType, text } = props;
  return (
    <div className="cell-with-icon">
      <div>
        <Icon className="cell-icon" type={iconType} />
      </div>
      {text}
    </div>
  );
};

export default CellWithIcon;
