import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDebounce, useLoader } from "@shared/hooks";
import { ActionTypes, Option } from "@shared/interfaces";
import { useDispatch, useSelector } from "react-redux";
import { SelectPicker } from "rsuite";
import { getUniqueElements } from "@shared/utils";
import { AnyAction } from "redux";

import { Label } from "../Label";

import "./index.scss";

interface AsyncSelectPickerInputProps<T> {
  labelTitle?: string;
  labelClassName?: string;
  value: Option | null;
  name: string;
  placeholder: string;
  className: string;
  disabled?: boolean;
  searchable?: boolean;
  getData: (search: string) => AnyAction;
  selectData: any;
  prepareOptionFunction: (item: T) => Option;
  onChange: (value: Option | null) => void;
  onSort?: (isGroup: boolean) => (a: any, b: any) => number;
  disabledOptionsValues?: string[];
  loadingAction: ActionTypes;
}

const AsyncSelectPickerInput = <T,>(props: AsyncSelectPickerInputProps<T>) => {
  const {
    labelTitle,
    labelClassName,
    getData,
    selectData,
    prepareOptionFunction,
    name,
    onChange,
    placeholder,
    className,
    loadingAction,
    disabled,
    searchable = true,
    onSort,
    disabledOptionsValues,
  } = props;

  const dispatch = useDispatch();

  const [inputValue, setInputValue] = useState<string>("");
  const debounceValue = useDebounce<string>(inputValue, 500);
  const [value, setValue] = useState<Option | null>(props.value);

  const items: Array<T> = useSelector(selectData());

  const options: Option[] = useMemo(() => {
    let filteredOptions: Option[];

    if (!value) {
      filteredOptions = items.map((i) => prepareOptionFunction(i));
    } else {
      filteredOptions = getUniqueElements([value, ...items.map((i) => prepareOptionFunction(i))], "value");
    }
    if (disabledOptionsValues?.length) {
      filteredOptions = filteredOptions.filter((option) => !disabledOptionsValues?.includes(option.value));
    }

    return filteredOptions;
  }, [value, items, prepareOptionFunction, disabledOptionsValues]);

  const preparedValue: string = useMemo(() => {
    return props.value?.value ?? "";
  }, [props.value]);

  const { isLoading } = useLoader({
    name,
    actionTypes: useMemo(() => [loadingAction], [loadingAction]),
  });

  useEffect(() => {
    dispatch(getData(debounceValue));
  }, [dispatch, getData, debounceValue]);

  const handleChange = useCallback(
    (value: string) => {
      const selectedValue = options.find((option) => option.value === value) ?? null;
      onChange(selectedValue);
      setValue(selectedValue);
    },
    [options, onChange],
  );

  const onCloseMenu = useCallback(() => {
    if (debounceValue) {
      setInputValue("");
    }
  }, [debounceValue]);

  const renderMenu = useCallback(
    (menu: React.ReactNode) => {
      if (isLoading) {
        return <p style={{ padding: "6px 12px 12px", color: "#999" }}>Loading...</p>;
      }

      return menu;
    },
    [isLoading],
  );

  return (
    <div className="async-select-picker-input">
      {labelTitle ? <Label title={labelTitle} className={labelClassName} /> : null}
      <SelectPicker
        searchable={searchable}
        disabled={disabled}
        searchBy={() => true}
        data={options}
        value={preparedValue}
        onSearch={setInputValue}
        name={name}
        onChange={(value) => handleChange(value as string)}
        placeholder={placeholder}
        className={className}
        onClose={onCloseMenu}
        renderMenu={renderMenu}
        placement="auto"
        sort={onSort}
      />
    </div>
  );
};

export default AsyncSelectPickerInput;
