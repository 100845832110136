import * as Yup from "yup";
import { FORM_ERROR_MESSAGES } from "@shared/constants";
import { Goal } from "@shared/models";
import { CreateGoalDto, GoalFormShape } from "@containers/Goal/interfaces";

export const validationSchema: Yup.Schema<GoalFormShape> = Yup.object().shape({
  name: Yup.string().max(150, `Name ${FORM_ERROR_MESSAGES.LONG}`).required(FORM_ERROR_MESSAGES.REQUIRED),
  due_date: Yup.string().required(FORM_ERROR_MESSAGES.REQUIRED),
  description: Yup.string().max(250, `Description ${FORM_ERROR_MESSAGES.LONG}`).required(FORM_ERROR_MESSAGES.REQUIRED),
  progress: Yup.number().min(0).max(100).required(FORM_ERROR_MESSAGES.REQUIRED),
});

export const getInitValues = (goal: Goal | null): GoalFormShape => ({
  name: goal?.name || "",
  due_date: goal?.due_date ? new Date(goal.due_date).toISOString().substring(0, 10) : "",
  description: goal?.description || "",
  progress: goal?.progress || 0,
});

export const prepareSubmitValue = (value: GoalFormShape): CreateGoalDto => ({
  name: value.name,
  due_date: new Date(String(value.due_date)),
  description: value.description,
  progress: value.progress,
});
