import { useEffect } from "react";

const useResize = (
  resizerElementRef: React.RefObject<HTMLDivElement>,
  resizebleElementRef: React.RefObject<HTMLDivElement>,
) => {
  useEffect(() => {
    let elLeft = 0;
    let elTop = 0;

    function mouseMove(e: MouseEvent) {
      if (!resizebleElementRef.current) return;

      const width = e.clientX - elLeft;
      const height = e.clientY - elTop;

      if (width >= 500) {
        resizebleElementRef.current.style.width = `${width}px`;
      }

      if (height >= 200) {
        resizebleElementRef.current.style.height = `${height}px`;
      }
    }

    function mouseUp() {
      document.removeEventListener("mousemove", mouseMove);
    }

    function mouseDown(e: MouseEvent) {
      if (!resizebleElementRef.current || e.target !== resizerElementRef.current) return;

      const { top, left } = resizebleElementRef.current.getBoundingClientRect();

      elLeft = left;
      elTop = top;

      document.addEventListener("mouseup", mouseUp);
      document.addEventListener("mousemove", mouseMove);
    }

    document.addEventListener("mousedown", mouseDown);

    return () => {
      document.removeEventListener("mousedown", mouseDown);
      document.removeEventListener("mouseup", mouseUp);
      document.removeEventListener("mousemove", mouseMove);
    };
  }, [resizerElementRef, resizebleElementRef]);
};

export default useResize;
