import { ActionWithPayload, AppState, Filter, PaginatedResponse } from "@shared/interfaces";
import { Project, Role, Team, User } from "@shared/models";
import { actions } from "@shared/store";
import { tokenHandler } from "@shared/utils";
import { AnyAction } from "redux";
import { call, put, select, takeEvery, takeLatest } from "redux-saga/effects";

import api from "../api";
import { getUsers, startLoading, stopLoading } from "./actions";

function* getUsersSaga({ payload }: ActionWithPayload<Filter>) {
  try {
    yield put(startLoading());
    const response: PaginatedResponse<User> = yield call(api.getUsers, payload);
    yield put(
      getUsers.success({
        ...response,
        is_clear: !payload.page,
      }),
    );
    yield put(stopLoading());
  } catch (error) {
    yield put(getUsers.failure(error as Error));
    yield put(stopLoading());
  }
}

function* getUserDetailsSaga() {
  try {
    yield put(actions.startLoading());
    const response: { user: User } = yield call(api.getUserDetails);
    yield tokenHandler.setUser(response.user);

    yield put(actions.getUserDetails.success(response.user));
    yield put(actions.stopLoading());
  } catch (error) {
    yield put(actions.getUserDetails.failure(error as Error));
    yield put(actions.stopLoading());
  }
}

function* watchLoaders(payload: AnyAction) {
  const state: AppState = yield select();
  const { loadingTypes } = state.shared;

  const startedSection = loadingTypes.find(({ startActions }) => startActions.includes(payload.type));
  const stoppedSection = loadingTypes.find(({ stopActions }) => stopActions[payload.type]);

  if (startedSection) {
    yield put(actions.addLoadingSection({ loadingSection: startedSection.name, requestName: payload.type }));
  }
  if (stoppedSection) {
    yield put(
      actions.removeLoadingSection({
        loadingSection: stoppedSection.name,
        requestName: stoppedSection.stopActions[payload.type],
      }),
    );
  }
}

function* getTeamsSaga() {
  try {
    yield put(startLoading());
    const response: Team[] = yield call(api.getTeams);
    yield put(stopLoading());
    yield put(actions.getTeams.success(response));
  } catch (error) {
    yield put(actions.getTeams.failure(error as Error));
    yield put(stopLoading());
  }
}
function* getProjectsSaga({ payload }: ActionWithPayload<Filter>) {
  try {
    yield put(startLoading());
    const response: PaginatedResponse<Project> = yield call(api.getProjects, payload);
    yield put(
      actions.getProjects.success({
        ...response,
        is_clear: !payload.page,
      }),
    );
    yield put(stopLoading());
  } catch (error) {
    yield put(actions.getProjects.failure(error as Error));
    yield put(stopLoading());
  }
}
function* getRolesSaga() {
  try {
    yield put(startLoading());
    const response: { roles: Role[] } = yield call(api.getRoles);
    yield put(stopLoading());
    yield put(actions.getRoles.success(response.roles));
  } catch (error) {
    yield put(actions.getRoles.failure(error as Error));
    yield put(stopLoading());
  }
}

function* sharedSagas() {
  yield takeLatest(actions.getUserDetails.request, getUserDetailsSaga);
  yield takeLatest(actions.getUsers.request, getUsersSaga);
  yield takeLatest(actions.getTeams.request, getTeamsSaga);
  yield takeLatest(actions.getProjects.request, getProjectsSaga);
  yield takeLatest(actions.getRoles.request, getRolesSaga);

  yield takeEvery("*", watchLoaders);
}

export default sharedSagas;
