import { Project, WorkType } from "@shared/models";
import { call, put, takeLatest } from "redux-saga/effects";
import { PaginatedResponse, ActionWithPayload, Filter } from "@shared/interfaces";
import { startLoading, stopLoading } from "@shared/store/actions";

import { createProject, deleteProject, getProjects, getWorkTypes, updateProject } from "./actions";
import { CreateProjectDto, CreateProjectSuccess, DeleteProjectSuccess, UpdateProjectDto } from "../interfaces";
import api from "../api";

function* getProjectsSaga({ payload }: ActionWithPayload<Filter>) {
  try {
    yield put(startLoading());
    const response: PaginatedResponse<Project> = yield call(api.getProjects, payload);
    yield put(stopLoading());
    yield put(
      getProjects.success({
        ...response,
        is_clear: !payload.page,
      }),
    );
  } catch (error) {
    yield put(getProjects.failure(error as Error));
    yield put(stopLoading());
  }
}

function* getWorkTypesSaga() {
  try {
    yield put(startLoading());
    const response: WorkType[] = yield call(api.getWorkTypes);
    yield put(stopLoading());
    yield put(getWorkTypes.success(response));
  } catch (error) {
    yield put(getProjects.failure(error as Error));
    yield put(stopLoading());
  }
}

function* createProjectSaga({ payload }: ActionWithPayload<CreateProjectDto>) {
  try {
    yield put(startLoading());
    const response: CreateProjectSuccess = yield call(api.createProject, payload);
    yield put(stopLoading());
    yield put(createProject.success(response));
  } catch (error) {
    yield put(createProject.failure(error as Error));
    yield put(stopLoading());
  }
}

function* updateProjectSaga({ payload }: ActionWithPayload<UpdateProjectDto>) {
  try {
    yield put(startLoading());
    const response: CreateProjectSuccess = yield call(api.updateProject, payload);
    yield put(stopLoading());
    yield put(updateProject.success(response));
  } catch (error) {
    yield put(updateProject.failure(error as Error));
    yield put(stopLoading());
  }
}

function* deleteProjectSaga({ payload }: ActionWithPayload<number>) {
  try {
    yield put(startLoading());
    const response: DeleteProjectSuccess = yield call(api.deleteProject, payload);
    yield put(stopLoading());
    yield put(deleteProject.success(response));
  } catch (error) {
    yield put(deleteProject.failure(error as Error));
    yield put(stopLoading());
  }
}

function* projectSagas() {
  yield takeLatest(getProjects.request, getProjectsSaga);
  yield takeLatest(createProject.request, createProjectSaga);
  yield takeLatest(updateProject.request, updateProjectSaga);
  yield takeLatest(deleteProject.request, deleteProjectSaga);
  yield takeLatest(getWorkTypes.request, getWorkTypesSaga);
}

export default projectSagas;
