import { call, put, takeLatest } from "redux-saga/effects";
import { PaginatedResponse, ActionWithPayload, GoalsFilter } from "@shared/interfaces";
import { startLoading, stopLoading } from "@shared/store/actions";
import { Comment, Goal } from "@shared/models";
import {
  createGoal,
  createComment,
  deleteGoal,
  deleteComment,
  getComments,
  getGoals,
  updateGoal,
  updateComment,
  updateGoalProgress,
} from "@containers/Goal/store/actions";
import {
  CreateCommentDto,
  CreateGoalDto,
  CreateUpdateCommentSuccess,
  DeleteCommentSuccess,
  DeleteGoalSuccess,
  UpdateCommentDto,
  UpdateGoalDto,
  UpdateGoalProgressDto,
  UpdateGoalSuccess,
} from "@containers/Goal/interfaces";

import api from "../api";

function* getGoalsSaga({ payload }: ActionWithPayload<GoalsFilter>) {
  try {
    yield put(startLoading());
    const response: PaginatedResponse<Goal> = yield call(api.getGoals, payload);
    yield put(stopLoading());
    yield put(getGoals.success(response));
  } catch (error) {
    yield put(getGoals.failure(error as Error));
    yield put(stopLoading());
  }
}

function* updateGoalProgressSaga({ payload }: ActionWithPayload<UpdateGoalProgressDto>) {
  try {
    yield put(startLoading());
    const response: UpdateGoalSuccess = yield call(api.updateGoalProgress, payload);
    yield put(stopLoading());
    yield put(updateGoalProgress.success(response));
  } catch (error) {
    yield put(updateGoalProgress.failure(error as Error));
    yield put(stopLoading());
  }
}

function* createGoalSaga({ payload }: ActionWithPayload<CreateGoalDto>) {
  try {
    yield put(startLoading());
    const response: UpdateGoalSuccess = yield call(api.createGoal, payload);
    yield put(stopLoading());
    yield put(createGoal.success(response));
  } catch (error) {
    yield put(createGoal.failure(error as Error));
    yield put(stopLoading());
  }
}

function* updateGoalSaga({ payload }: ActionWithPayload<UpdateGoalDto>) {
  try {
    yield put(startLoading());
    const response: UpdateGoalSuccess = yield call(api.updateGoal, payload);
    yield put(stopLoading());
    yield put(updateGoal.success(response));
  } catch (error) {
    yield put(updateGoal.failure(error as Error));
    yield put(stopLoading());
  }
}

function* deleteGoalSaga({ payload }: ActionWithPayload<number>) {
  try {
    yield put(startLoading());
    const response: DeleteGoalSuccess = yield call(api.deleteGoal, payload);
    yield put(stopLoading());
    yield put(deleteGoal.success(response));
  } catch (error) {
    yield put(deleteGoal.failure(error as Error));
    yield put(stopLoading());
  }
}

function* createGoalCommentSaga({ payload }: ActionWithPayload<CreateCommentDto>) {
  try {
    yield put(startLoading());
    const response: CreateUpdateCommentSuccess = yield call(api.createComment, payload);
    yield put(stopLoading());
    yield put(createComment.success(response));
  } catch (error) {
    yield put(createComment.failure(error as Error));
    yield put(stopLoading());
  }
}

function* getGoalCommentsSaga({ payload }: ActionWithPayload<Partial<Comment>>) {
  try {
    yield put(startLoading());
    const response: Comment[] = yield call(api.getComments, payload);
    yield put(stopLoading());
    yield put(getComments.success(response));
  } catch (error) {
    yield put(getComments.failure(error as Error));
    yield put(stopLoading());
  }
}

function* updateGoalCommentSaga({ payload }: ActionWithPayload<UpdateCommentDto>) {
  try {
    yield put(startLoading());
    const response: CreateUpdateCommentSuccess = yield call(api.updateComment, payload);
    yield put(stopLoading());
    yield put(updateComment.success(response));
  } catch (error) {
    yield put(updateComment.failure(error as Error));
    yield put(stopLoading());
  }
}

function* deleteGoalCommentSaga({ payload }: ActionWithPayload<number>) {
  try {
    yield put(startLoading());
    const response: DeleteCommentSuccess = yield call(api.deleteComment, payload);
    yield put(stopLoading());
    yield put(deleteComment.success(response));
  } catch (error) {
    yield put(deleteComment.failure(error as Error));
    yield put(stopLoading());
  }
}

function* surveySagas() {
  yield takeLatest(getGoals.request, getGoalsSaga);
  yield takeLatest(updateGoalProgress.request, updateGoalProgressSaga);
  yield takeLatest(createGoal.request, createGoalSaga);
  yield takeLatest(updateGoal.request, updateGoalSaga);
  yield takeLatest(deleteGoal.request, deleteGoalSaga);
  yield takeLatest(createComment.request, createGoalCommentSaga);
  yield takeLatest(getComments.request, getGoalCommentsSaga);
  yield takeLatest(updateComment.request, updateGoalCommentSaga);
  yield takeLatest(deleteComment.request, deleteGoalCommentSaga);
}

export default surveySagas;
