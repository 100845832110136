import { createAsyncAction, createAction } from "typesafe-actions";
import { CoreValue, Recognition, UserCoreValuesSummary } from "@shared/models";
import { PaginatedResponse, WithCallback } from "@shared/interfaces";
import { RecognitionFilter } from "@shared/interfaces/RecognitionFilter.interface";
import {
  CreateRecognitionDto,
  CreateRecognitionSuccess,
  UpdateRecognitionDto,
} from "@containers/Recognition/interfaces";

import { RecognitionActionTypes } from "./constants";

export const getReceivedRecognitions = createAsyncAction(
  RecognitionActionTypes.GET_RECEIVED_RECOGNITIONS,
  RecognitionActionTypes.GET_RECEIVED_RECOGNITIONS_SUCCESS,
  RecognitionActionTypes.GET_RECEIVED_RECOGNITIONS_FAILURE,
)<RecognitionFilter, PaginatedResponse<Recognition>, Error>();

export const getSentRecognitions = createAsyncAction(
  RecognitionActionTypes.GET_SENT_RECOGNITIONS,
  RecognitionActionTypes.GET_SENT_RECOGNITIONS_SUCCESS,
  RecognitionActionTypes.GET_SENT_RECOGNITIONS_FAILURE,
)<RecognitionFilter, PaginatedResponse<Recognition>, Error>();

export const getRecognition = createAsyncAction(
  RecognitionActionTypes.GET_RECOGNITION,
  RecognitionActionTypes.GET_RECOGNITION_SUCCESS,
  RecognitionActionTypes.GET_RECOGNITION_FAILURE,
)<number, Recognition | null, Error>();

export const createRecognition = createAsyncAction(
  RecognitionActionTypes.CREATE_RECOGNITION,
  RecognitionActionTypes.CREATE_RECOGNITION_SUCCESS,
  RecognitionActionTypes.CREATE_RECOGNITION_FAILURE,
)<WithCallback<CreateRecognitionDto>, CreateRecognitionSuccess, Error>();

export const updateRecognition = createAsyncAction(
  RecognitionActionTypes.UPDATE_RECOGNITION,
  RecognitionActionTypes.UPDATE_RECOGNITION_SUCCESS,
  RecognitionActionTypes.UPDATE_RECOGNITION_FAILURE,
)<UpdateRecognitionDto, CreateRecognitionSuccess, Error>();

export const getCoreValues = createAsyncAction(
  RecognitionActionTypes.GET_CORE_VALUES,
  RecognitionActionTypes.GET_CORE_VALUES_SUCCESS,
  RecognitionActionTypes.GET_CORE_VALUES_FAILURE,
)<undefined, CoreValue[], Error>();

export const getUserCoreValuesSummary = createAsyncAction(
  RecognitionActionTypes.GET_USER_CORE_VALUES_SUMMARY,
  RecognitionActionTypes.GET_USER_CORE_VALUES_SUMMARY_SUCCESS,
  RecognitionActionTypes.GET_USER_CORE_VALUES_SUMMARY_FAILURE,
)<number, UserCoreValuesSummary[], Error>();

export const setReceivedFilter = createAction(RecognitionActionTypes.SET_RECEIVED_FILTER)<RecognitionFilter | null>();
export const setSentFilter = createAction(RecognitionActionTypes.SET_SENT_FILTER)<RecognitionFilter | null>();

export const clearRecognitions = createAction(RecognitionActionTypes.CLEAR_RECOGNITIONS)();
export const clearCoreValues = createAction(RecognitionActionTypes.CLEAR_CORE_VALUES)();
export const clearUserCoreValuesSummary = createAction(RecognitionActionTypes.CLEAR_USER_CORE_VALUES_SUMMARY)();
