import { Procedure, Process, UserOnboarding, UserOnboardingSteps } from "@shared/models";
import { call, put, takeLatest } from "redux-saga/effects";
import { PaginatedResponse, ActionWithPayload, Filter, BaseSuccessResponse, WithCallback } from "@shared/interfaces";
import { startLoading, stopLoading } from "@shared/store/actions";
import {
  createProcedure,
  deleteProcedure,
  getProcedure,
  getProcedures,
  getProcesses,
  getProcessesProcedures,
  getUserOnboardingSteps,
  updateProcedure,
  updateUserOnboardingStatus,
} from "@containers/Procedure/store/actions";
import {
  CreateProcedureDto,
  CreateProcedureSuccess,
  DeleteProcedureSuccess,
  UpdateProcedureDto,
} from "@containers/Procedure/interfaces";
import { ProcessFilter } from "@shared/interfaces/ProcessFilter.interface";

import api from "../api";

function* getProceduresSaga({ payload }: ActionWithPayload<Filter>) {
  try {
    yield put(startLoading());
    const response: PaginatedResponse<Procedure> = yield call(api.getProcedures, payload);
    yield put(stopLoading());
    yield put(
      getProcedures.success({
        ...response,
        is_clear: !payload.page,
      }),
    );
  } catch (error) {
    yield put(getProcedures.failure(error as Error));
    yield put(stopLoading());
  }
}

function* getProcedureSaga({ payload }: ActionWithPayload<number>) {
  try {
    yield put(startLoading());
    const response: Procedure = yield call(api.getProcedure, payload);
    yield put(stopLoading());
    yield put(getProcedure.success(response));
  } catch (error) {
    yield put(getProcedure.failure(error as Error));
    yield put(stopLoading());
  }
}

function* getProcessesSaga() {
  try {
    yield put(startLoading());
    const response: Process[] = yield call(api.getProcesses);
    yield put(stopLoading());
    yield put(getProcesses.success(response));
  } catch (error) {
    yield put(getProcesses.failure(error as Error));
    yield put(stopLoading());
  }
}

function* getProcessesProceduresSaga({ payload }: ActionWithPayload<ProcessFilter>) {
  try {
    yield put(startLoading());
    const response: Process[] = yield call(api.getProcessesProcedures, payload);
    yield put(stopLoading());
    yield put(getProcessesProcedures.success(response));
  } catch (error) {
    yield put(getProcessesProcedures.failure(error as Error));
    yield put(stopLoading());
  }
}

function* createProcedureSaga({ payload }: ActionWithPayload<CreateProcedureDto>) {
  try {
    yield put(startLoading());
    const response: CreateProcedureSuccess = yield call(api.createProcedure, payload);
    yield put(stopLoading());
    yield put(createProcedure.success(response));
  } catch (error) {
    yield put(createProcedure.failure(error as Error));
    yield put(stopLoading());
  }
}

function* updateProcedureSaga({ payload }: ActionWithPayload<UpdateProcedureDto>) {
  try {
    yield put(startLoading());
    const response: CreateProcedureSuccess = yield call(api.updateProcedure, payload);
    yield put(stopLoading());
    yield put(updateProcedure.success(response));
  } catch (error) {
    yield put(updateProcedure.failure(error as Error));
    yield put(stopLoading());
  }
}

function* deleteProcedureSaga({ payload }: ActionWithPayload<number>) {
  try {
    yield put(startLoading());
    const response: DeleteProcedureSuccess = yield call(api.deleteProcedure, payload);
    yield put(stopLoading());
    yield put(deleteProcedure.success(response));
  } catch (error) {
    yield put(deleteProcedure.failure(error as Error));
    yield put(stopLoading());
  }
}

function* getUserOnboardingStepsSaga() {
  try {
    yield put(startLoading());
    const response: UserOnboardingSteps[] = yield call(api.getUserOnboardingSteps);
    yield put(stopLoading());
    yield put(getUserOnboardingSteps.success(response));
  } catch (error) {
    yield put(getUserOnboardingSteps.failure(error as Error));
    yield put(stopLoading());
  }
}

function* updateUserOnboardingStatusSaga({ payload }: ActionWithPayload<WithCallback<Partial<UserOnboarding>>>) {
  try {
    const { cb, ...rest } = payload;
    yield put(startLoading());
    const response: BaseSuccessResponse = yield call(api.updateUserOnboardingStatus, rest);
    yield put(stopLoading());
    yield put(updateUserOnboardingStatus.success(response));
    cb?.();
  } catch (error) {
    yield put(updateUserOnboardingStatus.failure(error as Error));
    yield put(stopLoading());
  }
}

function* procedureSagas() {
  yield takeLatest(getProcedures.request, getProceduresSaga);
  yield takeLatest(getProcedure.request, getProcedureSaga);
  yield takeLatest(getProcesses.request, getProcessesSaga);
  yield takeLatest(getProcessesProcedures.request, getProcessesProceduresSaga);
  yield takeLatest(createProcedure.request, createProcedureSaga);
  yield takeLatest(updateProcedure.request, updateProcedureSaga);
  yield takeLatest(deleteProcedure.request, deleteProcedureSaga);
  yield takeLatest(getUserOnboardingSteps.request, getUserOnboardingStepsSaga);
  yield takeLatest(updateUserOnboardingStatus.request, updateUserOnboardingStatusSaga);
}

export default procedureSagas;
