export enum USER_STATUS {
  ACTIVE = "Active",
  INACTIVE = "Inactive",
}

export enum TYPE_EMPLOYMENT {
  FULL_TIME_JOB = "Full-Time Job",
  PART_TIME_JOB = "Part-Time Job",
}

export enum SURVEY_STATUS {
  ACTIVE = "Active",
  INACTIVE = "Inactive",
}

export enum USER_SURVEY_STATUS {
  NEW = "New",
  IN_PROGRESS = "In progress",
  COMPLETED = "Completed",
}

export enum USER_ONBOARDING_STATUS {
  NEW = "New",
  IN_PROGRESS = "In progress",
  COMPLETED = "Completed",
}

export enum USER_ONBOARDING_STEP_STATUS {
  NEW = "New",
  IN_PROGRESS = "In progress",
  COMPLETED = "Completed",
}

export enum USER_PROCEDURE_STATUS {
  NEW = "New",
  IN_PROGRESS = "In progress",
  REVIEWED = "Reviewed",
}

export enum GOALS_STATUS {
  IN_PROGRESS = "In progress",
  COMPLETED = "Completed",
  CLOSED = "Closed",
}

export enum PROJECT_STATUS {
  ACTIVE = "Active",
  COMPLETED = "Completed",
}

export enum RECOGNITION_STATUS {
  APPROVED = "Approved",
  DECLINED = "Declined",
  REVIEW = "Review",
}
