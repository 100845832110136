import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { FieldArray, FieldArrayRenderProps, Formik, FormikProps } from "formik";
import { Button, ErrorMessageField, InputSearch, SelectPickerInput } from "@shared/components";
import { Process, UserProcedure } from "@shared/models";
import { Option } from "@shared/interfaces";
import { AssignProcedureDto, AssignProcedureFormShape } from "@containers/User/interfaces/UserForm.interface";

import { getInitValues, prepareSubmitValue, validationSchema } from "./formHelpers";

import "./index.scss";

interface AssignProcedureFormProps {
  processesWithProcedures: Process[];
  assignedProcedures: UserProcedure[];
  onChangeForm: () => void;
  onCloseForm: () => void;
  onSubmitForm: (payload: Omit<AssignProcedureDto, "user_id">) => void;
}

const AssignProcedureForm: React.FunctionComponent<AssignProcedureFormProps> = (props) => {
  const { onChangeForm, onCloseForm, onSubmitForm, processesWithProcedures, assignedProcedures } = props;
  const [procedureOptions, setProcedureOptions] = useState<Option<number>[]>([]);
  const [searchValue, setSearchValue] = useState<string>("");
  const [selectedProcess, setSelectedProcess] = useState<number | null>(null);
  const formValues = useMemo(() => getInitValues(processesWithProcedures), [processesWithProcedures]);
  const formikRef = useRef<FormikProps<AssignProcedureFormShape>>(null);

  const processOptions = useMemo(() => {
    if (!processesWithProcedures.length) return [];

    const processOpt = processesWithProcedures.map((i) => {
      return {
        label: i.name,
        value: i.id,
      };
    });

    return [...processOpt, { label: "All", value: 0 }];
  }, [processesWithProcedures]);

  useEffect(() => {
    if (!(selectedProcess || selectedProcess === 0)) {
      setProcedureOptions([]);
      return;
    }

    const filteredProcesses = selectedProcess
      ? processesWithProcedures.filter((process) => process.id === selectedProcess)
      : [...processesWithProcedures];

    const procedureOptions = filteredProcesses.reduce((acc, process) => {
      const options: Option<number>[] = (process?.procedures || []).map((i) => ({
        label: i.name,
        value: i.id,
      }));
      return [...acc, ...options];
    }, [] as Option<number>[]);

    const filteredProcedureOptions = searchValue
      ? procedureOptions.filter((procedure) => procedure.label.toLowerCase().includes(searchValue.toLowerCase()))
      : procedureOptions;

    setProcedureOptions(filteredProcedureOptions);
  }, [searchValue, selectedProcess, processesWithProcedures]);

  useEffect(() => {
    if (processesWithProcedures.length && processesWithProcedures[0].procedures?.length) {
      const procedureOpt = processesWithProcedures[0].procedures.map((i) => {
        return {
          label: i.name,
          value: i.id,
        };
      });

      setProcedureOptions(procedureOpt);
    }
  }, [processesWithProcedures]);

  const checkEmptyProceduresList = useMemo(
    () =>
      !procedureOptions.length ||
      !procedureOptions.filter(
        (procedure) =>
          !assignedProcedures.find((assignedProcedure) => assignedProcedure.procedure.id === procedure.value),
      ).length,
    [procedureOptions, assignedProcedures],
  );

  const handleSelectProcess = useCallback((value: number) => {
    setSelectedProcess(value);
    formikRef.current?.setFieldValue("process", value);
  }, []);

  const handleSelectProcedure = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>, arrayHelpers: FieldArrayRenderProps, value: number) => {
      if (e.target.checked) {
        arrayHelpers.push(value);
      } else {
        const idx = formikRef.current?.values.procedures.indexOf(value);
        idx && arrayHelpers.remove(idx);
      }
    },
    [],
  );

  return (
    <Formik
      validationSchema={validationSchema}
      innerRef={formikRef}
      enableReinitialize={true}
      validateOnBlur={false}
      onSubmit={(values, { setSubmitting }) => {
        onSubmitForm(prepareSubmitValue(values));
        setSubmitting(false);
      }}
      initialValues={formValues}
      validate={() => {
        onChangeForm();
      }}
    >
      {({ values, handleSubmit, handleChange }) => (
        <div className="assign-procedure-form">
          <form onSubmit={handleSubmit}>
            <div className="assign-procedure-form-content">
              <div className="procedure-process-input-wrapper">
                <div className="procedure-process-input procedure-search-wrapper">
                  <InputSearch name="search" onChangeSearch={setSearchValue} labelTitle="Process" />
                </div>
                <div className="procedure-process-input select-wrapper">
                  <SelectPickerInput
                    searchable={false}
                    name="process"
                    cleanable={false}
                    data={processOptions}
                    onChange={handleSelectProcess}
                    onClean={handleChange}
                    labelTitle="Process"
                    value={values.process}
                    placeholder="Process"
                  />
                </div>
                <ErrorMessageField name="process" />
              </div>
              <div className="procedures-wrapper">
                <div className="procedures-header">Procedures</div>
                {checkEmptyProceduresList ? (
                  <div className="no-procedures">No procedures yet.</div>
                ) : (
                  <>
                    <FieldArray
                      name="procedures"
                      render={(arrayHelpers) => (
                        <div className="procedures-body">
                          {procedureOptions
                            .filter(
                              (procedure) =>
                                !assignedProcedures.find(
                                  (assignedProcedure) => assignedProcedure.procedure.id === procedure.value,
                                ),
                            )
                            .map((procedureOption) => (
                              <label className="procedure-label" key={procedureOption.value}>
                                <input
                                  name="procedures"
                                  type="checkbox"
                                  className="procedure-checkbox"
                                  value={procedureOption.value}
                                  checked={values.procedures.includes(procedureOption.value)}
                                  onChange={(e) => handleSelectProcedure(e, arrayHelpers, procedureOption.value)}
                                />
                                <div className="procedure-label-text">{procedureOption.label}</div>
                              </label>
                            ))}
                        </div>
                      )}
                    />
                    <ErrorMessageField name="procedures" />
                  </>
                )}
              </div>
            </div>
          </form>

          <div className="assign-procedure-form-footer">
            <Button type="button" className="close-btn margin-right" onClick={() => onCloseForm()}>
              Cancel
            </Button>
            <Button type="submit" className="accent padding" onClick={() => handleSubmit()}>
              Assign Procedure
            </Button>
          </div>
        </div>
      )}
    </Formik>
  );
};

export default AssignProcedureForm;
