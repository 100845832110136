import React from "react";
import { MenuItem } from "@shared/interfaces";

import SidebarItem from "../SidebarItem/SidebarItem";

import "./index.scss";

interface SidebarItemsProps {
  items: MenuItem[];
  currentPathname: string;
}

const SidebarItems = (props: SidebarItemsProps) => {
  const { items, currentPathname } = props;

  return (
    <div className="sidebar-items">
      {items.map((item) => (
        <SidebarItem key={item.name} item={item} currentPathname={currentPathname} />
      ))}
    </div>
  );
};

export default SidebarItems;
