import React from "react";

import "./index.scss";

export interface StatusColumn {
  status: string;
  percent?: number;
}

function StatusColumn(props: StatusColumn) {
  const { status, percent } = props;

  return (
    <div className="status-column">
      <div className={`status ${status}`} />
      <div>{status === "In progress" && percent ? `${status} - ${percent}%` : status}</div>
    </div>
  );
}

export default StatusColumn;
