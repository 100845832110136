export enum ModalSize {
  NORMAL = "normal",
  EXTENDED = "extended",
  RESIZABLE = "resizable",
}

export interface ModalComponent {
  type: string;
  draggable?: boolean;
  resizable?: boolean;
  size?: ModalSize;
  props: any;
}

export enum OverlaySize {
  NORMAL = "normal",
  EXTENDED = "extended",
  REDUCED = "reduced",
  EXTRA_REDUCED = "extra_reduced",
}

export interface OverlayComponent {
  type: string;
  size?: OverlaySize;
  closeOnOverlayClick: boolean;
  leaveConfirm?: boolean;
  props: any;
  class?: string;
}
