import { MenuItem } from "@shared/interfaces";
import { checkPermission, openInNewTab } from "@shared/utils";
import { PROCESS_INTERRACTIVE_SCHEMA_URL } from "@shared/constants/shared";
import { ReactComponent as SchemaIcon } from "@assets/images/icons/navbar/schema.svg";

import { ReactComponent as PeopleIcon } from "../../assets/images/icons/navbar/people.svg";
import { ReactComponent as ProcedureIcon } from "../../assets/images/icons/navbar/procedures.svg";
import { ReactComponent as ProcedureAdminIcon } from "../../assets/images/icons/navbar/procedures_admin.svg";
import { ReactComponent as SurveyIcon } from "../../assets/images/icons/navbar/surveys.svg";
import { ReactComponent as ProjectIcon } from "../../assets/images/icons/navbar/projects.svg";
import { ReactComponent as GoalIcon } from "../../assets/images/icons/navbar/goals.svg";
import { ReactComponent as RecognitionIcon } from "../../assets/images/icons/navbar/recognitions.svg";
import { ReactComponent as AccountabilityChartIcon } from "../../assets/images/icons/navbar/chart.svg";
import { PERMISSION } from "./permission";
import { NameOfChildRoutes } from "./routes";

export const SIDEBAR_ITEMS: MenuItem[] = [
  {
    name: "Teammates",
    path: `${NameOfChildRoutes.USERS.ROOT}`,
    root_path: NameOfChildRoutes.USERS.ROOT,
    permission: PERMISSION.VIEW_USERS,
    icon: PeopleIcon,
  },
  {
    name: "Procedures",
    path: `${NameOfChildRoutes.PROCEDURES.ROOT}`,
    root_path: NameOfChildRoutes.PROCEDURES.ROOT,
    exclude_paths: [`${NameOfChildRoutes.PROCEDURES.ROOT}${NameOfChildRoutes.PROCEDURES.MANAGE}`],
    permission: PERMISSION.CONTRACTOR_MODE,
    icon: ProcedureIcon,
  },
  {
    name: "Procedures ",
    path: `${NameOfChildRoutes.PROCEDURES.ROOT}${NameOfChildRoutes.PROCEDURES.MANAGE}`,
    root_path: NameOfChildRoutes.PROCEDURES.ROOT,
    exclude_paths: checkPermission(PERMISSION.CONTRACTOR_MODE) ? [NameOfChildRoutes.PROCEDURES.ROOT] : undefined,
    permission: PERMISSION.EDIT_PROCEDURE,
    icon: ProcedureAdminIcon,
  },
  {
    name: "Surveys",
    path: `${NameOfChildRoutes.SURVEYS.ROOT}`,
    root_path: NameOfChildRoutes.SURVEYS.ROOT,
    permission: PERMISSION.VIEW_SURVEYS,
    icon: SurveyIcon,
  },
  {
    name: "Projects",
    path: `${NameOfChildRoutes.PROJECTS.ROOT}`,
    root_path: NameOfChildRoutes.PROJECTS.ROOT,
    permission: PERMISSION.VIEW_PROJECTS,
    icon: ProjectIcon,
  },
  {
    name: "Goals",
    path: `${NameOfChildRoutes.GOALS.ROOT}`,
    root_path: NameOfChildRoutes.GOALS.ROOT,
    permission: PERMISSION.VIEW_GOALS,
    icon: GoalIcon,
  },
  {
    name: "Recognitions",
    path: `${NameOfChildRoutes.RECOGNITIONS.ROOT}`,
    root_path: NameOfChildRoutes.RECOGNITIONS.ROOT,
    permission: PERMISSION.VIEW_RECOGNITIONS,
    icon: RecognitionIcon,
  },
  {
    name: "Accountability Chart",
    path: `${NameOfChildRoutes.ACCOUNTABILITY_CHART.ROOT}`,
    root_path: NameOfChildRoutes.ACCOUNTABILITY_CHART.ROOT,
    icon: AccountabilityChartIcon,
  },
  ...(process.env.REACT_APP_ENV !== "production"
    ? [
        {
          name: "Process Schema",
          path: PROCESS_INTERRACTIVE_SCHEMA_URL,
          root_path: PROCESS_INTERRACTIVE_SCHEMA_URL,
          icon: SchemaIcon,
          onClick: () => {
            openInNewTab(PROCESS_INTERRACTIVE_SCHEMA_URL);
          },
        },
      ]
    : []),
];
