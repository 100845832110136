import React, { FC, useCallback } from "react";
import { Button, Icon } from "@shared/components";
import { actions as sharedActions } from "@shared/store";
import { useDispatch } from "react-redux";

import "./index.scss";

const RecognitionOverlayCreationNoteContainer: FC = () => {
  const dispatch = useDispatch();
  const closeRecognitionCreationNoteOverlay = useCallback(() => {
    dispatch(sharedActions.hideOverlay());
  }, [dispatch]);

  return (
    <div className="recognition-overlay__creation-note-container">
      <div className="recognition-overlay__creation-note-title">
        <div className="recognition-overlay__creation-note-title__main">Thank you!</div>
        <div className="recognition-overlay__creation-note-title__sub">Your Recognition is recorded</div>
      </div>
      <div className="recognition-overlay__creation-note-icon">
        <Icon type="recognitions-empty" />
      </div>
      <div className="recognition-overlay__creation-note-comment">
        Once the HR manager reviews the Recognition, <br /> the recipient will be notified
      </div>
      <div className="recognition-overlay__creation-note-button-wrapper">
        <Button onClick={closeRecognitionCreationNoteOverlay} className="accent padding full-width">
          Got it!
        </Button>
      </div>
    </div>
  );
};

export default RecognitionOverlayCreationNoteContainer;
