import { User } from "@shared/models/User";

export enum MeetingStatus {
  SCHEDULED = "Scheduled",
  CONDUCTED = "Conducted",
}

export interface Meeting {
  id: number;

  title: string;
  comment: string;
  date: Date;
  next_meeting_date: Date;
  manager_id: number;
  contractor_id: number;
  add_to_google_calendar: boolean;

  contractor?: User;
  manager?: User;
  status: MeetingStatus;

  created_by?: string;
}
