import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Icon, RangeProgressBar } from "@shared/components";
import { StatusColumn } from "@shared/components/Table/components";
import { getGbFormattedDate } from "@shared/utils";
import { Goal } from "@shared/models";
import { UpdateGoalProgressDto } from "@containers/Goal/interfaces";
import CommentListContainer from "@containers/Goal/containers/CommentsContainer/CommentListContainer/CommentListContainer";
import CommentCreateContainer from "@containers/Goal/containers/CommentsContainer/CommentCreateContainer/CommentCreateContainer";
import { GOALS_STATUS, PERMISSION } from "@shared/constants";
import { useCheckPermission } from "@shared/hooks";

import "./index.scss";

export interface GoalListProps {
  goal: Goal;
  updateGoalProgress: (payload: UpdateGoalProgressDto) => void;
  updateGoal: (payload: Goal) => void;
  deleteGoal: (payload: Goal) => void;
  closeGoal: (payload: Goal) => void;
  showComments: (goal_id: number) => void;
}

const GoalList: React.FC<GoalListProps> = (props) => {
  const { goal, updateGoalProgress, updateGoal, deleteGoal, closeGoal, showComments } = props;

  const [progressValue, setProgressValue] = useState(goal.progress);
  const canCloseGoal = useCheckPermission(PERMISSION.ASSIGN_GOAL);

  useEffect(() => {
    setProgressValue(goal.progress);
  }, [goal]);

  const updateGoalProgressHandler = useCallback(
    (value: number) => {
      if (value !== goal.progress) {
        updateGoalProgress({ id: goal.id, progress: value });
      }
    },
    [goal, updateGoalProgress],
  );

  const dueDate = useMemo(() => {
    const completeDate = goal.status === GOALS_STATUS.COMPLETED && new Date(goal.complete_date).getTime();
    const difference = (completeDate ? completeDate : new Date().getTime()) - new Date(goal.due_date).getTime();

    return Math.ceil(difference / (1000 * 3600 * 24)) > 1 ? (
      <span className="date-expired">
        <Icon type="danger" />
        Overdue {getGbFormattedDate(goal.due_date)}
      </span>
    ) : (
      <span>Due {getGbFormattedDate(goal.due_date)}</span>
    );
  }, [goal]);

  return (
    <div className="goal-content-wrapper">
      <div className="goal-details-wrapper">
        <div className="icons-wrapper">
          <div className="item-icon">
            <Icon type="goals" />
          </div>
          <div className="actions">
            <Icon type="edit" onClick={() => updateGoal(goal)} />
            <Icon type="delete" onClick={() => deleteGoal(goal)} />
            {goal.status !== GOALS_STATUS.CLOSED && canCloseGoal && (
              <Icon type="close" onClick={() => closeGoal(goal)} />
            )}
          </div>
        </div>
        <div>
          <div className="title">{goal.name}</div>
          <div className="date">{dueDate}</div>
        </div>
        <div className="description">{goal.description}</div>
        <RangeProgressBar
          min={0}
          max={100}
          step={1}
          value={progressValue}
          onChangeValue={setProgressValue}
          onFinalChangeValue={updateGoalProgressHandler}
        />
        <div className="status-wrapper">
          <StatusColumn status={goal.status} percent={progressValue} />
        </div>
      </div>
      <div className="goal-comments-wrapper">
        <div className="title">Comments</div>
        <CommentCreateContainer goal_id={goal.id} />
        {goal?.comments?.length > 0 && <CommentListContainer comments={[goal.comments[0]]} />}
        {goal?.comments?.length > 1 && (
          <div className="actions-wrapper" onClick={() => showComments(goal.id)}>
            <span>Show All ({goal.comments.length})</span> <Icon className="right" type="chevron-left" />
          </div>
        )}
      </div>
    </div>
  );
};

export default GoalList;
