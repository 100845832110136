export enum InputTypes {
  PASSWORD = "password",
  PHONE = "tel",
  EMAIL = "email",
  DATE = "date",
  NUMBER = "number",
  TEXT = "text",
  SELECT = "select",
  CHECKBOX = "checkbox",
}
