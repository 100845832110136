import { useEffect, useState } from "react";

const useDraggable = (
  dragElementRef: React.RefObject<HTMLDivElement>,
  moveElementRef: React.RefObject<HTMLDivElement>,
) => {
  const [dragging, setDragging] = useState(false);

  useEffect(() => {
    let XShift = 0;
    let YShift = 0;

    function mouseMove(e: MouseEvent) {
      if (!moveElementRef.current) return;

      moveElementRef.current.style.left = `${e.clientX - XShift}px`;
      moveElementRef.current.style.top = `${e.clientY - YShift}px`;
    }

    function mouseUp() {
      document.removeEventListener("mousemove", mouseMove);
      setDragging(false);
    }

    function mouseDown(e: MouseEvent) {
      if (!moveElementRef.current || e.target !== dragElementRef.current) return;

      const { top, left } = moveElementRef.current.getBoundingClientRect();

      XShift = e.clientX - left;
      YShift = e.clientY - top;

      moveElementRef.current.style.position = "fixed";

      document.addEventListener("mouseup", mouseUp);
      document.addEventListener("mousemove", mouseMove);

      setDragging(true);
    }

    document.addEventListener("mousedown", mouseDown);

    return () => {
      document.removeEventListener("mousedown", mouseDown);
      document.removeEventListener("mouseup", mouseUp);
      document.removeEventListener("mousemove", mouseMove);
    };
  }, [dragElementRef, moveElementRef]);

  return { dragging };
};

export default useDraggable;
