import * as Yup from "yup";
import { FORM_ERROR_MESSAGES, USER_STATUS } from "@shared/constants";
import { CoreValue, User } from "@shared/models";
import { Option } from "@shared/interfaces";
import { actions as sharedActions, selectors as sharedSelectors } from "@shared/store/";
import { actions as RecognitionActions, selectors as RecognitionSelectors } from "@containers/Recognition/store";
import { CreateRecognitionDto, RecognitionFormShape } from "@containers/Recognition/interfaces";

export const RECOGNITION_SCORE_OPTIONS: Option<number>[] = [
  { value: 5, label: "5" },
  { value: 10, label: "10" },
  { value: 15, label: "15" },
  { value: 20, label: "20" },
];

const optionValidationSchema = Yup.object().shape({
  value: Yup.string().required(FORM_ERROR_MESSAGES.REQUIRED),
  label: Yup.string().required(FORM_ERROR_MESSAGES.REQUIRED),
});

export const validationSchema = Yup.object().shape({
  description: Yup.string()
    .min(10, `Description ${FORM_ERROR_MESSAGES.SHORT}`)
    .max(500, `Description ${FORM_ERROR_MESSAGES.LONG}`)
    .required(FORM_ERROR_MESSAGES.REQUIRED),
  score: Yup.number().required(FORM_ERROR_MESSAGES.REQUIRED),
  user: optionValidationSchema.required(FORM_ERROR_MESSAGES.REQUIRED).typeError(FORM_ERROR_MESSAGES.REQUIRED),
  core_value: optionValidationSchema.required(FORM_ERROR_MESSAGES.REQUIRED).typeError(FORM_ERROR_MESSAGES.REQUIRED),
});

export const getInitValues = (): RecognitionFormShape => ({
  description: "",
  score: RECOGNITION_SCORE_OPTIONS[0].value,
  user: null,
  core_value: null,
  is_anonymous: true,
});

export const prepareSubmitValue = (values: RecognitionFormShape): CreateRecognitionDto => {
  return {
    title: values.core_value?.label || "",
    description: values.description,
    score: values.score,
    user_id: Number(values.user?.value),
    core_value_id: Number(values.core_value?.value),
    is_anonymous: values.is_anonymous,
  };
};

export const handlers = {
  user: {
    selectData: sharedSelectors.getUsers,
    loadingAction: sharedActions.getUsers,
    prepareOptionFunction: (u: User): Option => {
      return { label: `${u.first_name} ${u.last_name}`, value: String(u.id) };
    },
    getData: (search?: string) => {
      return sharedActions.getUsers.request({
        page: 0,
        limit: 500,
        search: search || "",
        status: USER_STATUS.ACTIVE,
      });
    },
    onSort: () => {
      return (a: Option, b: Option) => {
        return a.label.localeCompare(b.label);
      };
    },
  },
  coreValues: {
    selectData: RecognitionSelectors.getCoreValues,
    loadingAction: RecognitionActions.getCoreValues,
    prepareOptionFunction: (cv: CoreValue): Option => {
      return { label: cv.title, value: String(cv.id) };
    },
    getData: () => {
      return RecognitionActions.getCoreValues.request();
    },
  },
};
