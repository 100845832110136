import React from "react";
import { MenuItem } from "@shared/interfaces";
import { Logo } from "@shared/components/Logo";
import { Button } from "@shared/components/Common";
import { User } from "@shared/models";
import { ReactComponent as LogoutIcon } from "@assets/images/icons/navbar/logout.svg";
import { useLocation } from "react-router";

import { SidebarItems } from "../SidebarItems";

import "./index.scss";

export interface MainSidebarProps {
  user: User | null;
  items: MenuItem[];
  onLogoutClick: () => void;
}

const Sidebar = (props: MainSidebarProps) => {
  const { items, onLogoutClick } = props;

  const location = useLocation();

  return (
    <div className="main-sidebar">
      <div className="sidebar-logo">
        <Logo />
      </div>
      <SidebarItems items={items} currentPathname={location.pathname} />
      <div className="sidebar-logout-wrapper">
        <div className="sidebar-logout">
          <Button onClick={onLogoutClick} className="clear sidebar-logout-btn">
            <LogoutIcon className="icon-logout" />
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
