import React from "react";
import { UserProcedure } from "@shared/models";
import { TableProperty } from "@shared/components/Table/interfaces";
import ProcedureStatusColumn from "@shared/components/Table/components/ProcedureStatusColumn/ProcedureStatusColumn";
import { CellWithIcon } from "@shared/components/Table/components";

export const procedureTablePropertiesForContractorAssigned: TableProperty<UserProcedure>[] = [
  {
    title: "Procedure Name",
    key: "procedure",
    get: (item) => <CellWithIcon iconType="procedure-list" text={item.procedure.name} />,
  },
  {
    title: "Status",
    key: "status",
    get: (item) => <ProcedureStatusColumn status={item.status} />,
  },
  {
    title: "Process name",
    key: "procedure",
    get: (item) => item.procedure.process?.map((t) => t.name).join(", ") || "-",
  },
  {
    title: "Accountable Team",
    key: "procedure",
    get: (item) => item.procedure.accountable_team?.name || "-",
  },
  {
    title: "Support Teams",
    key: "procedure",
    get: (item) => item.procedure.teams?.map((r) => r.name).join(", ") || "-",
  },
];
