import React, { useCallback, useMemo } from "react";
import classnames from "classnames";
import { useDispatch, useSelector } from "react-redux";
import { selectors, actions } from "@shared/store";
import { MODAL_TYPES, OVERLAY_TYPES } from "@shared/constants";
import { ProjectCreateEditContainer } from "@containers/Project/containers/ProjectCreateEditContainer";
import { ProcedureCreateEditContainer } from "@containers/Procedure/containers/ProcedureCreateEditContainer";
import { AssignOnboardingContainer } from "@containers/User/containers/UserViewContainer/UserProceduresContainer/AssignOnboardingContainer";
import { OverlaySize } from "@shared/interfaces";
import UserEditContainer from "@containers/User/containers/UserEditContainer/UserEditContainer";
import { CreateEditMeetingContainer } from "@containers/User/containers/UserViewContainer/UserMeetingsContainer/CreateMeetingContainer";
import { AssignProcedureContainer } from "@containers/User/containers/UserViewContainer/UserProceduresContainer/AssignProcedureContainer";
import { AssignSurveyContainer } from "@containers/User/containers/UserViewContainer/UserSurveysContainer/AssignSurveyContainer";
import { GoalCreateEditContainer } from "@containers/Goal/containers/GoalCreateEditContainer";
import { CommentsContainer } from "@containers/Goal/containers/CommentsContainer";
import { GoalCloseContainer } from "@containers/Goal/containers";
import { RecognitionOverlayDetailsContainer } from "@containers/Recognition/containers/RecognitionOverlayDetailsContainer";
import { RecognitionCreateContainer } from "@containers/Recognition/containers/RecognitionCreateContainer";
import { FinishOnboardingContainer } from "@containers/Procedure/containers/FinishOnboardingContainer";
import { RecognitionOverlayCreationNoteContainer } from "@containers/Recognition/containers/RecognitionOverlayCreationNoteContainer";
import "./index.scss";

function OverlayWrapper() {
  const dispatch = useDispatch();

  const overlay = useSelector(selectors.getOverlay());

  const hideOverlayAction = useCallback(() => {
    dispatch(actions.hideOverlay());
    if (overlay?.props.onClose) {
      overlay.props.onClose();
    }
  }, [dispatch, overlay]);

  const onCloseOverlay = useCallback(() => {
    if (overlay?.leaveConfirm) {
      dispatch(
        actions.showModal({
          type: MODAL_TYPES.CONFIRM,
          props: {
            heading: "You have unsaved changes",
            content: "Are you sure you want to leave? If you leave this page now your progress will be lost.",
            cancelBtnText: "Cancel",
            successBtnText: "Leave",
            onSuccess: hideOverlayAction,
          },
        }),
      );
      return;
    }

    hideOverlayAction();
  }, [overlay, dispatch, hideOverlayAction]);

  const Component = useMemo(() => {
    if (!overlay) return;

    return overlayStrategy(overlay.type);
  }, [overlay]);
  return overlay ? (
    <>
      <div className="overlay" onClick={() => (overlay.closeOnOverlayClick ? onCloseOverlay() : null)} />
      <div
        className={classnames(
          "overlay-wrapper",
          overlay.class,
          `overlay-wrapper_size-${overlay.size || OverlaySize.NORMAL}`,
        )}
      >
        <div className="inner-wrapper">
          {Component ? <Component {...overlay.props} onClose={onCloseOverlay} /> : null}
        </div>
      </div>
    </>
  ) : null;
}

export default OverlayWrapper;

const overlayStrategy = (type: string) => {
  switch (type) {
    case OVERLAY_TYPES.CREATE_EDIT_PROJECT: {
      return ProjectCreateEditContainer;
    }

    case OVERLAY_TYPES.CREATE_EDIT_PROCEDURE: {
      return ProcedureCreateEditContainer;
    }

    case OVERLAY_TYPES.EDIT_USER: {
      return UserEditContainer;
    }

    case OVERLAY_TYPES.ASSIGN_ONBOARDING: {
      return AssignOnboardingContainer;
    }

    case OVERLAY_TYPES.ASSIGN_PROCEDURE: {
      return AssignProcedureContainer;
    }

    case OVERLAY_TYPES.CREATE_EDIT_USER_MEETING: {
      return CreateEditMeetingContainer;
    }

    case OVERLAY_TYPES.ASSIGN_SURVEY: {
      return AssignSurveyContainer;
    }

    case OVERLAY_TYPES.CREATE_EDIT_GOAL: {
      return GoalCreateEditContainer;
    }

    case OVERLAY_TYPES.SHOW_GOAL_COMMENTS: {
      return CommentsContainer;
    }

    case OVERLAY_TYPES.CLOSE_GOAL: {
      return GoalCloseContainer;
    }
    case OVERLAY_TYPES.SHOW_RECOGNITION_DETAILS: {
      return RecognitionOverlayDetailsContainer;
    }
    case OVERLAY_TYPES.SHOW_RECOGNITION_CREATION_NOTE: {
      return RecognitionOverlayCreationNoteContainer;
    }
    case OVERLAY_TYPES.CREATE_RECOGNITION: {
      return RecognitionCreateContainer;
    }
    case OVERLAY_TYPES.FINISH_ONBOARDING_POPUP: {
      return FinishOnboardingContainer;
    }
  }
};
