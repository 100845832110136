import React, { useEffect, useMemo, useState } from "react";
import { Formik } from "formik";
import { Button, ErrorMessageField, SelectPickerInput } from "@shared/components";
import { Survey } from "@shared/models";
import { Option } from "@shared/interfaces";
import { AssignSurveyDto } from "@containers/User/interfaces/UserForm.interface";

import { getInitValues, validationSchema, prepareSubmitValue } from "./formHelpers";

import "./index.scss";

interface AssignSurveyFormProps {
  surveys: Survey[];
  onChangeForm: () => void;
  onCloseForm: () => void;
  onSubmitForm: (payload: Omit<AssignSurveyDto, "user_id">) => void;
}

const AssignSurveyForm: React.FunctionComponent<AssignSurveyFormProps> = (props) => {
  const { onChangeForm, onCloseForm, onSubmitForm, surveys } = props;
  const [surveyOptions, setSurveyOptions] = useState<Option<number>[]>([]);

  const formValues = useMemo(() => getInitValues(), []);

  useEffect(() => {
    if (surveys.length) {
      const surveyOpt = surveys.map((i) => {
        return {
          label: i.name,
          value: i.id,
        };
      });
      setSurveyOptions(surveyOpt);
    }
  }, [surveys]);

  return (
    <Formik
      validationSchema={validationSchema}
      enableReinitialize={true}
      validateOnBlur={false}
      onSubmit={(values, { setSubmitting }) => {
        onSubmitForm(prepareSubmitValue(values));
        setSubmitting(false);
      }}
      initialValues={formValues}
      validate={() => {
        onChangeForm();
      }}
    >
      {({ values, handleSubmit, setFieldValue, handleChange }) => (
        <div className="assign-survey-form">
          <form onSubmit={handleSubmit}>
            <div className="assign-survey-form-content">
              <div className="survey-input-wrapper">
                <div className="survey-input">
                  <SelectPickerInput
                    searchable={false}
                    name="surveys"
                    cleanable={false}
                    data={surveyOptions}
                    onChange={(value) => setFieldValue("surveys", [value])}
                    onClean={handleChange}
                    labelTitle="Survey"
                    value={values.surveys[0]}
                    placeholder="Survey"
                  />
                </div>
                <ErrorMessageField name="surveys" />
              </div>
            </div>

            <div className="assign-survey-form-footer">
              <Button type="button" className="close-btn margin-right" onClick={() => onCloseForm()}>
                Cancel
              </Button>
              <Button type="submit" className="accent padding" onSubmit={() => handleSubmit()}>
                Assign Survey
              </Button>
            </div>
          </form>
        </div>
      )}
    </Formik>
  );
};

export default AssignSurveyForm;
