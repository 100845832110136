import React from "react";
import { Range } from "react-range";
import { Icon } from "@shared/components";

import "./index.scss";

export interface RangeProgressBarProps {
  min: number;
  max: number;
  step: number;
  value: number;
  onChangeValue: (value: number) => void;
  onFinalChangeValue: (value: number) => void;
}

const RangeProgressBar: React.FC<RangeProgressBarProps> = ({
  min,
  max,
  step,
  value,
  onChangeValue,
  onFinalChangeValue,
}) => (
  <div className="progress-bar-wrapper">
    <div className="progress-bar-visible">
      <div className="progress-bar">
        <div className="point" />
        <div className="point" />
        <div className="point" />
        <div className="point" />
        <div className="point" />
        <div className="point" />
        <div className="point" />
        <div className="point" />
        <div className="point" />
        <div className="progress" style={{ width: value + "%" }}>
          <div className="progress-control">
            <div className="percent">{value !== 100 ? `${value}%` : <Icon type="progress-success" />}</div>
          </div>
        </div>
      </div>
    </div>
    <div className="progress-bar-hidden">
      <Range
        step={step}
        min={min}
        max={max}
        values={[value <= min ? min : value]}
        onChange={(value) => {
          onChangeValue(value[0]);
        }}
        onFinalChange={(value) => {
          onFinalChangeValue(value[0]);
        }}
        renderTrack={({ props, children }) => (
          <div
            className="track"
            {...props}
            style={{
              ...props.style,
              height: "36px",
              width: "100%",
              borderRadius: "30px",
              background: "transparent",
            }}
          >
            {children}
          </div>
        )}
        renderThumb={({ props }) => (
          <div
            {...props}
            style={{
              ...props.style,
              height: "28px",
              width: "150px",
              borderRadius: "16px",
              backgroundColor: "transparent",
              outline: "none",
            }}
          />
        )}
      />
    </div>
  </div>
);

export default RangeProgressBar;
