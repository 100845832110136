import React, { useCallback } from "react";
import { useDispatch } from "react-redux";
import { Comment } from "@shared/models";
import { CommentList } from "@containers/Goal/components";
import { UpdateCommentDto } from "@containers/Goal/interfaces";
import { actions as sharedActions } from "@shared/store";
import { actions } from "@containers/Goal/store";
import { MODAL_TYPES } from "@shared/constants";

export interface CommentListContainerProps {
  comments: Comment[];
}

const CommentListContainer: React.FC<CommentListContainerProps> = ({ comments }) => {
  const dispatch = useDispatch();

  const handleEditComment = useCallback(
    (payload: UpdateCommentDto) => {
      dispatch(actions.updateComment.request(payload));
    },
    [dispatch],
  );

  const handleDeleteComment = useCallback(
    (comment: Comment) => {
      if (!comment) return;

      dispatch(
        sharedActions.showModal({
          type: MODAL_TYPES.CONFIRM,
          props: {
            heading: "Delete",
            content: `Are you sure you want to delete: ${comment.description}?`,
            cancelBtnText: "Cancel",
            successBtnText: "Delete",
            onSuccess: () => {
              dispatch(actions.deleteComment.request(comment.id));
            },
          },
        }),
      );
    },
    [dispatch],
  );

  return <CommentList comments={comments} editComment={handleEditComment} deleteComment={handleDeleteComment} />;
};

export default CommentListContainer;
