import { Procedure } from "@shared/models/Procedure";

export enum UserProcedureStatus {
  NEW = "New",
  IN_PROGRESS = "In progress",
  REVIEWED = "Reviewed",
}

export interface UserProcedure {
  readonly id: number;
  status: UserProcedureStatus;
  user_id: number;
  last_review: Date;
  start_review: Date;
  end_review: Date;
  procedure: Procedure;
}
