import React, { lazy } from "react";
import { useRoutes, Navigate } from "react-router-dom";
import { Loadable } from "@shared/components";
import { NameOfChildRoutes } from "@shared/constants";
import { AuthGuard } from "@shared/guards";

import "./index.scss";

const GoalListContainer = Loadable(lazy(() => import("../GoalListContainer/GoalListContainer")));

const routes = [
  {
    path: `${NameOfChildRoutes.GOALS.LIST}`,
    element: (
      <AuthGuard>
        <GoalListContainer />
      </AuthGuard>
    ),
  },
  {
    path: `/*`,
    element: <Navigate to={`${NameOfChildRoutes.GOALS.LIST}`} />,
  },
];

const GoalContainer = () => {
  const content = useRoutes(routes);

  return <div className="goal-container">{content}</div>;
};

export default GoalContainer;
