import { ProjectFormShape } from "@containers/Project/interfaces";
import { FormikErrors, FormikTouched } from "formik";
import React from "react";

import "./index.scss";

interface WorkTypeErrorMessageProps {
  name: keyof ProjectFormShape;
  touched: FormikTouched<ProjectFormShape>;
  errors: FormikErrors<ProjectFormShape>;
}

const WorkTypeErrorMessage: React.FunctionComponent<WorkTypeErrorMessageProps> = (props) => {
  return props.touched[props.name] && typeof props.errors[props.name] === "string" ? (
    <div className="work-type-error-message">{String(props.errors[props.name])}</div>
  ) : null;
};

export default WorkTypeErrorMessage;
