import React, { useCallback } from "react";
import { Icon } from "@shared/components";
import { IconType } from "@shared/components/Icon/Icon";
import { ORDER_TYPE } from "@shared/constants";

import { TableProperty } from "../../interfaces";
import "./index.scss";

interface ISortableHeaderProps<T> {
  column: TableProperty<T>;
  sortOrder?: ORDER_TYPE;
  onChangeSorting?: (fields: string[] | undefined, order: ORDER_TYPE) => void;
}

function SortableHeader<T>(props: ISortableHeaderProps<T>) {
  const { sortOrder, onChangeSorting, column } = props;

  const handleChangeSorting = useCallback(() => {
    const orderTypes = Object.values(ORDER_TYPE);
    const nextOrderType = orderTypes[(orderTypes.indexOf(sortOrder || ORDER_TYPE.NONE) + 1) % orderTypes.length];

    if (onChangeSorting) {
      onChangeSorting(column.sort, nextOrderType);
    }
  }, [onChangeSorting, sortOrder, column]);

  return (
    <div className="sortable-header" onClick={() => handleChangeSorting()}>
      <div>{column.title}</div>
      <Icon className="sort-icon" type={`sort${sortOrder ? `-${sortOrder.toLowerCase()}` : ""}` as IconType} />
    </div>
  );
}

export default SortableHeader;
