import { call, put, takeLatest } from "redux-saga/effects";
import { startLoading, stopLoading } from "@shared/store/actions";
import { getChartData } from "@containers/AccountabilityChart/store/actions";
import { Chart } from "@shared/models";

import api from "../api";

function* getChartDataSaga() {
  try {
    yield put(startLoading());
    const response: Chart = yield call(api.getChartData);
    yield put(stopLoading());
    yield put(getChartData.success(response));
  } catch (error) {
    yield put(getChartData.failure(error as Error));
    yield put(stopLoading());
  }
}

function* chartSagas() {
  yield takeLatest(getChartData.request, getChartDataSaga);
}

export default chartSagas;
