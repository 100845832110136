import React from "react";

import "./index.scss";

export interface ProcedureStatusColumnProps {
  status: string;
}

function ProcedureStatusColumn(props: ProcedureStatusColumnProps) {
  const { status } = props;

  return (
    <div className={`procedure-status ${status}`}>
      <div>{status}</div>
    </div>
  );
}

export default ProcedureStatusColumn;
