import { createAsyncAction } from "typesafe-actions";
import { Chart } from "@shared/models";

import { ChartActionTypes } from "./constants";

export const getChartData = createAsyncAction(
  ChartActionTypes.GET_CHART_DATA,
  ChartActionTypes.GET_CHART_DATA_SUCCESS,
  ChartActionTypes.GET_CHART_DATA_FAILURE,
)<undefined, Chart, Error>();
