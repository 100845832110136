import React from "react";
import classnames from "classnames";

import "./index.scss";

export interface LabelItemProps {
  className?: string;
  title: string;
  value?: string | React.ReactNode;
}

const LabelItem: React.FC<LabelItemProps> = ({ className, value, title }) =>
  value ? (
    <div className={classnames("label-item", className)}>
      <div className="label-item-title">{title}</div>
      <div className="label-item-value">{value}</div>
    </div>
  ) : null;

export default LabelItem;
