import { useMemo } from "react";
import { debounce } from "@shared/utils";

export default function useDebouncedFunction<F extends (...args: Parameters<F>) => ReturnType<F>>(
  func?: F,
  delay = 500,
) {
  const debouncedFunction = useMemo(() => {
    if (!func) return;

    return debounce(func, delay);
  }, [func, delay]);
  return debouncedFunction;
}
