import { request } from "@shared/utils";
import { API, METHODS } from "@shared/constants";

import { GoogleLoginShape, LoginShape } from "../interfaces";

export default {
  login: (payload: LoginShape) => request(METHODS.POST, API.AUTH.LOGIN)(payload),
  googleLogin: (payload: GoogleLoginShape) => request(METHODS.POST, API.AUTH.GOOGLE_LOGIN)(payload),
  googleRegister: (payload: GoogleLoginShape) => request(METHODS.POST, API.AUTH.GOOGLE_REGISTER)(payload),
};
