import React from "react";
import { NOTIFICATION_APPEARANCE } from "@shared/interfaces";
import { useToasts } from "react-toast-notifications";
import { Icon } from "@shared/components/Icon";

import "./index.scss";

export interface ToastProps {
  appearance: NOTIFICATION_APPEARANCE;
  children: React.ReactNode;
}

const Toast = (props: ToastProps) => {
  const { appearance, children } = props;
  const { removeAllToasts } = useToasts();

  return (
    <div className={`notification ${appearance}`}>
      <div className={`notification-img ${appearance}`} />
      <div className="notification-message">{children}</div>
      <Icon type="white-clear" onClick={removeAllToasts} />
    </div>
  );
};

export default Toast;
