import { request } from "@shared/utils";
import { API, METHODS } from "@shared/constants";
import { ExportUsersHoursFilter, Filter, MeetingFilter, SurveysFilter, UserSurveyFilter } from "@shared/interfaces";
import {
  AssignOnboardingToUserDto,
  AssignProcedureDto,
  AssignSurveyDto,
  CreateUserDto,
  CreateUserMeetingDto,
  CreateUserSurveyAnswersDto,
  SelfAssignProcedureDto,
  UpdateUserDto,
  UpdateUserMeetingDto,
  UpdateUserProcedureStatusDto,
  UpdateUserStatusDto,
} from "@containers/User/interfaces/UserForm.interface";

export default {
  getUsers: (payload: Filter) => request(METHODS.GET, API.USER.GET_USERS)({ params: payload }),
  getUser: (userId: string) => request(METHODS.GET, API.USER.GET_USER(userId))(),
  syncUsers: () => request(METHODS.GET, API.USER.SYNC_USERS)(),
  syncUsersHours: () => request(METHODS.GET, API.USER.SYNC_USERS_HOURS)(),
  exportUsersHours: (payload: ExportUsersHoursFilter | undefined) =>
    request(METHODS.GET, API.USER.EXPORT_USERS_HOURS)({ params: payload }),
  createUser: (payload: CreateUserDto) => request(METHODS.POST, API.USER.CREATE_USER)(payload),
  updateUser: (payload: UpdateUserDto) => {
    const { id } = payload;
    return request(METHODS.PUT, API.USER.UPDATE_USER(String(id)))(payload);
  },
  deleteUser: (id: number) => {
    return request(METHODS.DELETE, API.USER.DELETE_USER(String(id)))();
  },
  getMeetings: (payload: MeetingFilter) => {
    return request(METHODS.GET, API.USER.GET_MEETINGS)({ params: payload });
  },
  getSurveys: (payload: UserSurveyFilter) => {
    return request(METHODS.GET, API.USER.GET_USER_SURVEYS)({ params: payload });
  },
  getSurvey: (id: number) => {
    return request(METHODS.GET, API.USER.GET_USER_SURVEY(String(id)))();
  },
  createUserSurveyAnswers: (payload: CreateUserSurveyAnswersDto) => {
    const { id, ...rest } = payload;
    return request(METHODS.POST, API.USER.CREATE_USER_SURVEY_ANSWERS(String(id)))(rest);
  },
  createUserMeeting: (payload: CreateUserMeetingDto) => request(METHODS.POST, API.USER.CREATE_MEETING)(payload),
  updateUserMeeting: (payload: UpdateUserMeetingDto) => {
    const { id, ...rest } = payload;
    return request(METHODS.PUT, API.USER.UPDATE_MEETING(String(id)))(rest);
  },
  deleteUserMeeting: (id: number) => {
    return request(METHODS.DELETE, API.USER.DELETE_MEETING(String(id)))();
  },
  getOnboardings: () => request(METHODS.GET, API.USER.GET_ONBOARDINGS)(),
  getAllProcessesWithProcedures: () => request(METHODS.GET, API.USER.GET_PROCESSES_WITH_PROCEDURES)(),
  getSurveyList: (payload: SurveysFilter) => request(METHODS.GET, API.SURVEY.GET_SURVEYS)({ params: payload }),
  getUserProcedures: (payload: Filter) => request(METHODS.GET, API.USER.GET_PROCEDURES)({ params: payload }),
  assignOnboarding: (payload: AssignOnboardingToUserDto) =>
    request(METHODS.POST, API.USER.ASSIGN_ONBOARDING_TO_USER)(payload),
  assignProcedure: (payload: AssignProcedureDto) => request(METHODS.POST, API.USER.ASSIGN_PROCEDURE)(payload),
  selfAssignProcedure: (payload: SelfAssignProcedureDto) =>
    request(METHODS.POST, API.USER.SELF_ASSIGN_PROCEDURE)(payload),
  assignSurvey: (payload: AssignSurveyDto) => request(METHODS.POST, API.USER.ASSIGN_SURVEY)(payload),
  updateUserStatus: (payload: UpdateUserStatusDto) => {
    const { id, ...rest } = payload;
    return request(METHODS.PUT, API.USER.UPDATE_STATUS(String(id)))(rest);
  },
  inviteUser: (id: number) => {
    return request(METHODS.POST, API.USER.INVITE_USER(String(id)))();
  },
  updateUserProcedureStatus: (payload: UpdateUserProcedureStatusDto) => {
    return request(METHODS.PUT, API.USER.UPDATE_USER_PROCEDURE_STATUS)(payload);
  },
};
