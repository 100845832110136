import * as Yup from "yup";
import { FORM_ERROR_MESSAGES, IS_VALID_EMAIL, TYPE_EMPLOYMENT, USER_STATUS } from "@shared/constants";
import { Role, User } from "@shared/models";
import { Option } from "@shared/interfaces";
import { actions as sharedActions, selectors as sharedSelectors } from "@shared/store/";
import { CreateUserDto, UserFormShape } from "@containers/User/interfaces/UserForm.interface";

export const USER_STATUS_OPTIONS: Option[] = [
  { value: "Active", label: "Active" },
  { value: "Archived", label: "Archived" },
  { value: "Inactive", label: "Inactive" },
];

export const USER_CONNECTION_OPTIONS: Option[] = [
  { value: "Direct Contractor", label: "Direct Contractor" },
  { value: "Employee", label: "Employee" },
  { value: "Agency", label: "Agency" },
];

export const USER_TYPE_EMPLOYMENT_OPTIONS: Option[] = [
  { value: TYPE_EMPLOYMENT.FULL_TIME_JOB, label: TYPE_EMPLOYMENT.FULL_TIME_JOB },
  { value: TYPE_EMPLOYMENT.PART_TIME_JOB, label: TYPE_EMPLOYMENT.PART_TIME_JOB },
];

const optionValidationSchema = Yup.object().shape({
  value: Yup.string().required(FORM_ERROR_MESSAGES.REQUIRED),
  label: Yup.string().required(FORM_ERROR_MESSAGES.REQUIRED),
});

export const validationSchema = Yup.object().shape({
  first_name: Yup.string().max(50, `First name ${FORM_ERROR_MESSAGES.LONG}`).required(FORM_ERROR_MESSAGES.REQUIRED),
  last_name: Yup.string().max(50, `Last name ${FORM_ERROR_MESSAGES.LONG}`).required(FORM_ERROR_MESSAGES.REQUIRED),
  email: Yup.string()
    .matches(IS_VALID_EMAIL, FORM_ERROR_MESSAGES.EMAIL)
    .max(150, `The email ${FORM_ERROR_MESSAGES.LONG}`)
    .required(FORM_ERROR_MESSAGES.REQUIRED),
  permission: optionValidationSchema.required(FORM_ERROR_MESSAGES.REQUIRED).typeError(FORM_ERROR_MESSAGES.REQUIRED),
  job_title: Yup.string().max(50, `Job title ${FORM_ERROR_MESSAGES.LONG}`).required(FORM_ERROR_MESSAGES.REQUIRED),
  team: optionValidationSchema.required(FORM_ERROR_MESSAGES.REQUIRED).typeError(FORM_ERROR_MESSAGES.REQUIRED),
  type_employment: Yup.string().required(FORM_ERROR_MESSAGES.REQUIRED).typeError(FORM_ERROR_MESSAGES.REQUIRED),
  connection: Yup.string().required(FORM_ERROR_MESSAGES.REQUIRED).typeError(FORM_ERROR_MESSAGES.REQUIRED),
  manager: optionValidationSchema.required(FORM_ERROR_MESSAGES.REQUIRED).typeError(FORM_ERROR_MESSAGES.REQUIRED),
  start_date: Yup.date()
    .required(FORM_ERROR_MESSAGES.REQUIRED)
    .test("start_date", "Please specify valid date", function (value) {
      const year = value?.getFullYear();
      return !(year && year < 2000);
    }),
});

export const getTeamInitValues = (user: User | null) => {
  if (!user || !user.team) return null;

  return {
    label: user.team.name,
    value: String(user.team.id),
  };
};

export const getRoleInitValue = (user: User | null) => {
  if (!user || !user.roles?.length) return null;

  return {
    label: `${user.roles[0].name}`,
    value: String(user.roles[0].id),
  };
};

export const getManagerInitValue = (user: User | null) => {
  if (!user || !user.manager) return null;

  return {
    label: `${user.manager?.first_name} ${user.manager?.last_name}`,
    value: String(user.manager.id),
  };
};

export const getInitValues = (user: User | null): UserFormShape => ({
  first_name: user?.first_name || "",
  last_name: user?.last_name || "",
  email: user?.login || "",
  job_title: user?.job_title || "",
  permission: getRoleInitValue(user),
  team: getTeamInitValues(user),
  type_employment: user?.type_employment || null,
  connection: user?.connection || USER_CONNECTION_OPTIONS[0].value,
  manager: getManagerInitValue(user),
  start_date: user?.start_date ? new Date(user.start_date).toISOString().substring(0, 10) : "",
  used_pto: user?.used_pto || 0,
});

export const prepareSubmitValue = (values: UserFormShape, user: User | null): CreateUserDto => {
  const { first_name, last_name, manager, email, team, permission, start_date, ...rest } = values;
  return {
    ...rest,
    first_name,
    last_name,
    manager_id: manager ? Number(manager.value) : null,
    login: email,
    status: user?.status ? user?.status : USER_STATUS.ACTIVE,
    team_id: team?.value ? Number(team?.value) : null,
    permission_id: permission?.value ? Number(permission?.value) : null,
    start_date: new Date(String(start_date)),
  };
};

export const handlers = {
  manager: {
    selectData: sharedSelectors.getUsers,
    loadingAction: sharedActions.getUsers,
    prepareOptionFunction: (u: User): Option => {
      return { label: `${u.first_name} ${u.last_name}`, value: String(u.id) };
    },
    getData: (search?: string) => {
      return sharedActions.getUsers.request({
        page: 0,
        limit: 500,
        search: search || "",
        status: USER_STATUS.ACTIVE,
      });
    },
    onSort: () => {
      return (a: Option, b: Option) => {
        return a.label.localeCompare(b.label);
      };
    },
  },
  roles: {
    selectData: sharedSelectors.getRoles,
    loadingAction: sharedActions.getRoles,
    prepareOptionFunction: (item: Role): Option => {
      return { label: item.name, value: String(item.id) };
    },
    getData: () => {
      return sharedActions.getRoles.request();
    },
  },
};
