export enum UserActionTypes {
  GET_USERS = "@@USER/GET_USERS",
  GET_USERS_SUCCESS = "@@USER/GET_USERS_SUCCESS",
  GET_USERS_FAILURE = "@@USER/GET_USERS_FAILURE",

  GET_USER = "@@USER/GET_USER",
  GET_USER_SUCCESS = "@@USER/GET_USER_SUCCESS",
  GET_USER_FAILURE = "@@USER/GET_USER_FAILURE",

  SYNC_USERS_HARVEST = "@@USER/SYNC_USERS_HARVEST",
  SYNC_USERS_HARVEST_SUCCESS = "@@USER/SYNC_USERS_HARVEST_SUCCESS",
  SYNC_USERS_HARVEST_FAILURE = "@@USER/SYNC_USERS_HARVEST_FAILURE",

  SYNC_USERS_HOURS_HARVEST = "@@USER/SYNC_USERS_HOURS_HARVEST",
  SYNC_USERS_HOURS_HARVEST_SUCCESS = "@@USER/SYNC_USERS_HOURS_HARVEST_SUCCESS",
  SYNC_USERS_HOURS_HARVEST_FAILURE = "@@USER/SYNC_USERS_HOURS_HARVEST_FAILURE",

  EXPORT_USERS_HOURS = "@@USER/EXPORT_USERS_HOURS",
  EXPORT_USERS_HOURS_SUCCESS = "@@USER/EXPORT_USERS_HOURS_SUCCESS",
  EXPORT_USERS_HOURS_FAILURE = "@@USER/EXPORT_USERS_HOURS_FAILURE",

  CLEAR_USERS = "@@USER/CLEAR_USERS",
  CLEAR_USER_MEETINGS = "@@USER/CLEAR_USER_MEETINGS",
  CLEAR_USER_PROCEDURES = "@@USER/CLEAR_USER_PROCEDURES",
  CLEAR_USER_SURVEYS = "@@USER/CLEAR_USER_SURVEYS",
  CLEAR_USER_SURVEY = "@@USER/CLEAR_USER_SURVEY",
  SET_FILTER = "@@USER/SET_FILTER",
  SET_MEETINGS_FILTER = "@@USER/SET_MEETINGS_FILTER",
  SET_USER_PROCEDURES_FILTER = "@@USER/SET_USER_PROCEDURES_FILTER",
  SET_USER_SURVEYS_FILTER = "@@USER/SET_USER_SURVEYS_FILTER",

  CREATE_USER = "@@USER/CREATE_USER",
  CREATE_USER_SUCCESS = "@@USER/CREATE_USER_SUCCESS",
  CREATE_USER_FAILURE = "@@USER/CREATE_USER_FAILURE",

  UPDATE_USER = "@@USER/UPDATE_USER",
  UPDATE_USER_SUCCESS = "@@USER/UPDATE_USER_SUCCESS",
  UPDATE_USER_FAILURE = "@@USER/UPDATE_USER_FAILURE",

  DELETE_USER = "@@USER/DELETE_USER",
  DELETE_USER_SUCCESS = "@@USER/DELETE_USER_SUCCESS",
  DELETE_USER_FAILURE = "@@USER/DELETE_USER_FAILURE",

  GET_MEETINGS = "@@USER/GET_MEETINGS",
  GET_MEETINGS_SUCCESS = "@@USER/GET_MEETINGS_SUCCESS",
  GET_MEETINGS_FAILURE = "@@USER/GET_MEETINGS_FAILURE",

  GET_USER_SURVEYS = "@@USER/GET_USER_SURVEYS",
  GET_USER_SURVEYS_SUCCESS = "@@USER/GET_USER_SURVEYS_SUCCESS",
  GET_USER_SURVEYS_FAILURE = "@@USER/GET_USER_SURVEYS_FAILURE",

  GET_USER_SURVEY = "@@USER/GET_USER_SURVEY",
  GET_USER_SURVEY_SUCCESS = "@@USER/GET_USER_SURVEY_SUCCESS",
  GET_USER_SURVEY_FAILURE = "@@USER/GET_USER_SURVEY_FAILURE",

  CREATE_USER_SURVEY_ANSWERS = "@@USER/CREATE_USER_SURVEY_ANSWERS",
  CREATE_USER_SURVEY_ANSWERS_SUCCESS = "@@USER/CREATE_USER_SURVEY_ANSWERS_SUCCESS",
  CREATE_USER_SURVEY_ANSWERS_FAILURE = "@@USER/CREATE_USER_SURVEY_ANSWERS_FAILURE",

  CREATE_MEETING = "@@USER/CREATE_MEETING",
  CREATE_MEETING_SUCCESS = "@@USER/CREATE_MEETING_SUCCESS",
  CREATE_MEETING_FAILURE = "@@USER/CREATE_MEETING_FAILURE",

  UPDATE_MEETING = "@@USER/UPDATE_MEETING",
  UPDATE_MEETING_SUCCESS = "@@USER/UPDATE_MEETING_SUCCESS",
  UPDATE_MEETING_FAILURE = "@@USER/UPDATE_MEETING_FAILURE",

  DELETE_MEETING = "@@USER/DELETE_MEETING",
  DELETE_MEETING_SUCCESS = "@@USER/DELETE_MEETING_SUCCESS",
  DELETE_MEETING_FAILURE = "@@USER/DELETE_MEETING_FAILURE",

  GET_ONBOARDINGS = "@@USER/GET_ONBOARDINGS",
  GET_ONBOARDINGS_SUCCESS = "@@USER/GET_ONBOARDINGS_SUCCESS",
  GET_ONBOARDINGS_FAILURE = "@@USER/GET_ONBOARDINGS_FAILURE",

  GET_PROCESSES_WITH_PROCEDURES = "@@USER/GET_PROCESSES_WITH_PROCEDURES",
  GET_PROCESSES_WITH_PROCEDURES_SUCCESS = "@@USER/GET_PROCESSES_WITH_PROCEDURES_SUCCESS",
  GET_PROCESSES_WITH_PROCEDURES_FAILURE = "@@USER/GET_PROCESSES_WITH_PROCEDURES_FAILURE",

  GET_SURVEY_LIST = "@@USER/GET_SURVEY_LIST",
  GET_SURVEY_LIST_SUCCESS = "@@USER/GET_SURVEY_LIST_SUCCESS",
  GET_SURVEY_LIST_FAILURE = "@@USER/GET_SURVEY_LIST_FAILURE",

  ASSIGN_PROCEDURE = "@@USER/ASSIGN_PROCEDURE",
  ASSIGN_PROCEDURE_SUCCESS = "@@USER/ASSIGN_PROCEDURE_SUCCESS",
  ASSIGN_PROCEDURE_FAILURE = "@@USER/ASSIGN_PROCEDURE_FAILURE",

  SELF_ASSIGN_PROCEDURE = "@@USER/SELF_ASSIGN_PROCEDURE",
  SELF_ASSIGN_PROCEDURE_SUCCESS = "@@USER/SELF_ASSIGN_PROCEDURE_SUCCESS",
  SELF_ASSIGN_PROCEDURE_FAILURE = "@@USER/SELF_ASSIGN_PROCEDURE_FAILURE",

  ASSIGN_SURVEY = "@@USER/ASSIGN_SURVEY",
  ASSIGN_SURVEY_SUCCESS = "@@USER/ASSIGN_SURVEY_SUCCESS",
  ASSIGN_SURVEY_FAILURE = "@@USER/ASSIGN_SURVEY_FAILURE",

  GET_USER_PROCEDURES = "@@USER/GET_USER_PROCEDURES",
  GET_USER_PROCEDURES_SUCCESS = "@@USER/GET_USER_PROCEDURES_SUCCESS",
  GET_USER_PROCEDURES_FAILURE = "@@USER/GET_USER_PROCEDURES_FAILURE",

  ASSIGN_ONBOARDING = "@@USER/ASSIGN_ONBOARDING",
  ASSIGN_ONBOARDING_SUCCESS = "@@USER/ASSIGN_ONBOARDING_SUCCESS",
  ASSIGN_ONBOARDING_FAILURE = "@@USER/ASSIGN_ONBOARDING_FAILURE",

  UPDATE_USER_STATUS = "@@USER/UPDATE_USER_STATUS",
  UPDATE_USER_STATUS_SUCCESS = "@@USER/UPDATE_USER_STATUS_SUCCESS",
  UPDATE_USER_STATUS_FAILURE = "@@USER/UPDATE_USER_STATUS_FAILURE",

  INVITE_USER = "@@USER/INVITE_USER",
  INVITE_USER_SUCCESS = "@@USER/INVITE_USER_SUCCESS",
  INVITE_USER_FAILURE = "@@USER/INVITE_USER_FAILURE",

  UPDATE_USER_PROCEDURE_STATUS = "@@USER/UPDATE_USER_PROCEDURE_STATUS",
  UPDATE_USER_PROCEDURE_STATUS_SUCCESS = "@@USER/UPDATE_USER_PROCEDURE_STATUS_SUCCESS",
  UPDATE_USER_PROCEDURE_STATUS_FAILURE = "@@USER/UPDATE_USER_PROCEDURE_STATUS_FAILURE",
}
