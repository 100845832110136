import { request } from "@shared/utils";
import { API, METHODS } from "@shared/constants";
import { RecognitionFilter } from "@shared/interfaces/RecognitionFilter.interface";
import { CreateRecognitionDto, UpdateRecognitionDto } from "@containers/Recognition/interfaces";

export default {
  getRecognitions: (payload: RecognitionFilter) =>
    request(METHODS.GET, API.RECOGNITION.GET_RECOGNITIONS)({ params: payload }),
  getRecognition: (recognitionId: number) =>
    request(METHODS.GET, API.RECOGNITION.GET_RECOGNITION(String(recognitionId)))(),
  createRecognition: (payload: CreateRecognitionDto) =>
    request(METHODS.POST, API.RECOGNITION.CREATE_RECOGNITION)(payload),
  getCoreValues: () => request(METHODS.GET, API.RECOGNITION.GET_CORE_VALUES)(),
  getUserCoreValuesSummary: (userId: number) =>
    request(METHODS.GET, API.RECOGNITION.GET_USER_CORE_VALUES_SUMMARY(String(userId)))(),
  updateRecognition: ({ id, ...rest }: UpdateRecognitionDto) =>
    request(METHODS.PUT, API.RECOGNITION.UPDATE_RECOGNITION(String(id)))(rest),
};
