import { useEffect } from "react";
import { useToasts } from "react-toast-notifications";

import { Notification as INotification } from "../../interfaces";

interface NotificationProps {
  notification: INotification | null;
}

const Notification = (props: NotificationProps) => {
  const { notification } = props;
  const { addToast } = useToasts();

  useEffect(() => {
    //TODO: check if removeAllToasts() will suit us in all notification cases(not only in 4487673466)
    if (notification) {
      addToast(notification.message, {
        appearance: notification.appearance,
        autoDismiss: true,
      });
    }
  }, [addToast, notification]);

  return null;
};

export default Notification;
