import React from "react";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import history from "@shared/history";
import { createRoot } from "react-dom/client";
import App from "@containers/App/App";

import configureStore from "./store";
import * as serviceWorker from "./serviceWorker";

import "@assets/styles/index.scss";

if (process.env.REACT_APP_ENV === "dev") {
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  const whyDidYouRender = require("@welldone-software/why-did-you-render");
  whyDidYouRender(React, { trackHooks: true });
}

const container: HTMLElement | null = document.getElementById("root");
if (container) {
  const root = createRoot(container);
  const { store } = configureStore(history);
  root.render(
    <BrowserRouter>
      <Provider store={store}>
        <App />
      </Provider>
    </BrowserRouter>,
  );
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
