import React, { useCallback } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { actions as sharedActions } from "@shared/store";
import { MODAL_TYPES, NameOfChildRoutes, NamesOfParentRoutes } from "@shared/constants";
import { Procedure, Process, Team } from "@shared/models";
import { ProcedureForm } from "@containers/Procedure/components";
import { actions } from "@containers/Procedure/store";
import { CreateProcedureDto } from "@containers/Procedure/interfaces";

import "./index.scss";

interface ProcedureCreateEditContainerProps {
  onClose: () => void;
  procedure: Procedure | null;
  processes: Process[];
  teams: Team[];
}

const ProcedureCreateEditContainer = (props: ProcedureCreateEditContainerProps) => {
  const { onClose, procedure, processes, teams } = props;
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const handleDelete = useCallback(
    (procedure: Procedure) => {
      dispatch(actions.deleteProcedure.request(procedure.id));

      onClose();
      navigate(`${NamesOfParentRoutes.PROCEDURES}${NameOfChildRoutes.PROCEDURES.MANAGE}`);
    },
    [navigate, dispatch, onClose],
  );

  const handleDeleteProcedure = useCallback(() => {
    if (!procedure) return;

    dispatch(
      sharedActions.showModal({
        type: MODAL_TYPES.CONFIRM,
        props: {
          heading: "Delete",
          content: `Are you sure you want to delete ${procedure.name}?`,
          cancelBtnText: "Cancel",
          successBtnText: "Delete",
          onSuccess: () => handleDelete(procedure),
        },
      }),
    );
  }, [procedure, dispatch, handleDelete]);

  const changeFormValues = useCallback(() => {
    dispatch(sharedActions.updateOverlay({ leaveConfirm: true }));
  }, [dispatch]);

  const handleSubmitForm = useCallback(
    (payload: CreateProcedureDto) => {
      dispatch(sharedActions.hideOverlay());

      if (!procedure) {
        return dispatch(actions.createProcedure.request(payload));
      }

      dispatch(actions.updateProcedure.request({ ...payload, id: Number(procedure.id) }));
    },
    [dispatch, procedure],
  );

  return (
    <div className="procedure-create-edit-container">
      <div className="title-wrapper">
        <div className="title">{procedure ? "Edit Procedure" : "Add Procedure"}</div>
        <hr />
      </div>
      <ProcedureForm
        procedure={procedure}
        processes={processes}
        teams={teams}
        onChangeForm={changeFormValues}
        onDelete={handleDeleteProcedure}
        onSubmitForm={handleSubmitForm}
        onCloseForm={onClose}
      />
    </div>
  );
};

export default ProcedureCreateEditContainer;
