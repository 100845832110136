import React from "react";
import { ErrorMessage } from "formik";

interface ErrorMessageFieldProps {
  name: string;
}

const ErrorMessageField = (props: ErrorMessageFieldProps) => (
  <ErrorMessage
    name={props.name}
    render={(msg) => (
      <p className="error-message" style={{ color: "#E3345E" }}>
        {msg}
      </p>
    )}
  />
);

export default ErrorMessageField;
