import React, { FC } from "react";
import { Button, InputSearch } from "@shared/components";
import { RecognitionFilter } from "@shared/interfaces/RecognitionFilter.interface";

import "./index.scss";

interface RecognitionListHeaderProps {
  filter: RecognitionFilter | null;
  changeSearch: (search: string) => void;
  addNewRecognition: () => void;
  total: number;
}

const RecognitionListHeader: FC<RecognitionListHeaderProps> = (props) => {
  const { filter, changeSearch, addNewRecognition, total } = props;

  return (
    <div className="recognition-list-header">
      <div className="recognition-list-header-left">
        <div className="recognition-header-title">Recognitions</div>
        <div className="recognition-header-total">{total}</div>
      </div>
      <div className="recognition-list-header-right">
        <InputSearch
          id="received"
          placeholder="Search recognition..."
          onChangeSearch={changeSearch}
          value={filter?.search}
        />
        <Button className="accent padding add-button" onClick={addNewRecognition}>
          New Recognition
        </Button>
      </div>
    </div>
  );
};

export default RecognitionListHeader;
