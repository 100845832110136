import React, { useCallback } from "react";
import { useDispatch } from "react-redux";
import { CommentCreate } from "@containers/Goal/components";
import { actions } from "@containers/Goal/store";

export interface CommentCreateContainerProps {
  goal_id?: number;
  contractor_id?: number;
}

const CommentCreateContainer: React.FC<CommentCreateContainerProps> = ({ goal_id, contractor_id }) => {
  const dispatch = useDispatch();
  const handleCreateComment = useCallback(
    (description: string) => {
      dispatch(actions.createComment.request({ description, goal_id, contractor_id }));
    },
    [dispatch, goal_id, contractor_id],
  );

  return <CommentCreate submitComment={(description) => handleCreateComment(description)} />;
};

export default CommentCreateContainer;
