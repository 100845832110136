import { NameOfChildRoutes, NamesOfParentRoutes, PERMISSION, ROLE } from "@shared/constants";

import { User } from "../models";
import { tokenHandler } from "../utils";

export const getUserPermissions = (userParam?: User): string[] => {
  const user: User = userParam || tokenHandler.getUser();

  if (!user || !user.roles) {
    return [];
  }

  const permissions: string[] = [];
  user.roles.forEach((r) => {
    permissions.push(...(r.permissions ? r.permissions.map((p) => p.name) : []));
  });

  return permissions;
};

export const checkPermission = (permission: string, user?: User): boolean => {
  const userPermissions = getUserPermissions(user);
  return !!userPermissions.find((up) => up === permission);
};

export const isUserContractor = (userForCheck?: User): boolean => {
  const user: User = userForCheck || tokenHandler.getUser();
  if (user) {
    return Boolean(user.roles?.find((r) => r.name === ROLE.CONTRACTOR)) || checkPermission(PERMISSION.CONTRACTOR_MODE);
  } else {
    return false;
  }
};

export const isUserTeamLead = (userForCheck?: User): boolean => {
  const user: User = userForCheck || tokenHandler.getUser();
  if (user) {
    return checkPermission(PERMISSION.TEAM_LEAD_MODE);
  } else {
    return false;
  }
};

export const checkPermissions = (permissions: string[], user?: User): boolean => {
  const userPermissions = getUserPermissions(user);
  return permissions.some((p) => userPermissions.find((up) => up === p));
};

const getUserUrlBeforeLogout = (): string | null => {
  const redirectUrl = localStorage.getItem("redirectUrl");

  localStorage.removeItem("redirectUrl");

  if (redirectUrl) {
    return redirectUrl;
  }

  return null;
};

export const getDefaultUrl = (user: User, onError: () => void) => {
  if (
    checkPermission(PERMISSION.VIEW_PROCEDURES, user) &&
    (isUserContractor(user) || checkPermission(PERMISSION.CONTRACTOR_MODE))
  ) {
    return getUserUrlBeforeLogout() || `${NameOfChildRoutes.PROCEDURES.ROOT}${NameOfChildRoutes.PROCEDURES.PROCESSES}`;
  }
  if (checkPermission(PERMISSION.VIEW_USERS, user)) {
    return getUserUrlBeforeLogout() || NameOfChildRoutes.USERS.ROOT;
  }

  onError();

  return `${NamesOfParentRoutes.AUTH}${NameOfChildRoutes.AUTH.LOGIN}`;
};
