export enum SurveyActionTypes {
  GET_SURVEYS = "@@SURVEY/GET_SURVEYS",
  GET_SURVEYS_SUCCESS = "@@SURVEY/GET_SURVEYS_SUCCESS",
  GET_SURVEYS_FAILURE = "@@SURVEY/GET_SURVEYS_FAILURE",

  GET_SURVEY = "@@SURVEY/GET_SURVEY",
  GET_SURVEY_SUCCESS = "@@SURVEY/GET_SURVEY_SUCCESS",
  GET_SURVEY_FAILURE = "@@SURVEY/GET_SURVEY_FAILURE",

  CREATE_SURVEY = "@@SURVEY/CREATE_SURVEY",
  CREATE_SURVEY_SUCCESS = "@@SURVEY/CREATE_SURVEY_SUCCESS",
  CREATE_SURVEY_FAILURE = "@@SURVEY/CREATE_SURVEY_FAILURE",

  UPDATE_SURVEY = "@@SURVEY/UPDATE_SURVEY",
  UPDATE_SURVEY_SUCCESS = "@@SURVEY/UPDATE_SURVEY_SUCCESS",
  UPDATE_SURVEY_FAILURE = "@@SURVEY/UPDATE_SURVEY_FAILURE",

  DELETE_SURVEY = "@@SURVEY/DELETE_SURVEY",
  DELETE_SURVEY_SUCCESS = "@@SURVEY/DELETE_SURVEY_SUCCESS",
  DELETE_SURVEY_FAILURE = "@@SURVEY/DELETE_SURVEY_FAILURE",

  CLEAR_SURVEYS = "@@SURVEY/CLEAR_SURVEY",
  SET_FILTER = "@@SURVEY/SET_FILTER",

  GET_SURVEY_STATISTIC = "@@SURVEY/GET_SURVEY_STATISTIC",
  GET_SURVEY_STATISTIC_SUCCESS = "@@SURVEY/GET_SURVEY_STATISTIC_SUCCESS",
  GET_SURVEY_STATISTIC_FAILURE = "@@SURVEY/GET_SURVEY_STATISTIC_FAILURE",

  GET_SURVEY_STATISTIC_DETAILS = "@@SURVEY/GET_SURVEY_STATISTIC_DETAILS",
  GET_SURVEY_STATISTIC_DETAILS_SUCCESS = "@@SURVEY/GET_SURVEY_STATISTIC_DETAILS_SUCCESS",
  GET_SURVEY_STATISTIC_DETAILS_FAILURE = "@@SURVEY/GET_SURVEY_STATISTIC_DETAILS_FAILURE",

  SEND_SURVEY = "@@SURVEY/SEND_SURVEY",
  SEND_SURVEY_SUCCESS = "@@SURVEY/SEND_SURVEY_SUCCESS",
  SEND_SURVEY_FAILURE = "@@SURVEY/SEND_SURVEY_FAILURE",

  SET_IS_EMPTY_SURVEY_STATISTIC = "@@SURVEY/SET_IS_EMPTY_SURVEY_STATISTIC",
}
