import { AnyAction, combineReducers } from "redux";
import { AppState } from "@shared/interfaces";
import { SharedReducer } from "@shared/store/reducer";
import { ProjectReducer } from "@containers/Project/store/reducer";
import { AuthReducer } from "@containers/Auth/store/reducer";
import { UserReducer } from "@containers/User/store/reducer";
import { ProcedureReducer } from "@containers/Procedure/store/reducer";
import { SurveyReducer } from "@containers/Survey/store/reducer";
import { GoalReducer } from "@containers/Goal/store/reducer";
import { RecognitionReducer } from "@containers/Recognition/store/reducer";
import { ChartReducer } from "@containers/AccountabilityChart/store/reducer";

export default () => {
  const appReducer = combineReducers({
    auth: AuthReducer,
    shared: SharedReducer,
    project: ProjectReducer,
    procedure: ProcedureReducer,
    survey: SurveyReducer,
    goal: GoalReducer,
    user: UserReducer,
    recognition: RecognitionReducer,
    chart: ChartReducer,
  });

  return (state: AppState | undefined, action: AnyAction) => {
    return appReducer(state, action);
  };
};
