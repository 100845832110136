import React, { useCallback } from "react";
import { useDispatch } from "react-redux";
import { actions as sharedActions } from "@shared/store";
import { actions } from "@containers/Project/store";
import { ProjectForm } from "@containers/Project/components";
import { MODAL_TYPES } from "@shared/constants";
import { Project, WorkType } from "@shared/models";
import { CreateProjectDto } from "@containers/Project/interfaces";

import "./index.scss";

interface ProjectCreateEditContainerProps {
  onClose: () => void;
  project: Project | null;
  workTypes: WorkType[];
}

const ProjectCreateEditContainer = (props: ProjectCreateEditContainerProps) => {
  const { onClose, project, workTypes } = props;

  const dispatch = useDispatch();

  const handleDeleteProject = useCallback(() => {
    if (!project) return;

    dispatch(
      sharedActions.showModal({
        type: MODAL_TYPES.CONFIRM,
        props: {
          heading: "Delete",
          content: `Are you sure you want to delete ${project.name}?`,
          cancelBtnText: "Cancel",
          successBtnText: "Delete",
          onSuccess: () => {
            dispatch(actions.deleteProject.request(project.id));
            onClose();
          },
        },
      }),
    );
  }, [dispatch, project, onClose]);

  const changeFormValues = useCallback(() => {
    dispatch(sharedActions.updateOverlay({ leaveConfirm: true }));
  }, [dispatch]);

  const handleSubmitForm = useCallback(
    (payload: CreateProjectDto) => {
      dispatch(sharedActions.hideOverlay());

      if (!project) {
        return dispatch(actions.createProject.request(payload));
      }

      dispatch(actions.updateProject.request({ ...payload, id: Number(project.id) }));
    },
    [dispatch, project],
  );

  return (
    <div className="project-create-edit-container">
      <div className="title-wrapper">
        <div className="title">{project ? "Edit Project" : "New Project"}</div>
        <hr />
      </div>
      <ProjectForm
        workTypes={workTypes}
        project={project}
        onChangeForm={changeFormValues}
        onDelete={handleDeleteProject}
        onSubmitForm={handleSubmitForm}
        onCloseForm={onClose}
      />
    </div>
  );
};

export default ProjectCreateEditContainer;
