import React from "react";
import classnames from "classnames";
import { USER_SURVEY_STATUS } from "@shared/constants";

import "./index.scss";

export interface UserSurveyStatusProps {
  className?: string;
  status: USER_SURVEY_STATUS;
  notRenameStatus?: boolean;
}

const UserSurveyStatus: React.FC<UserSurveyStatusProps> = ({ className, status, notRenameStatus }) => {
  const userSurveyStatusConverter = (status: string): string => {
    return status === USER_SURVEY_STATUS.NEW && !notRenameStatus ? "Assigned" : status;
  };
  return (
    <div className={classnames("status-label", className, userSurveyStatusConverter(status.replace(" ", "-")))}>
      {userSurveyStatusConverter(status)}
    </div>
  );
};

export default UserSurveyStatus;
