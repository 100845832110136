import { Procedure } from "@shared/models";
import { TableProperty } from "@shared/components/Table/interfaces";
import { CellWithIcon } from "@shared/components/Table/components";
import React from "react";

export const procedureTablePropertiesForContractorAll: TableProperty<Procedure>[] = [
  {
    title: "Procedure Name",
    key: "name",
    get: (item) => <CellWithIcon iconType="procedure-list" text={item.name} />,
  },
  {
    title: "Process name",
    key: "process",
    get: (item) => item.process?.map((t) => t.name).join(", ") || "-",
  },
  {
    title: "Accountable Team",
    key: "accountable_team",
    get: (item) => item.accountable_team?.name || "-",
  },
  {
    title: "Support Teams",
    key: "teams",
    get: (item) => item.teams?.map((r) => r.name).join(", ") || "-",
  },
];
