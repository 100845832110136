import { createAsyncAction, createAction } from "typesafe-actions";
import { BaseSuccessResponse, PaginatedResponse, SurveysFilter } from "@shared/interfaces";
import { Survey, SurveyStatistic, SurveyStatisticDetails } from "@shared/models/Survey";
import {
  CreateSurveyDto,
  CreateSurveySuccess,
  DeleteSurveySuccess,
  SurveyStatisticDetailFilter,
  UpdateSurveyDto,
  UpdateSurveySuccess,
} from "@containers/Survey/interfaces";

import { SurveyActionTypes } from "./constants";

export const getSurveys = createAsyncAction(
  SurveyActionTypes.GET_SURVEYS,
  SurveyActionTypes.GET_SURVEYS_SUCCESS,
  SurveyActionTypes.GET_SURVEYS_FAILURE,
)<SurveysFilter, PaginatedResponse<Survey>, Error>();

export const getSurvey = createAsyncAction(
  SurveyActionTypes.GET_SURVEY,
  SurveyActionTypes.GET_SURVEY_SUCCESS,
  SurveyActionTypes.GET_SURVEY_FAILURE,
)<number, Survey | null, Error>();

export const createSurvey = createAsyncAction(
  SurveyActionTypes.CREATE_SURVEY,
  SurveyActionTypes.CREATE_SURVEY_SUCCESS,
  SurveyActionTypes.CREATE_SURVEY_FAILURE,
)<CreateSurveyDto, CreateSurveySuccess, Error>();

export const updateSurvey = createAsyncAction(
  SurveyActionTypes.UPDATE_SURVEY,
  SurveyActionTypes.UPDATE_SURVEY_SUCCESS,
  SurveyActionTypes.UPDATE_SURVEY_FAILURE,
)<UpdateSurveyDto, UpdateSurveySuccess, Error>();

export const deleteSurvey = createAsyncAction(
  SurveyActionTypes.DELETE_SURVEY,
  SurveyActionTypes.DELETE_SURVEY_SUCCESS,
  SurveyActionTypes.DELETE_SURVEY_FAILURE,
)<number, DeleteSurveySuccess, Error>();

export const getSurveyStatistic = createAsyncAction(
  SurveyActionTypes.GET_SURVEY_STATISTIC,
  SurveyActionTypes.GET_SURVEY_STATISTIC_SUCCESS,
  SurveyActionTypes.GET_SURVEY_STATISTIC_FAILURE,
)<number, SurveyStatistic[], Error>();

export const getSurveyStatisticDetails = createAsyncAction(
  SurveyActionTypes.GET_SURVEY_STATISTIC_DETAILS,
  SurveyActionTypes.GET_SURVEY_STATISTIC_DETAILS_SUCCESS,
  SurveyActionTypes.GET_SURVEY_STATISTIC_DETAILS_FAILURE,
)<SurveyStatisticDetailFilter, SurveyStatisticDetails | null, Error>();

export const sendSurvey = createAsyncAction(
  SurveyActionTypes.SEND_SURVEY,
  SurveyActionTypes.SEND_SURVEY_SUCCESS,
  SurveyActionTypes.SEND_SURVEY_FAILURE,
)<number, BaseSuccessResponse, Error>();

export const setFilter = createAction(SurveyActionTypes.SET_FILTER)<SurveysFilter | null>();
export const clearSurveys = createAction(SurveyActionTypes.CLEAR_SURVEYS)();
export const setIsEmptySurveyStatistic = createAction(SurveyActionTypes.SET_IS_EMPTY_SURVEY_STATISTIC)<boolean>();
