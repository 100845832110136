import { createAction, createAsyncAction } from "typesafe-actions";
import { GoalsFilter, PaginatedResponse } from "@shared/interfaces";
import {
  CreateCommentDto,
  CreateGoalDto,
  CreateUpdateCommentSuccess,
  DeleteCommentSuccess,
  DeleteGoalSuccess,
  UpdateCommentDto,
  UpdateGoalDto,
  UpdateGoalProgressDto,
  UpdateGoalSuccess,
} from "@containers/Goal/interfaces";
import { Comment, Goal } from "@shared/models";

import { GoalActionTypes } from "./constants";

export const getGoals = createAsyncAction(
  GoalActionTypes.GET_GOALS,
  GoalActionTypes.GET_GOALS_SUCCESS,
  GoalActionTypes.GET_GOALS_FAILURE,
)<GoalsFilter, PaginatedResponse<Goal>, Error>();

export const updateGoalProgress = createAsyncAction(
  GoalActionTypes.UPDATE_GOAL_PROGRESS,
  GoalActionTypes.UPDATE_GOAL_PROGRESS_SUCCESS,
  GoalActionTypes.UPDATE_GOAL_PROGRESS_FAILURE,
)<UpdateGoalProgressDto, UpdateGoalSuccess, Error>();

export const createGoal = createAsyncAction(
  GoalActionTypes.CREATE_GOAL,
  GoalActionTypes.CREATE_GOAL_SUCCESS,
  GoalActionTypes.CREATE_GOAL_FAILURE,
)<CreateGoalDto, UpdateGoalSuccess, Error>();

export const updateGoal = createAsyncAction(
  GoalActionTypes.UPDATE_GOAL,
  GoalActionTypes.UPDATE_GOAL_SUCCESS,
  GoalActionTypes.UPDATE_GOAL_FAILURE,
)<UpdateGoalDto, UpdateGoalSuccess, Error>();

export const deleteGoal = createAsyncAction(
  GoalActionTypes.DELETE_GOAL,
  GoalActionTypes.DELETE_GOAL_SUCCESS,
  GoalActionTypes.DELETE_GOAL_FAILURE,
)<number, DeleteGoalSuccess, Error>();

export const createComment = createAsyncAction(
  GoalActionTypes.CREATE_COMMENT,
  GoalActionTypes.CREATE_COMMENT_SUCCESS,
  GoalActionTypes.CREATE_COMMENT_FAILURE,
)<CreateCommentDto, CreateUpdateCommentSuccess, Error>();

export const getComments = createAsyncAction(
  GoalActionTypes.GET_COMMENT,
  GoalActionTypes.GET_COMMENT_SUCCESS,
  GoalActionTypes.GET_COMMENT_FAILURE,
)<Partial<Comment>, Comment[], Error>();

export const updateComment = createAsyncAction(
  GoalActionTypes.UPDATE_COMMENT,
  GoalActionTypes.UPDATE_COMMENT_SUCCESS,
  GoalActionTypes.UPDATE_COMMENT_FAILURE,
)<UpdateCommentDto, CreateUpdateCommentSuccess, Error>();

export const deleteComment = createAsyncAction(
  GoalActionTypes.DELETE_COMMENT,
  GoalActionTypes.DELETE_COMMENT_SUCCESS,
  GoalActionTypes.DELETE_COMMENT_FAILURE,
)<number, DeleteCommentSuccess, Error>();

export const setFilter = createAction(GoalActionTypes.SET_FILTER)<GoalsFilter | null>();
export const clearGoals = createAction(GoalActionTypes.CLEAR_GOALS)();
