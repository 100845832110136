import React, { useState, useEffect, useCallback } from "react";

import "./index.scss";

export interface ITimer {
  initialMinute: number;
  initialSeconds: number;
  timerFinishedHandler: () => void;
}

const Timer = (props: ITimer) => {
  const { initialMinute = 0, initialSeconds = 0, timerFinishedHandler } = props;
  const [minutes, setMinutes] = useState(initialMinute);
  const [seconds, setSeconds] = useState(initialSeconds);

  const updateRemainingTime = useCallback(() => {
    if (seconds > 0) {
      setSeconds(seconds - 1);
    } else {
      if (minutes > 0) {
        setMinutes(minutes - 1);
        setSeconds(59);
      } else {
        timerFinishedHandler();
      }
    }
  }, [seconds, minutes, timerFinishedHandler]);

  useEffect(() => {
    const timer = setTimeout(() => {
      updateRemainingTime();
    }, 1000);

    return () => clearTimeout(timer);
  }, [updateRemainingTime]);

  return minutes === 0 && seconds === 0 ? null : (
    <div className="timer-wrapper">
      {minutes}:{seconds < 10 ? `0${seconds}` : seconds}
    </div>
  );
};

export default Timer;
