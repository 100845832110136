export enum FORM_ERROR_MESSAGES {
  REQUIRED = "This field is required",
  LONG = "is too long",
  SHORT = "is too short",
  PASSWORDS_MATCH = "Passwords do not match",
  PASSWORD_REQUIRED = "Password is required",
  PASSWORD_CONFIRM_REQUIRED = "Password confirmation is required",
  NUMBER = "must be a number",
  EMAIL = "Please enter a valid email",
  UPPERCASE = "At least 1 uppercase letter",
  LOWERCASE = "At least 1 lowercase letter",
  DIGIT = "At least 1 digit",
  ONLY_TEXT = "must be without numbers",
  PHONE_NUMBER = "Phone Number is incorrect",
  USER_PASSWORD = "The passwords should contain 8-15 char - Letters (upper and lower case), numbers and symbols (-+*/=?!#$%^&*)",
}
