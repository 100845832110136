import React, { useEffect, useMemo, useState } from "react";
import { FieldArray, Formik } from "formik";
import {
  AsyncSelectPickerInput,
  Button,
  ErrorMessageField,
  Input,
  SelectPickerInput,
  Textarea,
} from "@shared/components";
import { procedureRecurrenceOptions } from "@containers/Procedure/constants";
import { CreateProcedureDto } from "@containers/Procedure/interfaces";
import { Option } from "@shared/interfaces";
import { Procedure, Process, Team } from "@shared/models";

import { getInitValues, handlers, prepareSubmitValue, validationSchema } from "./formHelpers";

import "./index.scss";

interface ProcedureFormProps {
  procedure: Procedure | null;
  processes: Process[];
  teams: Team[];
  onDelete: () => void;
  onChangeForm: () => void;
  onCloseForm: () => void;
  onSubmitForm: (payload: CreateProcedureDto) => void;
}

const ProcedureForm: React.FunctionComponent<ProcedureFormProps> = (props) => {
  const [processOptions, setProcessOptions] = useState<Option<number>[]>([]);
  const [teamOptions, setTeamOptions] = useState<Option<number>[]>([]);
  const { procedure, processes, teams, onChangeForm, onCloseForm, onDelete, onSubmitForm } = props;

  const formValues = useMemo(() => getInitValues(procedure), [procedure]);

  useEffect(() => {
    const processOpt = processes.map((i) => {
      return {
        label: i.name,
        value: i.id,
      };
    });
    setProcessOptions(processOpt);
  }, [processes]);

  useEffect(() => {
    const teamOpt = teams.map((i) => {
      return {
        label: i.name,
        value: i.id,
      };
    });
    setTeamOptions(teamOpt);
  }, [teams]);

  return (
    <Formik
      validationSchema={validationSchema}
      enableReinitialize={true}
      validateOnBlur={false}
      onSubmit={(values, { setSubmitting }) => {
        onSubmitForm(prepareSubmitValue(values));
        setSubmitting(false);
      }}
      initialValues={formValues}
      validate={() => {
        onChangeForm();
      }}
    >
      {({ values, handleChange, handleBlur, handleSubmit, setFieldValue }) => (
        <div className="procedure-form">
          <form onSubmit={handleSubmit}>
            <div className="procedure-form-content">
              <div className="procedure-name-container">
                <Input
                  className="procedure-name-input"
                  type="text"
                  labelTitle="Procedure name"
                  name="name"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.name}
                  placeholder="Procedure name"
                />
                <ErrorMessageField name="name" />
              </div>
              <div className="procedure-process-input-wrapper">
                <div className="procedure-process-input">
                  <SelectPickerInput
                    searchable={false}
                    name="process"
                    cleanable={false}
                    data={processOptions}
                    onChange={(value) => setFieldValue("process", value)}
                    onClean={handleChange}
                    labelTitle="Process Name"
                    value={values.process}
                    placeholder="Process Name"
                  />
                </div>
                <ErrorMessageField name="process" />
              </div>
              <div className="procedure-process-input-wrapper">
                <div className="procedure-process-input">
                  <AsyncSelectPickerInput
                    labelTitle="Project Name"
                    name="project"
                    className="project-input"
                    onChange={(v) => setFieldValue("project", v)}
                    value={values.project}
                    placeholder="Select Project"
                    getData={handlers.project.getData}
                    selectData={handlers.project.selectData}
                    prepareOptionFunction={handlers.project.prepareOptionFunction}
                    loadingAction={handlers.project.loadingAction}
                  />
                  <ErrorMessageField name="project" />
                </div>
              </div>
              <div className="teams-wrapper">
                <div className="teams-header">Accountable team</div>
                <FieldArray
                  name="accountable_team"
                  render={() => (
                    <div className="teams-body">
                      {teamOptions.map((teamOption) => (
                        <label className="team-label" key={teamOption.value}>
                          <input
                            name="accountable_team"
                            type="checkbox"
                            className="team-checkbox"
                            value={teamOption.value}
                            checked={values.accountable_team_id === teamOption.value}
                            onChange={(e) => {
                              if (e.target.checked) {
                                setFieldValue("accountable_team_id", teamOption.value);
                              }
                            }}
                          />
                          <div className="team-label-text">{teamOption.label}</div>
                        </label>
                      ))}
                    </div>
                  )}
                />
                <ErrorMessageField name="accountable_team_id" />
              </div>
              <div className="teams-wrapper">
                <div className="teams-header">Support team</div>
                <FieldArray
                  name="teams"
                  render={(arrayHelpers) => (
                    <div className="teams-body">
                      {teamOptions.map((teamOption) => (
                        <label className="team-label" key={teamOption.value}>
                          <input
                            name="teams"
                            type="checkbox"
                            className="team-checkbox"
                            value={teamOption.value}
                            checked={values.teams.includes(teamOption.value)}
                            onChange={(e) => {
                              if (e.target.checked) {
                                arrayHelpers.push(teamOption.value);
                              } else {
                                const idx = values.teams.indexOf(teamOption.value);
                                arrayHelpers.remove(idx);
                              }
                            }}
                          />
                          <div className="team-label-text">{teamOption.label}</div>
                        </label>
                      ))}
                    </div>
                  )}
                />
                <ErrorMessageField name="teams" />
              </div>
              <div className="procedure-link-container">
                <Textarea
                  className="procedure-link-input"
                  type="text"
                  labelTitle="Procedure Google Doc Link"
                  name="external_link"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.external_link}
                  placeholder="Procedure Google Doc Link"
                />
                <ErrorMessageField name="external_link" />
              </div>
              <div className="procedure-process-input-wrapper">
                <div className="procedure-process-input">
                  <SelectPickerInput
                    searchable={false}
                    name="recurrence"
                    cleanable={false}
                    data={procedureRecurrenceOptions}
                    onChange={(value) => {
                      setFieldValue("recurrence", value);
                    }}
                    onClean={handleChange}
                    labelTitle="Recurrence"
                    value={values.recurrence}
                    placeholder="Recurrence"
                  />
                </div>
                <ErrorMessageField name="recurrence" />
              </div>
            </div>

            <div className="procedure-form-footer">
              <div className="procedure-form-footer-left">
                {procedure ? (
                  <Button type="button" className="clear padding" onClick={onDelete}>
                    Delete
                  </Button>
                ) : null}
              </div>

              <div className="procedure-form-footer-right">
                <Button type="button" className="close-btn margin-right" onClick={onCloseForm}>
                  Cancel
                </Button>
                <Button type="submit" className="accent padding">
                  {procedure ? "Save" : "Add Procedure"}
                </Button>
              </div>
            </div>
          </form>
        </div>
      )}
    </Formik>
  );
};

export default ProcedureForm;
