import { Procedure, ProcedureRecurrenceType, Project } from "@shared/models";
import * as Yup from "yup";
import { Option } from "@shared/interfaces";
import { CreateProcedureDto, ProcedureFormShape } from "@containers/Procedure/interfaces";
import { FORM_ERROR_MESSAGES, WEBSITE_LINK } from "@shared/constants";
import { actions as projectActions, selectors as projectSelectors } from "@containers/Project/store";

export const PROCEDURE_STATUS_OPTIONS: Option<string>[] = [
  { value: "Published", label: "Published" },
  { value: "Unpublished", label: "Unpublished" },
];

export const validationSchema = Yup.object().shape({
  name: Yup.string().max(250, `Procedure name ${FORM_ERROR_MESSAGES.LONG}`).required(FORM_ERROR_MESSAGES.REQUIRED),
  status: Yup.string().required(FORM_ERROR_MESSAGES.REQUIRED),
  external_link: Yup.string()
    .min(10, `Link ${FORM_ERROR_MESSAGES.SHORT}`)
    .required(FORM_ERROR_MESSAGES.REQUIRED)
    .matches(WEBSITE_LINK, "Please provide a valid link"),
  process: Yup.number().required(FORM_ERROR_MESSAGES.REQUIRED).typeError(FORM_ERROR_MESSAGES.REQUIRED),
  accountable_team_id: Yup.number().required(FORM_ERROR_MESSAGES.REQUIRED).typeError(FORM_ERROR_MESSAGES.REQUIRED),
  teams: Yup.array().of(Yup.number()).min(1, FORM_ERROR_MESSAGES.REQUIRED),
  recurrence: Yup.string().required(FORM_ERROR_MESSAGES.REQUIRED),
});

export const getInitValues = (procedure: Procedure | null): ProcedureFormShape => ({
  name: procedure?.name || "",
  status: procedure?.status || PROCEDURE_STATUS_OPTIONS[1].value,
  external_link: procedure?.external_link || "",
  process: procedure?.process[0]?.id || null,
  accountable_team_id: procedure?.accountable_team_id || null,
  teams: procedure?.teams?.map((team) => team.id) || [],
  project: getProjectInitValues(procedure),
  recurrence: getRecurrenceInitValue(procedure),
});

export const prepareSubmitValue = (value: ProcedureFormShape): CreateProcedureDto => ({
  name: value.name,
  status: value.status,
  external_link: value.external_link,
  process: value.process,
  accountable_team_id: value.accountable_team_id,
  teams: value.teams,
  project_id: Number(value?.project?.value),
  recurrence: value.recurrence,
});

export const getProjectInitValues = (procedure: Procedure | null) => {
  if (!procedure || !procedure.project) return null;

  return {
    label: procedure.project.name,
    value: String(procedure.project.id),
  };
};

export const getRecurrenceInitValue = (procedure: Procedure | null) => {
  if (!procedure) return ProcedureRecurrenceType.NONE;

  return procedure.recurrence;
};

export const handlers = {
  project: {
    selectData: projectSelectors.getProjects,
    loadingAction: projectActions.getProjects,
    prepareOptionFunction: (item: Project): Option => {
      return { label: item.name, value: String(item.id) };
    },
    getData: (search: string) => {
      return projectActions.getProjects.request({
        search,
        page: 0,
        limit: 20,
      });
    },
  },
};
