import React from "react";

import MainSidebar, { MainSidebarProps } from "./components/MainSidebar/MainSidebar";

import "./index.scss";

const Sidebar = (props: MainSidebarProps) => {
  const { items, onLogoutClick, user } = props;

  return (
    <div className="sidebar-container">
      <MainSidebar items={items} onLogoutClick={onLogoutClick} user={user} />
    </div>
  );
};

export default Sidebar;
