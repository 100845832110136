import React, { FC, useCallback } from "react";
import { UserCoreValuesSummary } from "@shared/models";
import { CoreValueCard } from "@containers/Recognition/components/RecognitionListContainer/RecognitionListCoreValuesSummary/CoreValueCard";
import { RecognitionFilter } from "@shared/interfaces/RecognitionFilter.interface";
import { useSelector } from "react-redux";
import { selectors as RecognitionSelectors } from "@containers/Recognition/store";
import { useSearchParams } from "react-router-dom";

import "./index.scss";

interface RecognitionListCoreValuesSummaryProps {
  userCoreValuesSummary: UserCoreValuesSummary[];
  changeReceivedFilter: (updatedFilter: Partial<RecognitionFilter>) => void;
  activeTab?: number;
  setActiveTab: (activeTab: number) => void;
}

const RecognitionListCoreValuesSummary: FC<RecognitionListCoreValuesSummaryProps> = (props) => {
  const { userCoreValuesSummary, changeReceivedFilter, activeTab, setActiveTab } = props;

  const receivedRecognitionsFilter = useSelector(RecognitionSelectors.getReceivedRecognitionsFilter());
  const [searchParams, setSearchParams] = useSearchParams();

  const coreValueCardOnClickHandler = useCallback(
    (coreValueId: number) => {
      if (activeTab !== 0) {
        setActiveTab(0);
        searchParams.set("activeTab", "0");
        setSearchParams(searchParams);
      }

      if (coreValueId === receivedRecognitionsFilter?.core_value_id) {
        changeReceivedFilter({ core_value_id: undefined });
        return;
      }

      changeReceivedFilter({ core_value_id: coreValueId });
    },
    [
      activeTab,
      changeReceivedFilter,
      receivedRecognitionsFilter?.core_value_id,
      searchParams,
      setActiveTab,
      setSearchParams,
    ],
  );

  return (
    <div className="recognition-list-core-values-summary">
      <div className="core-values-summary-title">
        Hi there! See how many rewards you have earned for each core value.
      </div>
      <div className="core-values-summary-subtitle">
        Once you have reached 100 points in any category, you can exchange them for $$$ in the company merch store.
      </div>
      <div className="core-values-cards-section">
        {userCoreValuesSummary.map((coreValueSummary) => (
          <CoreValueCard
            key={coreValueSummary.core_value_id}
            valueTitle={coreValueSummary.coreValue.title}
            totalScore={coreValueSummary.total_score}
            coreValueId={coreValueSummary.core_value_id}
            onClickHandler={coreValueCardOnClickHandler}
            isSelected={coreValueSummary.core_value_id === receivedRecognitionsFilter?.core_value_id}
            iconUrl={coreValueSummary.coreValue.icon_url}
          />
        ))}
      </div>
    </div>
  );
};

export default RecognitionListCoreValuesSummary;
