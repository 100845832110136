import { request } from "@shared/utils";
import { API, METHODS } from "@shared/constants";
import { Filter } from "@shared/interfaces";

export default {
  getUserDetails: () => request(METHODS.GET, API.USER.GET_USER_DETAILS)(),
  getUsers: (payload: Filter) => request(METHODS.GET, API.USER.GET_USERS)({ params: payload }),
  getTeams: () => request(METHODS.GET, API.PROCEDURE.GET_TEAMS)(),
  getProjects: (payload: Filter) => request(METHODS.GET, API.PROJECT.GET_PROJECTS)({ params: payload }),
  getRoles: () => request(METHODS.GET, API.ROLES.GET_ROLES)(),
};
