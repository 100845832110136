import React, { FC } from "react";
import { Button, Icon } from "@shared/components";
import { UserOnboardingSteps } from "@shared/models";

import "./index.scss";

interface FinishOnboardingContainerProps {
  onClose: () => void;
  stepsWithSurvey: UserOnboardingSteps[];
}

const FinishOnboardingContainer: FC<FinishOnboardingContainerProps> = (props) => {
  const { onClose, stepsWithSurvey } = props;
  return (
    <div className="finish-onboarding-container-wrapper">
      <div className="finish-onboarding-container-title">Congrats!</div>
      {stepsWithSurvey.map((ss) => (
        <div key={ss.id} className="finish-onboarding-container-score">
          {ss.name}: Your score is {ss.survey?.score}%
        </div>
      ))}
      <div className="finish-onboarding-container-icon-wrapper">
        <Icon type="finish-onboarding" />
      </div>
      <Button className="finish-onboarding-container-button accent" onClick={onClose}>
        Finish Onboarding
      </Button>
    </div>
  );
};

export default FinishOnboardingContainer;
