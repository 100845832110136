import React, { useCallback, useMemo, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import classnames from "classnames";
import { selectors, actions } from "@shared/store";
import { MODAL_TYPES } from "@shared/constants";
import { ModalSize } from "@shared/interfaces";
import { useDraggable, useResize } from "@shared/hooks";

import { ModalConfirm } from "../ModalConfirm";

import "./index.scss";

const ModalWrapper = () => {
  const dispatch = useDispatch();
  const dragElementRef = useRef<HTMLDivElement>(null);
  const modalElementRef = useRef<HTMLDivElement>(null);
  const resizerElementRef = useRef<HTMLDivElement>(null);

  const { dragging } = useDraggable(dragElementRef, modalElementRef);
  useResize(resizerElementRef, modalElementRef);

  const modal = useSelector(selectors.getModal());

  const onClose = useCallback(() => {
    if (modal?.props.onClose) {
      modal.props.onClose();
    }

    dispatch(actions.hideModal());
  }, [modal, dispatch]);

  const onSuccess = useCallback(() => {
    if (modal?.props.onSuccess) {
      modal.props.onSuccess();
    }

    dispatch(actions.hideModal());
  }, [modal, dispatch]);

  const Component = useMemo(() => {
    if (!modal) return;
    return modalStrategy(modal.type);
  }, [modal]);

  return modal ? (
    <>
      {!modal.draggable && <div className="dynamic-modal-overlay" />}
      <div
        className={classnames("dynamic-modal-wrapper", {
          "dynamic-modal-wrapper_draggable": modal.draggable,
        })}
      >
        <div
          ref={modalElementRef}
          className={classnames("dynamic-modal", "box", `dynamic-modal_size-${modal.size || ModalSize.NORMAL}`, {
            "dynamic-modal_dragging": dragging,
          })}
        >
          {modal.draggable && (
            <div ref={dragElementRef} className="dynamic-modal-drag-panel">
              <span className="dynamic-modal-drag-panel-dot" />
              <span className="dynamic-modal-drag-panel-dot" />
              <span className="dynamic-modal-drag-panel-dot" />
            </div>
          )}
          <div className="dynamic-modal-content">
            {Component ? <Component {...modal.props} onClose={onClose} onSuccess={onSuccess} /> : null}
          </div>
          {modal.resizable && (
            <div ref={resizerElementRef} className="dynamic-modal-resizer">
              ⟷
            </div>
          )}
        </div>
      </div>
    </>
  ) : null;
};

export default ModalWrapper;

const modalStrategy = (type: string) => {
  switch (type) {
    case MODAL_TYPES.CONFIRM: {
      return ModalConfirm;
    }
  }
};
