import { Icon } from "@shared/components";
import React, { FC } from "react";

import "./index.scss";

interface RecognitionsEmptyScreenProps {
  text?: string;
}

const RecognitionsEmptyScreen: FC<RecognitionsEmptyScreenProps> = (props) => {
  const { text } = props;
  return (
    <div className="recognitions-empty-screen-wrapper">
      <Icon type="recognitions-empty" />
      <div className="recognitions-empty-screen-text">{text || "You don’t have any recognitions yet"}</div>
    </div>
  );
};
export default RecognitionsEmptyScreen;
