import React, { FC, RefObject, useCallback, useRef, useState } from "react";
import { AccessControl, Button, DropDownMenu, Icon, InputSearch } from "@shared/components";
import { UserFilter } from "@shared/interfaces";
import { useOutsideClick } from "@shared/hooks";
import classnames from "classnames";
import { PERMISSION, USER_STATUS } from "@shared/constants";

import "./index.scss";

const userDropdownOptions = [
  {
    name: USER_STATUS.ACTIVE,
    value: USER_STATUS.ACTIVE,
  },
  {
    name: USER_STATUS.INACTIVE,
    value: USER_STATUS.INACTIVE,
  },
  {
    name: "All",
    value: "",
  },
];

interface UserListHeaderProps {
  usersTotal: number;
  filter: UserFilter;
  changeSearch: (search: string) => void;
  changeFilter: (filter: Partial<UserFilter>) => void;
  openUserFormOverlay: () => void;
  isCurrentUserTeamLead: boolean;
  dropDownSyncMenuItems: { label: string; handler: () => void }[];
}

export const UserListHeader: FC<UserListHeaderProps> = (props) => {
  const {
    usersTotal,
    filter,
    changeSearch,
    changeFilter,
    openUserFormOverlay,
    isCurrentUserTeamLead,
    dropDownSyncMenuItems,
  } = props;

  const [isFilterDropdownOpened, setFilterDropdownOpened] = useState<boolean>(false);
  const [isSyncDropdownOpened, setSyncDropdownOpened] = useState<boolean>(false);
  const filterDropdownRef = useRef<HTMLDivElement | null>(null);
  const { isOutside } = useOutsideClick(
    filterDropdownRef as RefObject<{ contains: (target: EventTarget | null) => boolean }>,
  );

  const onClickOutside = useCallback(() => {
    setFilterDropdownOpened(false);
    return " closed";
  }, []);

  return (
    <div className="user-list-header">
      <div className="user-list-header-left">
        <div>{isCurrentUserTeamLead ? "Your Teammates" : "Company Teammates"}</div>
        <div className="user-total">{usersTotal}</div>
      </div>
      <div className="user-list-header-right">
        <InputSearch placeholder="Search..." onChangeSearch={changeSearch} value={filter.search} />

        <Button
          className="filter-btn secondary margin-left"
          onClick={() => setFilterDropdownOpened(!isFilterDropdownOpened)}
        >
          <Icon type="filter" />
        </Button>
        {isFilterDropdownOpened ? (
          <div
            className={classnames(
              classnames("user-filter-dropdown", isCurrentUserTeamLead ? "team-lead-view" : ""),
              isOutside ? onClickOutside() : "opened",
            )}
            ref={filterDropdownRef}
          >
            {userDropdownOptions.map((option) => (
              <label className="dropdown-label" key={option.value}>
                <input
                  name={option.name}
                  type="radio"
                  className="filter-radio"
                  value={option.value}
                  checked={option.value === filter?.status}
                  onChange={(e) => changeFilter({ status: e.target.value as USER_STATUS })}
                />
                <div className="dropdown-label-text">{option.name}</div>
              </label>
            ))}
          </div>
        ) : null}
        <AccessControl permission={PERMISSION.SYNC_TEAM_WITH_HARVEST}>
          <DropDownMenu
            className="sync-harvest accent"
            items={dropDownSyncMenuItems}
            isCloseIcon
            externalOpened={isSyncDropdownOpened}
            setExternalOpened={setSyncDropdownOpened}
          />
        </AccessControl>
        <AccessControl permission={PERMISSION.CREATE_USER}>
          <Button className="accent padding margin-left" onClick={openUserFormOverlay}>
            Add teammate
          </Button>
        </AccessControl>
      </div>
    </div>
  );
};

export default UserListHeader;
