import React, { FC } from "react";
import classnames from "classnames";

import "./index.scss";
import { Icon } from "../Icon";

interface ContainerItemHeaderProps {
  title: string;
  subTitle?: string | React.ReactNode;
  onBack?: () => void;
  className?: string;
  rightBlock?: React.ReactNode;
}

const ContainerItemHeader: FC<ContainerItemHeaderProps> = (props) => {
  const { title, subTitle, onBack, className, rightBlock } = props;

  return (
    <div className={classnames("container-item-header", className)}>
      <div className="container-item-header-left">
        <div className={classnames("container-item-header-title", { clickable: Boolean(onBack) })} onClick={onBack}>
          {onBack && <Icon type="back" />}
          {title}
        </div>
        {subTitle && <div className="container-item-header-subtitle">{subTitle}</div>}
      </div>
      <div className="container-item-header-right">{rightBlock}</div>
    </div>
  );
};

export default ContainerItemHeader;
