export enum GoalActionTypes {
  GET_GOALS = "@@GOAL/GET_GOALS",
  GET_GOALS_SUCCESS = "@@GOAL/GET_GOALS_SUCCESS",
  GET_GOALS_FAILURE = "@@GOAL/GET_GOALS_FAILURE",

  UPDATE_GOAL_PROGRESS = "@@GOAL/UPDATE_GOAL_PROGRESS",
  UPDATE_GOAL_PROGRESS_SUCCESS = "@@GOAL/UPDATE_GOAL_PROGRESS_SUCCESS",
  UPDATE_GOAL_PROGRESS_FAILURE = "@@GOAL/UPDATE_GOAL_PROGRESS_FAILURE",

  CREATE_GOAL = "@@GOAL/CREATE_GOAL",
  CREATE_GOAL_SUCCESS = "@@GOAL/CREATE_GOAL_SUCCESS",
  CREATE_GOAL_FAILURE = "@@GOAL/CREATE_GOAL_FAILURE",

  UPDATE_GOAL = "@@GOAL/UPDATE_GOAL",
  UPDATE_GOAL_SUCCESS = "@@GOAL/UPDATE_GOAL_SUCCESS",
  UPDATE_GOAL_FAILURE = "@@GOAL/UPDATE_GOAL_FAILURE",

  DELETE_GOAL = "@@GOAL/DELETE_GOAL",
  DELETE_GOAL_SUCCESS = "@@GOAL/DELETE_GOAL_SUCCESS",
  DELETE_GOAL_FAILURE = "@@GOAL/DELETE_GOAL_FAILURE",

  CREATE_COMMENT = "@@GOAL/CREATE_COMMENT",
  CREATE_COMMENT_SUCCESS = "@@GOAL/CREATE_COMMENT_SUCCESS",
  CREATE_COMMENT_FAILURE = "@@GOAL/CREATE_COMMENT_FAILURE",

  GET_COMMENT = "@@GOAL/GET_GOAL_COMMENT",
  GET_COMMENT_SUCCESS = "@@GOAL/GET_COMMENT_SUCCESS",
  GET_COMMENT_FAILURE = "@@GOAL/GET_COMMENT_FAILURE",

  UPDATE_COMMENT = "@@GOAL/UPDATE_COMMENT",
  UPDATE_COMMENT_SUCCESS = "@@GOAL/UPDATE_COMMENT_SUCCESS",
  UPDATE_COMMENT_FAILURE = "@@GOAL/UPDATE_COMMENT_FAILURE",

  DELETE_COMMENT = "@@GOAL/DELETE_COMMENT",
  DELETE_COMMENT_SUCCESS = "@@GOAL/DELETE_COMMENT_SUCCESS",
  DELETE_COMMENT_FAILURE = "@@GOAL/DELETE_COMMENT_FAILURE",

  SET_FILTER = "@@GOAL/SET_FILTER",
  CLEAR_GOALS = "@@GOAL/CLEAR_GOALS",
}
