import React, { FC } from "react";

import "./index.scss";

interface ProgresLabelProps {
  progress: number;
}

const ProgresLabel: FC<ProgresLabelProps> = (props) => {
  const { progress } = props;

  return (
    <div className="progress-label">
      <div className="progress-container-wrapper">
        <div className="progress-container" />
        <div className="progress-thumb" style={{ width: `${progress}%` }} />
      </div>
      <div className="progress-value">{progress}%</div>
    </div>
  );
};

export default ProgresLabel;
