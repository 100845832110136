import React, { useCallback, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { actions as sharedActions } from "@shared/store";
import { User } from "@shared/models";
import { AssignSurveyForm } from "@containers/User/components";
import { actions, selectors } from "@containers/User/store";
import { AssignSurveyDto } from "@containers/User/interfaces/UserForm.interface";
import { SURVEY_STATUS } from "@shared/constants";

interface AssignSurveyContainerProps {
  onClose: () => void;
  user: User;
}

const AssignSurveyContainer = (props: AssignSurveyContainerProps) => {
  const { onClose, user } = props;
  const dispatch = useDispatch();
  const surveyList = useSelector(selectors.getSurveyList());

  useEffect(() => {
    dispatch(actions.getSurveyList.request({ page: 0, limit: 500, search: "" }));

    return () => {
      dispatch(actions.getSurveyList.success({ rows: [], count: 0 }));
    };
  }, [dispatch]);

  const changeFormValues = useCallback(() => {
    dispatch(sharedActions.updateOverlay({ leaveConfirm: true }));
  }, [dispatch]);

  const handleSubmitForm = useCallback(
    (payload: Omit<AssignSurveyDto, "user_id">) => {
      const { survey_ids } = payload;
      dispatch(actions.assignSurvey.request({ user_id: user.id, survey_ids }));
      dispatch(sharedActions.hideOverlay());
    },

    [dispatch, user.id],
  );

  const activeSurveyList = useMemo(
    () => surveyList.filter((survey) => survey.status === SURVEY_STATUS.ACTIVE),
    [surveyList],
  );

  return (
    <div>
      <AssignSurveyForm
        surveys={activeSurveyList}
        onChangeForm={changeFormValues}
        onCloseForm={onClose}
        onSubmitForm={handleSubmitForm}
      />
    </div>
  );
};

export default AssignSurveyContainer;
