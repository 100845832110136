import React from "react";
import "./index.scss";
import { Button } from "@shared/components";
import { ReactComponent as ChevronLeft } from "@assets/images/icons/general/chevron-left.svg";

interface BackBtnProps {
  btnHandler: () => void;
}

const BackBtn: React.FunctionComponent<BackBtnProps> = (props) => {
  const { btnHandler } = props;
  return (
    <Button className="back-btn" onClick={btnHandler}>
      <div>
        <ChevronLeft />
        Back to List
      </div>
    </Button>
  );
};

export default BackBtn;
