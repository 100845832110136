import React, { lazy, useEffect } from "react";
import { ToastProvider } from "react-toast-notifications";
import { useDispatch, useSelector } from "react-redux";
import { useRoutes } from "react-router-dom";
import { useNavigate } from "react-router";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { BlockScreen, Loadable, ModalWrapper, Notification, OverlayWrapper, Toast } from "@shared/components";
import { NamesOfParentRoutes } from "@shared/constants";
import { AuthGuard, GuestGuard } from "@shared/guards";
import { actions, selectors } from "@shared/store";

import config from "../../config";

const AuthContainer = Loadable(lazy(() => import("../Auth/containers/AuthContainer/AuthContainer")));
const ApplicatonContainer = Loadable(lazy(() => import("../App/ApplicatonContainer")));

const routes = [
  {
    path: `${NamesOfParentRoutes.AUTH}/*`,
    element: (
      <GuestGuard>
        <AuthContainer />
        <BlockScreen />
      </GuestGuard>
    ),
  },
  {
    path: `/*`,
    element: (
      <AuthGuard>
        <ApplicatonContainer />
        <BlockScreen />
      </AuthGuard>
    ),
  },
];

const App = () => {
  const content = useRoutes(routes);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const notification = useSelector(selectors.getNotification());
  const navigateURL = useSelector(selectors.getNavigateURL());

  useEffect(() => {
    if (navigateURL) {
      navigate(navigateURL);
      dispatch(actions.navigate(""));
    }
  }, [navigateURL, dispatch, navigate]);

  return (
    <GoogleOAuthProvider clientId={config.googleClientId}>
      <ToastProvider components={{ Toast: Toast }} placement="bottom-left">
        <Notification notification={notification} />
        <ModalWrapper />
        <OverlayWrapper />
        <div className="App">{content}</div>
      </ToastProvider>
    </GoogleOAuthProvider>
  );
};

export default App;
