import React, { FC, RefObject, useCallback, useRef, useState } from "react";
import { Button, Icon, InputSearch } from "@shared/components";
import { ProjectFilter } from "@shared/interfaces";
import { useOutsideClick } from "@shared/hooks";
import classnames from "classnames";
import { GOALS_STATUS, PROJECT_STATUS } from "@shared/constants";

import "./index.scss";

const projectDropdownOptions = [
  {
    name: PROJECT_STATUS.ACTIVE,
    value: PROJECT_STATUS.ACTIVE,
  },
  {
    name: GOALS_STATUS.COMPLETED,
    value: GOALS_STATUS.COMPLETED,
  },
  {
    name: "All",
    value: "",
  },
];

interface ProjectListHeaderProps {
  projectsTotal: number;
  filter: ProjectFilter;
  changeSearch: (search: string) => void;
  changeFilter: (filter: Partial<ProjectFilter>) => void;
  openProjectFormOverlay: () => void;
}

export const ProjectListHeader: FC<ProjectListHeaderProps> = (props) => {
  const { projectsTotal, filter, changeSearch, changeFilter } = props;

  const [isFilterDropdownOpened, setFilterDropdownOpened] = useState<boolean>(false);
  const filterDropdownRef = useRef<HTMLDivElement | null>(null);
  const { isOutside } = useOutsideClick(
    filterDropdownRef as RefObject<{ contains: (target: EventTarget | null) => boolean }>,
  );

  const onClickOutside = useCallback(() => {
    setFilterDropdownOpened(false);
    return " closed";
  }, []);

  return (
    <div className="project-list-header">
      <div className="project-list-header-left">
        <div>Projects</div> <div className="project-total">{projectsTotal}</div>
      </div>
      <div className="project-list-header-right">
        <InputSearch placeholder="Search" onChangeSearch={changeSearch} value={filter.search} />
        <Button
          className="filter-btn secondary margin-left"
          onClick={() => setFilterDropdownOpened(!isFilterDropdownOpened)}
        >
          <Icon type="filter" />
        </Button>
        {isFilterDropdownOpened ? (
          <div
            className={classnames("project-filter-dropdown", isOutside ? onClickOutside() : "opened")}
            ref={filterDropdownRef}
          >
            {projectDropdownOptions.map((option) => (
              <label className="dropdown-label" key={option.value}>
                <input
                  name={option.name}
                  type="radio"
                  className="filter-radio"
                  value={option.value}
                  checked={option.value === (filter?.status || "")}
                  onChange={(e) => changeFilter({ status: e.target.value as PROJECT_STATUS })}
                />
                <div className="dropdown-label-text">{option.name}</div>
              </label>
            ))}
          </div>
        ) : null}
        {/*
          <Button className="accent padding margin-left" onClick={() => openProjectFormOverlay()}>
            New Project
          </Button>
        */}
      </div>
    </div>
  );
};

export default ProjectListHeader;
