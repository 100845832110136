import { Meeting, User, Onboarding, UserProcedure, Process, UserSurvey, Survey } from "@shared/models";
import { call, put, takeLatest } from "redux-saga/effects";
import {
  PaginatedResponse,
  ActionWithPayload,
  Filter,
  MeetingFilter,
  UserProcedureFilter,
  BaseSuccessResponse,
  SurveysFilter,
  WithOnlyCallback,
  WithCallback,
} from "@shared/interfaces";
import { startLoading, stopLoading } from "@shared/store/actions";
import {
  CreateUserMeetingDto,
  CreateUserMeetingSuccess,
  UpdateUserDto,
  UpdateUserSuccess,
  AssignOnboardingToUserDto,
  AssignOnboardingToUserSuccess,
  UpdateUserMeetingDto,
  UpdateUserMeetingSuccess,
  DeleteUserMeetingSuccess,
  UpdateUserStatusDto,
  UpdateUserStatusSuccess,
  AssignProcedureDto,
  AssignProcedureSuccess,
  UpdateUserProcedureStatusDto,
  AssignSurveyDto,
  AssignSurveySuccess,
  CreateUserSurveyAnswersDto,
  SelfAssignProcedureDto,
  DeleteUserSuccess,
  CreateUserDto,
  CreateUserSuccess,
  ExportUsersHoursDto,
} from "@containers/User/interfaces/UserForm.interface";
import { actions as userActions } from "@containers/User/store";
import { actions as procedureActions } from "@containers/Procedure/store";
import { downLoadFile } from "@shared/utils";

import {
  getUsers,
  syncUsers,
  updateUser,
  getUser,
  getOnboardings,
  assignOnboarding,
  getUserMeetings,
  createUserMeeting,
  getUserProcedures,
  updateUserMeeting,
  deleteUserMeeting,
  updateUserStatus,
  getProcessesWithProcedures,
  assignProcedure,
  inviteUser,
  updateUserProcedureStatus,
  getUserSurveys,
  assignSurvey,
  getSurveyList,
  getUserSurvey,
  createUserSurveyAnswers,
  selfAssignProcedure,
  deleteUser,
  createUser,
  syncUsersHours,
  exportUsersHours,
} from "./actions";
import api from "../api";

function* syncUsersSaga({ payload: { cb } }: ActionWithPayload<WithOnlyCallback>) {
  try {
    yield put(startLoading());
    yield call(api.syncUsers);
    yield put(syncUsers.success());

    cb();

    yield put(stopLoading());
  } catch (error) {
    yield put(syncUsers.failure(error as Error));
    yield put(stopLoading());
  }
}

function* syncUsersHoursSaga() {
  try {
    yield put(startLoading());
    yield call(api.syncUsersHours);
    yield put(stopLoading());
    yield put(syncUsersHours.success());
  } catch (error) {
    yield put(syncUsersHours.failure(error as Error));
    yield put(stopLoading());
  }
}

function* exportUsersHoursSaga({ payload }: ActionWithPayload<ExportUsersHoursDto>) {
  try {
    yield put(startLoading());
    const response: { csv: string } = yield call(api.exportUsersHours, payload.filter);
    yield put(stopLoading());

    downLoadFile(response.csv, "application/csv", payload.csvName);

    yield put(exportUsersHours.success());
  } catch (error) {
    yield put(exportUsersHours.failure(error as Error));
    yield put(stopLoading());
  }
}

function* getUsersSaga({ payload }: ActionWithPayload<Filter>) {
  try {
    yield put(startLoading());

    const response: PaginatedResponse<User> = yield call(api.getUsers, payload);

    yield put(stopLoading());
    yield put(
      getUsers.success({
        ...response,
        is_clear: !payload.page,
      }),
    );
  } catch (error) {
    yield put(getUsers.failure(error as Error));
    yield put(stopLoading());
  }
}

function* getMeetingsSaga({ payload }: ActionWithPayload<MeetingFilter>) {
  try {
    yield put(startLoading());

    const response: PaginatedResponse<Meeting> = yield call(api.getMeetings, payload);

    yield put(stopLoading());
    yield put(getUserMeetings.success({ ...response, is_clear: !payload.page }));
  } catch (error) {
    yield put(getUserMeetings.failure(error as Error));
    yield put(stopLoading());
  }
}

function* getSurveysSaga({ payload }: ActionWithPayload<SurveysFilter>) {
  try {
    yield put(startLoading());

    const response: PaginatedResponse<UserSurvey> = yield call(api.getSurveys, payload);

    yield put(stopLoading());
    yield put(getUserSurveys.success({ ...response, is_clear: !payload.page }));
  } catch (error) {
    yield put(getUserSurveys.failure(error as Error));
    yield put(stopLoading());
  }
}

function* getSurveySaga({ payload }: ActionWithPayload<number>) {
  try {
    yield put(startLoading());
    const response: UserSurvey = yield call(api.getSurvey, payload);
    yield put(stopLoading());
    yield put(getUserSurvey.success(response));
  } catch (error) {
    yield put(getUserSurvey.failure(error as Error));
    yield put(stopLoading());
  }
}

function* createUserSurveyAnswersSaga({ payload }: ActionWithPayload<WithCallback<CreateUserSurveyAnswersDto>>) {
  try {
    const { cb, ...rest } = payload;
    yield put(startLoading());
    const response: UserSurvey = yield call(api.createUserSurveyAnswers, rest);
    yield put(stopLoading());
    yield put(createUserSurveyAnswers.success(response));
    if (cb) {
      cb();
    }
  } catch (error) {
    yield put(createUserSurveyAnswers.failure(error as Error));
    yield put(stopLoading());
  }
}

function* createUserMeetingSaga({ payload }: ActionWithPayload<CreateUserMeetingDto>) {
  try {
    yield put(startLoading());

    const response: CreateUserMeetingSuccess = yield call(api.createUserMeeting, payload);

    yield put(stopLoading());
    yield put(createUserMeeting.success(response));
  } catch (error) {
    yield put(createUserMeeting.failure(error as Error));
    yield put(stopLoading());
  }
}

function* updateUserMeetingSaga({ payload }: ActionWithPayload<UpdateUserMeetingDto>) {
  try {
    yield put(startLoading());

    const response: UpdateUserMeetingSuccess = yield call(api.updateUserMeeting, payload);

    yield put(stopLoading());
    yield put(updateUserMeeting.success(response));
  } catch (error) {
    yield put(updateUserMeeting.failure(error as Error));
    yield put(stopLoading());
  }
}

function* updateUserStatusSaga({ payload }: ActionWithPayload<UpdateUserStatusDto>) {
  try {
    yield put(startLoading());

    const response: UpdateUserStatusSuccess = yield call(api.updateUserStatus, payload);

    yield put(stopLoading());
    yield put(updateUserStatus.success(response));
  } catch (error) {
    yield put(updateUserStatus.failure(error as Error));
    yield put(stopLoading());
  }
}

function* deleteUserMeetingSaga({ payload }: ActionWithPayload<number>) {
  try {
    yield put(startLoading());
    const response: DeleteUserMeetingSuccess = yield call(api.deleteUserMeeting, payload);
    yield put(stopLoading());
    yield put(deleteUserMeeting.success(response));
  } catch (error) {
    yield put(deleteUserMeeting.failure(error as Error));
    yield put(stopLoading());
  }
}

function* createUserSaga({ payload }: ActionWithPayload<CreateUserDto>) {
  try {
    yield put(startLoading());
    const response: CreateUserSuccess = yield call(api.createUser, payload);
    yield put(stopLoading());
    yield put(createUser.success(response));
  } catch (error) {
    yield put(createUser.failure(error as Error));
    yield put(stopLoading());
  }
}

function* updateUserSaga({ payload }: ActionWithPayload<UpdateUserDto>) {
  try {
    yield put(startLoading());

    const response: UpdateUserSuccess = yield call(api.updateUser, payload);

    yield put(stopLoading());
    yield put(updateUser.success(response));
  } catch (error) {
    yield put(updateUser.failure(error as Error));
    yield put(stopLoading());
  }
}

function* deleteUserSaga({ payload }: ActionWithPayload<number>) {
  try {
    yield put(startLoading());
    const response: DeleteUserSuccess = yield call(api.deleteUser, payload);
    yield put(stopLoading());
    yield put(deleteUser.success(response));
  } catch (error) {
    yield put(deleteUser.failure(error as Error));
    yield put(stopLoading());
  }
}

function* getUserSaga({ payload }: ActionWithPayload<string>) {
  try {
    yield put(startLoading());

    const response: User = yield call(api.getUser, payload);

    yield put(stopLoading());
    yield put(
      getUser.success({
        ...response,
      }),
    );
  } catch (error) {
    yield put(getUser.failure(error as Error));
    yield put(stopLoading());
  }
}

function* getOnboardingsSaga() {
  try {
    yield put(startLoading());

    const response: Onboarding[] = yield call(api.getOnboardings);

    yield put(stopLoading());
    yield put(getOnboardings.success(response));
  } catch (error) {
    yield put(getOnboardings.failure(error as Error));
    yield put(stopLoading());
  }
}

function* getProcessesWithProceduresSaga() {
  try {
    yield put(startLoading());

    const response: Process[] = yield call(api.getAllProcessesWithProcedures);

    yield put(stopLoading());
    yield put(getProcessesWithProcedures.success(response));
  } catch (error) {
    yield put(getProcessesWithProcedures.failure(error as Error));
    yield put(stopLoading());
  }
}

function* getSurveyListSaga({ payload }: ActionWithPayload<SurveysFilter>) {
  try {
    yield put(startLoading());

    const response: PaginatedResponse<Survey> = yield call(api.getSurveyList, payload);

    yield put(stopLoading());
    yield put(getSurveyList.success(response));
  } catch (error) {
    yield put(getSurveyList.failure(error as Error));
    yield put(stopLoading());
  }
}

function* getUserProceduresSaga({ payload }: ActionWithPayload<UserProcedureFilter>) {
  try {
    yield put(startLoading());
    const response: PaginatedResponse<UserProcedure> = yield call(api.getUserProcedures, payload);
    yield put(stopLoading());
    yield put(getUserProcedures.success({ ...response, is_clear: !payload.page }));
  } catch (error) {
    yield put(getUserProcedures.failure(error as Error));
    yield put(stopLoading());
  }
}

function* assignOnboardingSaga({ payload }: ActionWithPayload<AssignOnboardingToUserDto>) {
  try {
    yield put(startLoading());
    const response: AssignOnboardingToUserSuccess = yield call(api.assignOnboarding, payload);
    yield put(stopLoading());
    yield put(assignOnboarding.success(response));
    yield put(
      procedureActions.getProcessesProcedures.request({
        user_id: response.assignedOnboarding.user_id,
        with_user_procedures: true,
      }),
    );
  } catch (error) {
    yield put(assignOnboarding.failure(error as Error));
    yield put(stopLoading());
  }
}

function* assignProcedureSaga({ payload }: ActionWithPayload<AssignProcedureDto>) {
  try {
    yield put(startLoading());
    const response: AssignProcedureSuccess = yield call(api.assignProcedure, payload);
    yield put(stopLoading());
    yield put(assignProcedure.success(response));
    yield put(
      procedureActions.getProcessesProcedures.request({ user_id: payload.user_id, with_user_procedures: true }),
    );
  } catch (error) {
    yield put(assignProcedure.failure(error as Error));
    yield put(stopLoading());
  }
}

function* selfAssignProcedureSaga({ payload }: ActionWithPayload<SelfAssignProcedureDto>) {
  try {
    yield put(startLoading());
    const response: UserProcedure = yield call(api.selfAssignProcedure, payload);
    yield put(stopLoading());
    yield put(selfAssignProcedure.success(response));
  } catch (error) {
    yield put(selfAssignProcedure.failure(error as Error));
    yield put(stopLoading());
  }
}

function* assignSurveySaga({ payload }: ActionWithPayload<AssignSurveyDto>) {
  try {
    yield put(startLoading());
    const response: AssignSurveySuccess = yield call(api.assignSurvey, payload);
    yield put(stopLoading());
    yield put(assignSurvey.success(response));
    yield put(
      userActions.getUserSurveys.request({
        page: 0,
        limit: 50,
        search: "",
        user_id: payload.user_id,
      }),
    );
  } catch (error) {
    yield put(assignSurvey.failure(error as Error));
    yield put(stopLoading());
  }
}

function* inviteUserSaga({ payload }: ActionWithPayload<number>) {
  try {
    yield put(startLoading());

    const response: BaseSuccessResponse = yield call(api.inviteUser, payload);

    yield put(stopLoading());
    yield put(inviteUser.success(response));
  } catch (error) {
    yield put(inviteUser.failure(error as Error));
    yield put(stopLoading());
  }
}

function* updateUserProcedureStatusSaga({ payload }: ActionWithPayload<WithCallback<UpdateUserProcedureStatusDto>>) {
  try {
    const { cb, ...rest } = payload;
    yield put(startLoading());
    yield call(api.updateUserProcedureStatus, rest);
    yield put(stopLoading());
    yield put(updateUserProcedureStatus.success(rest));
    cb?.();
  } catch (error) {
    yield put(updateUserProcedureStatus.failure(error as Error));
    yield put(stopLoading());
  }
}

function* userSagas() {
  yield takeLatest(getUsers.request, getUsersSaga);
  yield takeLatest(syncUsers.request, syncUsersSaga);
  yield takeLatest(createUser.request, createUserSaga);
  yield takeLatest(updateUser.request, updateUserSaga);
  yield takeLatest(deleteUser.request, deleteUserSaga);
  yield takeLatest(inviteUser.request, inviteUserSaga);
  yield takeLatest(getUser.request, getUserSaga);
  yield takeLatest(getOnboardings.request, getOnboardingsSaga);
  yield takeLatest(getProcessesWithProcedures.request, getProcessesWithProceduresSaga);
  yield takeLatest(getSurveyList.request, getSurveyListSaga);
  yield takeLatest(assignProcedure.request, assignProcedureSaga);
  yield takeLatest(selfAssignProcedure.request, selfAssignProcedureSaga);
  yield takeLatest(assignSurvey.request, assignSurveySaga);
  yield takeLatest(assignOnboarding.request, assignOnboardingSaga);
  yield takeLatest(getUserMeetings.request, getMeetingsSaga);
  yield takeLatest(getUserSurveys.request, getSurveysSaga);
  yield takeLatest(getUserSurvey.request, getSurveySaga);
  yield takeLatest(createUserSurveyAnswers.request, createUserSurveyAnswersSaga);
  yield takeLatest(createUserMeeting.request, createUserMeetingSaga);
  yield takeLatest(updateUserMeeting.request, updateUserMeetingSaga);
  yield takeLatest(deleteUserMeeting.request, deleteUserMeetingSaga);
  yield takeLatest(updateUserStatus.request, updateUserStatusSaga);
  yield takeLatest(getUserProcedures.request, getUserProceduresSaga);
  yield takeLatest(updateUserProcedureStatus.request, updateUserProcedureStatusSaga);
  yield takeLatest(syncUsersHours.request, syncUsersHoursSaga);
  yield takeLatest(exportUsersHours.request, exportUsersHoursSaga);
}

export default userSagas;
