import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { actions as sharedActions } from "@shared/store";
import { User } from "@shared/models";
import { AssignProcedureForm } from "@containers/User/components";
import { actions, selectors } from "@containers/User/store";
import { AssignProcedureDto } from "@containers/User/interfaces/UserForm.interface";

interface AssignProcedureContainerProps {
  onClose: () => void;
  user: User;
}

const AssignProcedureContainer = (props: AssignProcedureContainerProps) => {
  const { onClose, user } = props;
  const dispatch = useDispatch();
  const processesWithProcedures = useSelector(selectors.getProcessesWithProcedures());
  const userAssignedProcedures = useSelector(selectors.getCurrentContractorUserProcedures());

  useEffect(() => {
    dispatch(actions.getProcessesWithProcedures.request());
    dispatch(
      actions.getUserProcedures.request({
        user_id: Number(user.id),
        page: 0,
        limit: 500,
        search: "",
      }),
    );
  }, [dispatch, user.id]);

  const changeFormValues = useCallback(() => {
    dispatch(sharedActions.updateOverlay({ leaveConfirm: true }));
  }, [dispatch]);

  const handleSubmitForm = useCallback(
    (payload: Omit<AssignProcedureDto, "user_id">) => {
      const { procedure_ids } = payload;
      dispatch(sharedActions.hideOverlay());
      dispatch(actions.assignProcedure.request({ user_id: user.id, procedure_ids }));
    },

    [dispatch, user.id],
  );

  return (
    <div className="assign-onboarding-container">
      <AssignProcedureForm
        processesWithProcedures={processesWithProcedures}
        assignedProcedures={userAssignedProcedures}
        onChangeForm={changeFormValues}
        onCloseForm={onClose}
        onSubmitForm={handleSubmitForm}
      />
    </div>
  );
};

export default AssignProcedureContainer;
