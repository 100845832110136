import React, { useEffect, useMemo, useState } from "react";
import { FieldArray, Formik } from "formik";
import { AsyncSelectPickerInput, Button, ErrorMessageField, Input, SelectPickerInput } from "@shared/components";
import { Team, User } from "@shared/models";
import UserFormTextInput from "@containers/User/components/UserEditContainer/EditUserForm/components/UserFormTextInput/UserFormTextInput";
import { Option } from "@shared/interfaces";
import { CreateUserDto } from "@containers/User/interfaces/UserForm.interface";

import {
  getInitValues,
  validationSchema,
  prepareSubmitValue,
  handlers,
  USER_CONNECTION_OPTIONS,
  USER_TYPE_EMPLOYMENT_OPTIONS,
} from "./formHelpers";
import "./index.scss";

interface UserFormProps {
  user: User | null;
  teams: Team[];
  onChangeForm: () => void;
  onCloseForm: () => void;
  onSubmitForm: (payload: CreateUserDto) => void;
}

const UserEditForm: React.FunctionComponent<UserFormProps> = (props) => {
  const { user, teams, onChangeForm, onCloseForm, onSubmitForm } = props;
  const [teamOptions, setTeamOptions] = useState<Option[]>([]);

  const formValues = useMemo(() => getInitValues(user), [user]);

  useEffect(() => {
    const teamOpt = teams.map((t) => {
      return {
        label: t.name,
        value: String(t.id),
      };
    });
    setTeamOptions(teamOpt);
  }, [teams]);

  return (
    <Formik
      validationSchema={validationSchema}
      enableReinitialize={true}
      validateOnBlur={false}
      onSubmit={(values, { setSubmitting }) => {
        onSubmitForm(prepareSubmitValue(values, user));
        setSubmitting(false);
      }}
      initialValues={formValues}
      validate={() => {
        onChangeForm();
      }}
    >
      {({ values, handleChange, handleBlur, handleSubmit, setFieldValue }) => (
        <div className="user-form">
          <form onSubmit={handleSubmit}>
            <div className="user-form-content">
              <div className="text-input-group">
                <UserFormTextInput
                  labelTitle={"First name"}
                  name={"first_name"}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.first_name}
                  placeholder={"First name"}
                />

                <UserFormTextInput
                  labelTitle={"Last name"}
                  name={"last_name"}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.last_name}
                  placeholder={"Last name"}
                />
                <UserFormTextInput
                  labelTitle={"Email"}
                  name={"email"}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.email}
                  placeholder={"Email"}
                />
                <UserFormTextInput
                  labelTitle={"Job Title"}
                  name={"job_title"}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.job_title}
                  placeholder={"Job Title"}
                />
              </div>
              <div className="user-select-input-wrapper">
                <div className="user-select-input">
                  <AsyncSelectPickerInput
                    searchable={false}
                    labelTitle="Permission Level"
                    name="permission"
                    className="user-permission-input"
                    onChange={(v) => setFieldValue("permission", v)}
                    value={values.permission}
                    placeholder="Select Permission Level"
                    getData={handlers.roles.getData}
                    selectData={handlers.roles.selectData}
                    prepareOptionFunction={handlers.roles.prepareOptionFunction}
                    loadingAction={handlers.roles.loadingAction}
                  />
                </div>
                <ErrorMessageField name="permission" />
              </div>

              <div>
                <div className="teams-header">Team</div>
                <FieldArray
                  name="teams"
                  render={() => (
                    <div className="teams-body">
                      {teamOptions.map((teamOption) => (
                        <label className="team-label" key={teamOption.value}>
                          <input
                            name={teamOption.label}
                            type="radio"
                            className="team-radio"
                            value={teamOption.value}
                            checked={values?.team?.value === teamOption.value}
                            onChange={() => setFieldValue("team", teamOption)}
                          />
                          <div className="team-label-text">{teamOption.label}</div>
                        </label>
                      ))}
                    </div>
                  )}
                />
                <ErrorMessageField name="team" />
              </div>
              <div className="user-select-input-wrapper">
                <div className="user-select-input">
                  <SelectPickerInput
                    searchable={false}
                    name="connection"
                    cleanable={false}
                    data={USER_CONNECTION_OPTIONS}
                    onChange={(value) => setFieldValue("connection", value)}
                    labelTitle="Connection"
                    value={values.connection}
                    placeholder="Connection"
                  />
                </div>
                <ErrorMessageField name="connection" />
              </div>
              <div className="user-select-input-wrapper">
                <div className="user-select-input">
                  <SelectPickerInput
                    searchable={false}
                    name="type_employment"
                    cleanable={false}
                    data={USER_TYPE_EMPLOYMENT_OPTIONS}
                    onChange={(value) => setFieldValue("type_employment", value)}
                    labelTitle="Type of employment"
                    value={values.type_employment}
                    placeholder="Type of employment"
                  />
                </div>
                <ErrorMessageField name="type_employment" />
              </div>
              <div className="user-select-input-wrapper">
                <div className="user-select-input">
                  <AsyncSelectPickerInput
                    labelTitle="Reports to"
                    name="manager"
                    className="manager-input"
                    onChange={(v) => setFieldValue("manager", v)}
                    value={values.manager}
                    placeholder="Select Manager"
                    getData={handlers.manager.getData}
                    selectData={handlers.manager.selectData}
                    prepareOptionFunction={handlers.manager.prepareOptionFunction}
                    loadingAction={handlers.manager.loadingAction}
                    onSort={handlers.manager.onSort}
                  />
                </div>
                <ErrorMessageField name="manager" />
              </div>
              <div className="user-date-input-wrapper">
                <Input
                  type="date"
                  className="date-input"
                  labelTitle="Start Date"
                  name="start_date"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.start_date}
                  placeholder="Start Date"
                />
                <ErrorMessageField name="start_date" />
              </div>
              <div className="user-date-input-wrapper">
                <Input
                  type="number"
                  className="date-input"
                  labelTitle="Used PTO"
                  name="used_pto"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.used_pto}
                  min={0}
                  max={100}
                  placeholder="Used PTO"
                />
                <ErrorMessageField name="used_pto" />
              </div>
            </div>
          </form>

          <div className="user-form-footer">
            <Button type="button" className="close-btn margin-right" onClick={() => onCloseForm()}>
              Cancel
            </Button>
            <Button type="submit" className="accent padding" onClick={() => handleSubmit()}>
              {user ? "Update Teammate" : "Add Teammate"}
            </Button>
          </div>
        </div>
      )}
    </Formik>
  );
};

export default UserEditForm;
