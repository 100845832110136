import React from "react";
import { Procedure } from "@shared/models";
import { TableProperty } from "@shared/components/Table/interfaces";
import { PublishingStatusColumn } from "@shared/components/Table/components/PublishingStatusColumn";
import { CellWithIcon } from "@shared/components/Table/components";

export const onboardingTableProperties: TableProperty<Procedure>[] = [
  {
    title: "Procedure Name",
    key: "name",
    get: (item) => (
      <span id={String(item.id)}>
        <CellWithIcon iconType="procedure-list" text={item.name} />
      </span>
    ),
  },
  {
    title: "Status",
    key: "status",
    get: (item) => <PublishingStatusColumn status={item.status} />,
  },
  {
    title: "Process name",
    key: "process",
    get: (item) => item.process?.map((t) => t.name).join(", ") || "-",
  },
];
