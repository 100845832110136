import { call, put, takeLatest } from "redux-saga/effects";
import { PaginatedResponse, ActionWithPayload, BaseSuccessResponse } from "@shared/interfaces";
import { startLoading, stopLoading } from "@shared/store/actions";
import { SurveysFilter } from "@shared/interfaces/SurveysFilter.interface";
import { Survey, SurveyStatistic, SurveyStatisticDetails } from "@shared/models/Survey";
import {
  createSurvey,
  deleteSurvey,
  getSurvey,
  getSurveys,
  getSurveyStatistic,
  getSurveyStatisticDetails,
  sendSurvey,
  setIsEmptySurveyStatistic,
  updateSurvey,
} from "@containers/Survey/store/actions";
import {
  CreateSurveyDto,
  CreateSurveySuccess,
  DeleteSurveySuccess,
  SurveyStatisticDetailFilter,
  UpdateSurveyDto,
  UpdateSurveySuccess,
} from "@containers/Survey/interfaces";

import api from "../api";

function* getSurveysSaga({ payload }: ActionWithPayload<SurveysFilter>) {
  try {
    yield put(startLoading());
    const response: PaginatedResponse<Survey> = yield call(api.getSurveys, payload);
    yield put(stopLoading());
    yield put(
      getSurveys.success({
        ...response,
        is_clear: !payload.page,
      }),
    );
  } catch (error) {
    yield put(getSurveys.failure(error as Error));
    yield put(stopLoading());
  }
}

function* getSurveySaga({ payload }: ActionWithPayload<number>) {
  try {
    yield put(startLoading());
    const response: Survey = yield call(api.getSurvey, payload);
    yield put(stopLoading());
    yield put(getSurvey.success(response));
  } catch (error) {
    yield put(getSurvey.failure(error as Error));
    yield put(stopLoading());
  }
}

function* createSurveySaga({ payload }: ActionWithPayload<CreateSurveyDto>) {
  try {
    yield put(startLoading());
    const response: CreateSurveySuccess = yield call(api.createSurvey, payload);
    yield put(stopLoading());
    yield put(createSurvey.success(response));
  } catch (error) {
    yield put(createSurvey.failure(error as Error));
    yield put(stopLoading());
  }
}

function* updateSurveySaga({ payload }: ActionWithPayload<UpdateSurveyDto>) {
  try {
    yield put(startLoading());
    const response: UpdateSurveySuccess = yield call(api.updateSurvey, payload);
    yield put(stopLoading());
    yield put(updateSurvey.success(response));
  } catch (error) {
    yield put(updateSurvey.failure(error as Error));
    yield put(stopLoading());
  }
}

function* deleteSurveySaga({ payload }: ActionWithPayload<number>) {
  try {
    yield put(startLoading());
    const response: DeleteSurveySuccess = yield call(api.deleteSurvey, payload);
    yield put(stopLoading());
    yield put(deleteSurvey.success(response));
  } catch (error) {
    yield put(deleteSurvey.failure(error as Error));
    yield put(stopLoading());
  }
}

function* getSurveyStatisticSaga({ payload }: ActionWithPayload<number>) {
  try {
    yield put(startLoading());
    const response: SurveyStatistic[] = yield call(api.getSurveyStatistic, payload);
    yield put(stopLoading());
    yield put(getSurveyStatistic.success(response));
    if (response.length === 0) {
      yield put(setIsEmptySurveyStatistic(true));
    }
  } catch (error) {
    yield put(getSurveyStatistic.failure(error as Error));
    yield put(stopLoading());
  }
}

function* getSurveyStatisticDetailsSaga({ payload }: ActionWithPayload<SurveyStatisticDetailFilter>) {
  try {
    yield put(startLoading());
    const response: SurveyStatisticDetails = yield call(api.getSurveyStatisticDetails, payload);
    yield put(stopLoading());
    yield put(getSurveyStatisticDetails.success(response));
  } catch (error) {
    yield put(getSurveyStatisticDetails.failure(error as Error));
    yield put(stopLoading());
  }
}

function* sendSurveySaga({ payload }: ActionWithPayload<number>) {
  try {
    yield put(startLoading());
    const response: BaseSuccessResponse = yield call(api.sendSurvey, payload);
    yield put(stopLoading());
    yield put(sendSurvey.success(response));
    yield put(getSurveyStatistic.request(payload));
  } catch (error) {
    yield put(sendSurvey.failure(error as Error));
    yield put(stopLoading());
  }
}

function* surveySagas() {
  yield takeLatest(getSurveys.request, getSurveysSaga);
  yield takeLatest(getSurvey.request, getSurveySaga);
  yield takeLatest(createSurvey.request, createSurveySaga);
  yield takeLatest(updateSurvey.request, updateSurveySaga);
  yield takeLatest(deleteSurvey.request, deleteSurveySaga);
  yield takeLatest(getSurveyStatistic.request, getSurveyStatisticSaga);
  yield takeLatest(getSurveyStatisticDetails.request, getSurveyStatisticDetailsSaga);
  yield takeLatest(sendSurvey.request, sendSurveySaga);
}

export default surveySagas;
