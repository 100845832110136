import * as Yup from "yup";
import { CreateProjectDto, ProjectFormShape } from "@containers/Project/interfaces";
import { FORM_ERROR_MESSAGES, PROJECT_STATUS, USER_STATUS } from "@shared/constants";
import { Project, User } from "@shared/models";
import { Option } from "@shared/interfaces";
import { actions as stationActions, selectors as stationSelectors } from "@shared/store/";

export const PROJECT_TYPES_OPTIONS: Option<string>[] = [
  { value: "Fixed cost", label: "Fixed cost" },
  { value: "Retained Team", label: "Retained Team" },
  { value: "M&S", label: "M&S" },
];

export const PROJECT_STATUS_OPTIONS: Option[] = [
  { value: PROJECT_STATUS.ACTIVE, label: PROJECT_STATUS.ACTIVE },
  { value: PROJECT_STATUS.COMPLETED, label: PROJECT_STATUS.COMPLETED },
];

const workTypeValidationSchema = Yup.object().shape({
  id: Yup.number().optional(),
  work_type_id: Yup.number().required(FORM_ERROR_MESSAGES.REQUIRED),
  hours: Yup.number().required(FORM_ERROR_MESSAGES.REQUIRED),
});

const optionValidationSchema = Yup.object().shape({
  value: Yup.string().required(FORM_ERROR_MESSAGES.REQUIRED),
  label: Yup.string().required(FORM_ERROR_MESSAGES.REQUIRED),
});

export const validationSchema: Yup.Schema<ProjectFormShape> = Yup.object().shape({
  name: Yup.string().max(250, `Name ${FORM_ERROR_MESSAGES.LONG}`).required(FORM_ERROR_MESSAGES.REQUIRED),
  code: Yup.string().max(35, `Code ${FORM_ERROR_MESSAGES.LONG}`).required(FORM_ERROR_MESSAGES.REQUIRED),
  type: Yup.string().required(FORM_ERROR_MESSAGES.REQUIRED),
  average_cost: Yup.number()
    .optional()
    .transform((value, originalValue) => (String(originalValue).trim() === "" ? null : value))
    .test("average_cost", "Please enter correct average cost", function (value) {
      if (value === undefined || value === null) {
        return true;
      }
      const regex = /^\d{1,8}(\.\d{1,2})?$/;
      return regex.test(String(value));
    }),
  status: Yup.string().required(FORM_ERROR_MESSAGES.REQUIRED).typeError(FORM_ERROR_MESSAGES.REQUIRED),
  start_date: Yup.string().required(FORM_ERROR_MESSAGES.REQUIRED),
  manager: optionValidationSchema.required(FORM_ERROR_MESSAGES.REQUIRED),
  work_types: Yup.array().of(workTypeValidationSchema).required(FORM_ERROR_MESSAGES.REQUIRED),
});

export const getWorkTypesInitValues = (project: Project | null) => {
  if (!project || !project.work_types) return [];

  return project.work_types.map((wt) => ({
    id: wt.id,
    work_type_id: wt.work_type_id,
    hours: wt.hours,
  }));
};

export const getManagerInitValues = (project: Project | null) => {
  if (!project || !project.manager) return null;

  return {
    label: `${project.manager.first_name} ${project.manager.last_name}`,
    value: String(project.manager.id),
  };
};

export const getInitValues = (project: Project | null): ProjectFormShape => ({
  name: project?.name || "",
  code: project?.code || "",
  average_cost: project?.average_cost || undefined,
  status: project?.status || PROJECT_STATUS.ACTIVE,
  type: project?.type || PROJECT_TYPES_OPTIONS[0].value,
  start_date: project?.start_date ? new Date(project.start_date).toISOString().substring(0, 10) : undefined,
  work_types: getWorkTypesInitValues(project),
  manager: getManagerInitValues(project),
});

export const prepareSubmitValue = (value: ProjectFormShape): CreateProjectDto => ({
  name: value.name,
  code: value.code,
  type: value.type,
  average_cost: value.average_cost ? value.average_cost : null,
  status: value.status,
  start_date: new Date(String(value.start_date)),
  manager_id: Number(value?.manager?.value),
  work_types: value.work_types,
});

export const handlers = {
  user: {
    selectData: stationSelectors.getUsers,
    loadingAction: stationActions.getUsers,
    prepareOptionFunction: (item: User): Option => {
      return { label: `${item.first_name} ${item.last_name}`, value: String(item.id) };
    },
    getData: (search: string) => {
      return stationActions.getUsers.request({
        search,
        page: 0,
        limit: 500,
        status: USER_STATUS.ACTIVE,
      });
    },
  },
};
