export enum MODAL_TYPES {
  CONFIRM = "CONFIRM",
}

export enum LOCATION_KEY {
  DEFAULT = "default",
}

export enum OVERLAY_TYPES {
  CREATE_EDIT_PROJECT = "CREATE_EDIT_PROJECT",
  CREATE_EDIT_PROCEDURE = "CREATE_EDIT_PROCEDURE",
  EDIT_USER = "EDIT_USER",
  ASSIGN_ONBOARDING = "ASSIGN_ONBOARDING",
  ASSIGN_PROCEDURE = "ASSIGN_PROCEDURE",
  ASSIGN_SURVEY = "ASSIGN_SURVEY",
  CREATE_EDIT_USER_MEETING = "CREATE_EDIT_USER_MEETING",
  CREATE_EDIT_GOAL = "CREATE_EDIT_GOAL",
  SHOW_GOAL_COMMENTS = "SHOW_GOAL_COMMENTS",
  CLOSE_GOAL = "CLOSE_GOAL",
  SHOW_RECOGNITION_DETAILS = "SHOW_RECOGNITION_DETAILS",
  SHOW_RECOGNITION_CREATION_NOTE = "SHOW_RECOGNITION_CREATION_NOTE",
  CREATE_RECOGNITION = "CREATE_RECOGNITION",
  FINISH_ONBOARDING_POPUP = "FINISH_ONBOARDING_POPUP",
}

export enum ROLE {
  SUPER_ADMIN = "Super Admin",
  HR = "HR",
  CONTRACTOR = "Contractor",
  TEAM_LEADER = "Team Lead",
  PROCEDURE_MANAGER = "Procedure Manager",
}

export enum INPUT_TYPES {
  PASSWORD = "password",
  TEXT = "text",
}

export enum ORDER_TYPE {
  DESC = "desc",
  ASC = "asc",
  NONE = "",
}

export enum GOOGLE_DOC_VIEW_TYPE {
  PREVIEW = "preview",
}

export enum CONTRACTOR_FILTER_DROPDOWN_OPTIONS {
  ASSIGNED_TO_ME = "Assigned to me",
  ALL = "All",
}

export const PROCESS_INTERRACTIVE_SCHEMA_URL = "https://credentials.ninetwothree.co/process-interactive";
export const GOOGLE_DOC_URN = "docs.google.com";
