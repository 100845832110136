import React, { FC } from "react";
import { User } from "@shared/models";
import { StatusLabel } from "@shared/components";

import "./index.scss";

interface UserHeaderSubtitleProps {
  user: User | null;
}

const UserHeaderSubtitle: FC<UserHeaderSubtitleProps> = (props) => {
  const { user } = props;

  return user ? (
    <div className="user-header-subtitle">
      <StatusLabel status={user?.status} />
      {user.job_title && <div>, {user.job_title}</div>}
    </div>
  ) : null;
};

export default UserHeaderSubtitle;
