import { request } from "@shared/utils";
import { API, METHODS } from "@shared/constants";
import { Filter } from "@shared/interfaces";

import { CreateProjectDto, UpdateProjectDto } from "../interfaces";

export default {
  getProjects: (payload: Filter) => request(METHODS.GET, API.PROJECT.GET_PROJECTS)({ params: payload }),
  getWorkTypes: () => request(METHODS.GET, API.PROJECT.GET_WORK_TYPES)(),
  createProject: (payload: CreateProjectDto) => request(METHODS.POST, API.PROJECT.CREATE_PROJECT)(payload),
  updateProject: (payload: UpdateProjectDto) => {
    const { id, ...rest } = payload;
    return request(METHODS.PUT, API.PROJECT.UPDATE_PROJECT(String(id)))(rest);
  },
  deleteProject: (id: number) => {
    return request(METHODS.DELETE, API.PROJECT.DELETE_PROJECT(String(id)))();
  },
};
