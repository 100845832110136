import React, { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useGoogleLogin } from "@react-oauth/google";
import { actions as sharedActions, selectors as sharedSelectors } from "@shared/store";
import { Meeting, User } from "@shared/models";
import { CreateEditMeetingForm } from "@containers/User/components/UserViewContainer/CreateEditMeetingForm";
import { CreateUserMeetingDto } from "@containers/User/interfaces/UserForm.interface";
import { createUserMeeting, deleteUserMeeting, updateUserMeeting } from "@containers/User/store/actions";
import { MODAL_TYPES } from "@shared/constants";
import { actions as authActions } from "@containers/Auth/store";

import "./index.scss";

interface CreateEditMeetingContainerProps {
  onClose: () => void;
  meeting: Meeting | null;
  user: User;
}

const CreateEditMeetingContainer = (props: CreateEditMeetingContainerProps) => {
  const { onClose, meeting, user } = props;
  const dispatch = useDispatch();
  const [payloadState, setPayloadState] = useState<Omit<CreateUserMeetingDto, "contractor_id"> | null>(null);
  const currentUser = useSelector(sharedSelectors.getUserDetails());
  const changeFormValues = useCallback(() => {
    dispatch(sharedActions.updateOverlay({ leaveConfirm: true }));
  }, [dispatch]);

  const login = useGoogleLogin({
    onSuccess: (codeResponse) => {
      if (payloadState) {
        dispatch(
          authActions.googleLogin.request({ code: codeResponse.code, cb: submitFormAction.bind(null, payloadState) }),
        );
      }
    },
    flow: "auth-code",
    scope: "https://www.googleapis.com/auth/calendar profile email",
  });

  const submitFormAction = useCallback(
    (payload: Omit<CreateUserMeetingDto, "contractor_id">) => {
      dispatch(sharedActions.hideOverlay());
      if (!meeting?.id) {
        return dispatch(createUserMeeting.request({ ...payload, contractor_id: user.id }));
      }

      dispatch(
        updateUserMeeting.request({
          ...payload,
          contractor_id: user.id,
          id: Number(meeting.id),
        }),
      );
    },
    [dispatch, meeting, user.id],
  );

  const handleSubmitForm = useCallback(
    (payload: Omit<CreateUserMeetingDto, "contractor_id">) => {
      if (payload.add_to_google_calendar && !currentUser?.is_auth_google) {
        setPayloadState(payload);
        return login();
      }
      submitFormAction(payload);
    },
    [login, submitFormAction, currentUser],
  );

  const handleDeleteMeeting = useCallback(() => {
    if (!meeting) return;

    dispatch(
      sharedActions.showModal({
        type: MODAL_TYPES.CONFIRM,
        props: {
          heading: "Delete",
          content: `Are you sure you want to delete ${meeting.title}?`,
          cancelBtnText: "Cancel",
          successBtnText: "Delete",
          onSuccess: () => {
            dispatch(deleteUserMeeting.request(meeting.id));
            onClose();
          },
        },
      }),
    );
  }, [dispatch, meeting, onClose]);

  return (
    <div className="create-edit-meeting-container">
      <div className="title-wrapper">
        <div className="title">{meeting ? "Edit Meeting" : "Create Meeting"}</div>
        <hr />
      </div>
      <CreateEditMeetingForm
        meeting={meeting}
        currentUser={currentUser}
        onChangeForm={changeFormValues}
        onSubmitForm={handleSubmitForm}
        onCloseForm={onClose}
        onDelete={handleDeleteMeeting}
      />
    </div>
  );
};

export default CreateEditMeetingContainer;
