import * as Yup from "yup";
import { FORM_ERROR_MESSAGES } from "@shared/constants";
import { Meeting, MeetingStatus, User } from "@shared/models";
import { CreateUserMeetingDto, CreateUserMeetingFormShape } from "@containers/User/interfaces/UserForm.interface";
import { actions as sharedActions, selectors as sharedSelectors } from "@shared/store";
import { Option } from "@shared/interfaces";

export const MEETING_STATUS_OPTIONS: Option<string>[] = [
  { value: "Scheduled", label: "Scheduled" },
  { value: "Conducted", label: "Conducted" },
];

export const validationSchema = Yup.object().shape({
  title: Yup.string().max(100, `Meeting ${FORM_ERROR_MESSAGES.LONG}`).required(FORM_ERROR_MESSAGES.REQUIRED),
  date: Yup.date().required(FORM_ERROR_MESSAGES.REQUIRED).typeError(FORM_ERROR_MESSAGES.REQUIRED),
  comment: Yup.string().max(200, `Comment ${FORM_ERROR_MESSAGES.LONG}`).required(FORM_ERROR_MESSAGES.REQUIRED),
  add_to_google_calendar: Yup.string(),
  status: Yup.string(),
});

export const getManagerInitValue = (meeting: Meeting | null) => {
  if (!meeting || !meeting.manager) return null;

  return {
    label: `${meeting.manager?.first_name} ${meeting.manager?.last_name}`,
    value: String(meeting.manager.id),
  };
};

export const getInitValues = (meeting: Meeting | null) => ({
  title: meeting?.title || "",
  date: meeting?.date ? new Date(String(meeting?.date)).toISOString().substring(0, 10) : "",
  comment: meeting?.comment || "",
  add_to_google_calendar: meeting?.add_to_google_calendar ? "1" : "0",
  status: meeting?.status || MeetingStatus.SCHEDULED,
});

export const prepareSubmitValue = (values: CreateUserMeetingFormShape): Omit<CreateUserMeetingDto, "contractor_id"> => {
  return {
    ...values,
    date: new Date(String(values.date)),
    add_to_google_calendar: Boolean(Number(values.add_to_google_calendar)),
    status: values.status,
  };
};

export const handlers = {
  manager: {
    selectData: sharedSelectors.getUsersWithAdminsAndHrsPermissions,
    loadingAction: sharedActions.getUsers,
    prepareOptionFunction: (u: User): Option => {
      return { label: `${u.first_name} ${u.last_name}`, value: String(u.id) };
    },
    getData: () => {
      return sharedActions.getUsers.request({
        page: 0,
        limit: 500,
        search: "",
      });
    },
  },
};
