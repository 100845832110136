import { Configuration, ConfigurationObject } from "@shared/interfaces";
const { REACT_APP_ENV = "dev" } = process.env;

const dev: Configuration = {
  env: REACT_APP_ENV,
  baseApiUrl: "http://localhost:4000/api/v1/",
  googleClientId: "590361666667-q2sliro1ottrbrb0k14uq2sqmjjp4ds0.apps.googleusercontent.com",
};

const qa: Configuration = {
  ...dev,
  baseApiUrl: "https://api.qa.core.ninetwothree.co/api/v1/",
};

const stage: Configuration = {
  ...dev,
  baseApiUrl: "https://api.uat.core.ninetwothree.co/api/v1/",
};

const production: Configuration = {
  ...dev,
  baseApiUrl: "https://api.core.ninetwothree.co/api/v1/",
};

const config: ConfigurationObject = {
  dev,
  qa,
  production,
  stage,
};

const configElement: Configuration = config[REACT_APP_ENV];
export default configElement;
