import React from "react";
import { Toggle, ToggleProps } from "rsuite";

import "./index.scss";

const ToggleComponent = (props: ToggleProps) => {
  return (
    <div className="toggle-wrapper">
      <Toggle {...props} as={undefined} />
    </div>
  );
};

export default ToggleComponent;
