import { createSelector } from "reselect";
import { isUserContractor } from "@shared/utils";

import { AppState } from "../interfaces";

const selectShared = (state: AppState) => state.shared;

export const getLoading = () => createSelector(selectShared, (state) => state.loading);

export const getNotification = () => createSelector(selectShared, (state) => state.notification);

export const getModal = () => createSelector(selectShared, (state) => state.modal);

export const getOverlay = () => createSelector(selectShared, (state) => state.overlay);

export const getUserDetails = () => createSelector(selectShared, (state) => state.user);
export const getUsers = () => createSelector(selectShared, (state) => state.users);
export const getUsersWithAdminsAndHrsPermissions = () =>
  createSelector(selectShared, (state) => {
    return state.users.filter((u) => !isUserContractor(u));
  });

export const getTeams = () => createSelector(selectShared, (state) => state.teams);
export const getProjects = () => createSelector(selectShared, (state) => state.projects);
export const getRoles = () => createSelector(selectShared, (state) => state.roles);

export const getUsersTotal = () => createSelector(selectShared, (state) => state.usersTotal);

export const getLoadingSections = () => createSelector(selectShared, (state) => Object.keys(state.loadingSections));

export const getLoadingSection = (sectionName: string) =>
  createSelector(selectShared, (state) => state.loadingSections[sectionName]);

export const getNavigateURL = () => createSelector(selectShared, (state) => state.navigateURL);
