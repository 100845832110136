import { produce } from "immer";
import { ActionType, createReducer } from "typesafe-actions";
import { SurveysFilter } from "@shared/interfaces/SurveysFilter.interface";

import { SurveyStateType } from "../interfaces";
import * as actions from "./actions";

type Action = ActionType<typeof actions>;

const defaultSurveysFilter: SurveysFilter = {
  page: 0,
  limit: 50,
  search: "",
};

export const initialState: SurveyStateType = {
  surveys: [],
  survey: null,
  surveysTotal: 0,
  filter: { ...defaultSurveysFilter },
  surveyStatistic: [],
  surveyStatisticDetails: null,
  isEmptySurveyStatistic: false,
};

const reducer = createReducer<SurveyStateType, Action>(initialState)
  .handleAction(actions.getSurveys.success, (state, action) =>
    produce(state, (nextState) => {
      const { rows, count, is_clear } = action.payload;
      nextState.surveys = !is_clear ? [...nextState.surveys, ...rows] : [...rows];
      nextState.surveysTotal = count;
    }),
  )
  .handleAction(actions.getSurvey.success, (state, action) =>
    produce(state, (nextState) => {
      nextState.survey = action.payload;
    }),
  )
  .handleAction(actions.createSurvey.success, (state, action) =>
    produce(state, (nextState) => {
      nextState.surveys = [action.payload.survey, ...nextState.surveys];
    }),
  )
  .handleAction(actions.updateSurvey.success, (state, action) =>
    produce(state, (nextState) => {
      const surveys = nextState.surveys;
      const foundSurveyIndex = surveys.findIndex((s) => action.payload.survey.id === s.id);

      surveys[foundSurveyIndex] = action.payload.survey;

      nextState.surveys = [...surveys];

      if (state.survey && state.survey.id === action.payload.survey.id) {
        nextState.survey = action.payload.survey;
      }
    }),
  )
  .handleAction(actions.deleteSurvey.success, (state, action) =>
    produce(state, (nextState) => {
      const surveys = nextState.surveys.filter((p) => p.id !== action.payload.survey_id);
      nextState.surveys = [...surveys];
      if (state.survey && state.survey.id === action.payload.survey_id) {
        nextState.survey = null;
      }
    }),
  )
  .handleAction(actions.setFilter, (state, action) =>
    produce(state, (nextState) => {
      nextState.filter = action.payload || { ...defaultSurveysFilter };
    }),
  )
  .handleAction(actions.clearSurveys, (state) =>
    produce(state, (nextState) => {
      nextState.surveys = [];
      nextState.surveysTotal = 0;
    }),
  )
  .handleAction(actions.getSurveyStatistic.success, (state, action) =>
    produce(state, (nextState) => {
      nextState.surveyStatistic = action.payload;
    }),
  )
  .handleAction(actions.getSurveyStatisticDetails.success, (state, action) =>
    produce(state, (nextState) => {
      nextState.surveyStatisticDetails = action.payload;
    }),
  )
  .handleAction(actions.setIsEmptySurveyStatistic, (state, action) =>
    produce(state, (nextState) => {
      nextState.isEmptySurveyStatistic = action.payload;
    }),
  );

export { reducer as SurveyReducer };
